/* eslint sort-keys: "error" */

const translations = {
  da: {
    'about-this-service': 'Om tjenesten',
    accessibility: 'Fremkommelighed',
    'add-itinerary-via-point': 'Tilføj viapunkt til rejsen',
    'add-location': 'Tilføj sted',
    'add-location-to-favourites': 'Tilføj sted til favoritter',
    address: 'Adresse',
    'address-or-stop': 'Adresse eller stoppested',
    agency: 'Operatør',
    airplane: 'Fly',
    'airplane-with-route-number': 'Fly {routeNumber}',
    'airport-check-in': 'Bagage kan checkes ind hos {agency}',
    'airport-collect-luggage': 'Hent eventuel bagage',
    'airport-security-check-go-to-gate':
      'Passer sikkerhedskontrollen og fortsæt til gate',
    'approx-meters': '{approxDistance} meter',
    'arriving-at': 'Ankomst',
    'arriving-soon': 'Nu',
    'avoid-transfers': 'Undgå skift',
    'avoid-walking': 'Undgå at gå',
    'back-button-title': 'Gå tilbage til forrige side',
    'back-to-front-page': 'Tilbage til forsiden ›',
    'bike-availability': 'Cykler tilgængelige',
    'bike-availability-short': 'Cykler',
    bus: 'Bus',
    'bus-with-route-number': 'Bus {routeNumber} {headSign}',
    'buy-ticket': 'Køb billet',
    call: 'Ring',
    cancel: 'Fortryd',
    'car-distance-duration': 'Kør {distance} ({duration})',
    cause: 'Årsag',
    'char-left': 'slet',
    'choose-stop': 'Vælg stoppested',
    citybike: 'Bycykel',
    'citybike-register-required': 'Bycykelregistrering påkræves',
    close: 'Lukket',
    'contact-information': '{contactName} kontaktinformation',
    'create-account': 'Gem {contactName}-konto',
    'cycle-distance-duration': 'Cykle {distance} ({duration})',
    'cyclewalk-distance-duration': 'Tril cyklen {distance} ({duration})',
    datasources: 'Datakilder',
    delete: 'Slet',
    'departure-time-in-minutes': '{minutes} m',
    departures: 'Afgange',
    description:
      'En national rejseplanlægger skal bidrage til at gøre det lettere at rejse kollektivt over hele landet.',
    destination: 'Til',
    'destination-label-change': 'Ændre destination',
    'destination-placeholder': 'Hvorhen vil du rejse?',
    'digitransit-platform': 'Digitransit platform',
    'disruption-info': 'Afvigsinformation',
    'disruption-info-no-alerts': 'Ingen afvigsinformation.',
    'disruption-info-route-no-alerts': 'Ingen afvigsinformation.',
    disruptions: 'Afvig',
    'distance-between': 'Afstand {distance1} m — {distance2} m',
    'distance-under': 'Afstand mindre end {distance} m',
    earlier: 'Tidligere',
    'edit-favourites': 'Rediger favorit',
    'extra-info': 'Mere info',
    'favourite-target': 'Favoritdestination',
    ferry: 'Færge',
    'ferry-with-route-number': 'Færge {routeNumber} {headSign}',
    roadinspection: 'Road inspection',
    'roadinspection-vehicle-time-range': 'Road inspection timerange: 30 days',
    'realtime-roadinspection-vehicles': 'Realtime road inspection',
    frontpage: 'Forside',
    'geolocate-yourself': 'Find min position',
    'geolocation-denied-heading':
      'Tjenesten fungerer bedst når du oplyser din position',
    'geolocation-denied-text':
      'Lokalisering er ikke tilladt. Kontroller indstillingene for din browser.',
    'geolocation-failed-heading': 'Lokalisering fungerer ikke',
    'geolocation-failed-text':
      'Din browser har ikke understøttelse for lokalisering.',
    'geolocation-prompt-text': 'Venligst accepter lokalisering.',
    'geolocation-timeout-heading':
      'Lokalisering tager længre tid end forventet.',
    'geolocation-timeout-text': 'Vælg udgangspunkt og prøv igen.',
    'give-destination': 'skriv hvor du vil hen',
    'give-name-to-location': 'Giv stedet en beskrivelse',
    'give-origin': 'tilføj afgangssted',
    'give-position': 'Tilføj position i søgefeldet over.',
    'hour-short': 't',
    'how-to-rate-service': 'Hvordan vil du rangere tjenesten?',
    'in-addition': 'Og',
    inquiry: 'Send tilbagemelding',
    instructions: 'Instruktioner',
    'itinerary-feedback-button': 'Send feedback',
    'itinerary-feedback-message': 'Fandt du ikke det du ledte efter?',
    'itinerary-feedback-placeholder': 'Beskrivelse (valgfrit)',
    'itinerary-hide-stops': 'Skjul stop',
    'itinerary-page.description': 'Rejsebeskrivelse',
    'itinerary-page.title': 'Rejsebeskrivelse',
    'itinerary-summary-page.description': 'Anbefalte rute',
    'itinerary-summary-page.title': 'Anbefalte rute',
    later: 'Senere',
    leaves: 'Går',
    'leaving-at': 'Afgang',
    'likely-to-recommend':
      'Hvor sandsynlig er det at du vil anbefale denne tjeneste til en ven eller kollega?',
    'likely-to-use':
      'Hvor sandsynlig er det at du vil bruge denne tjeneste fremfor tjenesten du allerede bruger?',
    loading: 'indlæser',
    locate: 'Der jeg er',
    'location-examples': 'f.eks hjemme, arbejde, skole ...',
    'location-from-map': 'Vælg position',
    login: 'Log ind',
    'long-transfers-margin': 'Rigtig lang',
    'main-menu-label-open': 'Åben hovedmenuen',
    'main-mode': 'Jeg rejser med',
    map: 'Kort',
    'messagebar-label-close-message-bar': 'Luk besked',
    'messagebar-label-page': 'Side',
    'minute-short': 'm',
    minutes: 'min',
    navigate: 'Naviger',
    'near-you': 'Nær dig',
    nearest: 'Forrige',
    'network-error': 'Netværksfejl',
    next: 'Næste',
    'no-favourite-locations':
      'Tilføj dine mest besøgte steder her. Det giver dig hurtigere rejseplanlægning.',
    'no-favourites':
      'Du kan tilføje favoritter ved at trykke på favoritknapper forskellige steder i rejseplanlæggeren',
    'no-position': 'Ingen position',
    'no-position-no-stops':
      'Kan ikke vise stoppesteder i nærheden da din position er ukendt.',
    'no-route-end-date-not-in-range':
      'Current timetable does not include routes departing later.',
    'no-route-msg':
      'Vi finder desværre ingen rejseforslag til rejsesøget. Forsøg at ændre adressen',
    'no-route-start-date-too-early':
      'Current timetable does not include routes departing earlier.',
    'no-route-start-end': 'Venligst vælg udgangspunkt og destination.',
    'no-transfers-margin': 'Ingen',
    'no-trips-found': 'Ingen rejser tilgænglig for denne dag.',
    now: 'Nu',
    'number-of-intermediate-stops':
      '{number, plural, =0 {Ingen stop} other {{number} stop} }',
    'off-canvas-info':
      'Ved at logge ind kan du finde tilbage til dine favoritter på andre enheder',
    or: 'eller',
    origin: 'Fra',
    'origin-label-change': 'Ændre afgangsted',
    'origin-placeholder': 'Fra',
    'own-position': 'Min position',
    'page-not-found': 'Siden findes ikke ੨( ･᷄ ︵･᷅ )ｼ',
    'park-and-ride': 'Indfartsparkering',
    'park-and-ride-availability': 'Tilgjængelighed',
    'park-and-ride-unknown': 'Ukendt kapacitet',
    'parking-availability': 'Tilgjængelighed',
    'pay-attention': 'Obs!',
    'pick-icon': 'Vælg et ikon',
    'place-route-or-keyword': 'Adresse eller stoppested',
    'platform-num': 'Platform {platformCode}',
    'platform-short': 'Plat.',
    'prefer-walking': 'Foretræk at gå',
    previous: 'Tidligere',
    print: 'Skriv ud',
    'print-timetable': 'Køreplan',
    rail: 'Tog',
    'rail-with-route-number': 'Tog {routeNumber} {headSign}',
    'realtime-matching': 'Sandtidsintegration',
    'rent-cycle-at': 'Lej cykel fra stativ {station}',
    'required-ticket': 'Billet kræves før påstigning',
    'right-now': 'Nu',
    route: 'linje',
    'route-destination-arrives': 'Destination / Sidste holdeplads',
    'route-from-here': 'Find rute herfra',
    'route-here': 'Find rute hertil',
    'route-not-possible':
      'Desværre kan vi ikke finde et rejseforslag til dig. Teknisk feil: "{error}"',
    'route-page.description': 'Rute {shortName}, {longName}',
    'route-page.title': 'Rute {shortName}',
    'route-page.title-short': 'Rute',
    'route-stop-or-keyword': 'Linje eller stoppested',
    routes: 'Ruter',
    run: 'Løbe',
    save: 'Gem',
    search: 'Til',
    'search-destination-results-but-no-search': 'Se resultater i Til-fanen',
    'search-no-results': 'Ingen resultater',
    'search-origin': 'tilføj afgangssted',
    'search-position': 'Led efter position',
    'search-search-results-but-no-destination': 'Se resultater i Fra-fanen',
    'searching-position': 'Leder etter position',
    'select-position': 'Vælg din position baseret på tidligere søg',
    send: 'Send',
    settings: 'Indstillinger',
    'settings-label-change': 'Ændre indstillinger',
    'settings-reset': 'Nulstil indstillinger',
    'settings-savebutton': 'Gem indstillinger',
    'settings-saved': 'Ændringer gemt!',
    share: 'Del rejsen',
    'show-departures': 'Vis afgange',
    'show-more': 'Vis flere',
    'skip-positioning': 'Hop over',
    slow: 'Spasering',
    'specify-location': 'Angiv sted',
    'splash-choose': 'Angiv udgangspunkt',
    'splash-locating': 'Søger efter din position',
    'splash-or-choose': 'Eller fravælg din position',
    'splash-use-positioning': 'Brug min position',
    'splash-welcome': 'Velkommen til ny version af din rejseplanlægger',
    'splash-you-can-also': 'Alternativt',
    stop: 'Holdeplads',
    'stop-number': 'Holdepladsnummer',
    'stop-page.description': 'Holdeplads - {name} {code}, {desc}',
    'stop-page.title': 'Holdeplads - {name} {code}',
    'stop-page.title-short': 'Holdeplads',
    stops: 'Holdepladser',
    subway: 'Metro',
    'subway-with-route-number': 'Metro {routeNumber} {headSign}',
    'summary-page.description': '{from} - {to}',
    'summary-page.title': 'Anbefalte ruter',
    'terminal-page.description': 'Terminal - {name}',
    'terminal-page.title': 'Terminal',
    'terminal-page.title-short': 'Terminal - {name}',
    'terms-of-use': 'Brugervilkår',
    'ticket-and-price-info': 'Billet- og prisinformation',
    tickets: 'Billetter',
    time: 'Tid',
    timetable: 'Køreplan',
    'to-stop': 'Afstand',
    today: 'I dag',
    tomorrow: 'I morgen',
    tram: 'Sporvogn',
    'tram-with-route-number': 'Sporvogn {routeNumber} {headSign}',
    transfers: 'Bytte',
    'transfers-allowed': 'Bytte tilladt',
    'transfers-margin': 'Byttetid',
    'trip-co2-emissions': 'Rejsens CO2-udslip',
    'trip-page.description': 'Linje {shortName}, {longName}',
    'trip-page.title': 'Linje {shortName}',
    'try-again': 'Prøv igen.',
    'use-citybike': 'Brug bycykel',
    'use-own-position': 'Brug min position',
    'using-modes': 'Transportform',
    'very-likely': 'Højst sandsynlig',
    'very-unlikely': 'Lidt sandsynlig',
    'via-leg-stop-duration': 'Viapunkt {stayDuration}',
    'via-point': 'Viapunkt',
    wait: 'Vent',
    'wait-amount-of-time': 'Vent {duration}',
    walk: 'gå',
    'walk-distance-duration': 'Gå {distance} ({duration})',
    walking: 'Gå',
    'walking-speed': 'Gåhastighed',
    'warning-call-agency':
      'Kun bestillingstransport: {routeName}, afgangen må forudbestilles af operatør.',
    'warning-call-agency-no-route':
      'Kun bestillingstransport. Afgangen må forudbestilles af operatør.',
    'weather-at-destination': 'Vejret på destinationen',
    yesterday: 'I går',
    'your-favourites': 'Favoritter',
    zones: 'Zoner',
  },
  de: {
    'about-this-service': 'Über diesen Service',
    accessibility: 'Barrierefreiheit',
    'add-location': 'Ort hinzufügen',
    'add-location-to-favourites': 'Ort zu Favoriten hinzufügen',
    address: 'Adresse',
    'address-or-stop': 'Adresse oder Haltestelle',
    agency: 'Betreiber',
    airplane: 'Flug',
    'airplane-with-route-number': 'Flug {routeNumber}',
    'airport-check-in': 'Gepäck bei {agency} einchecken',
    'airport-collect-luggage': 'Falls vorhanden, Gepäck einsammeln',
    'airport-security-check-go-to-gate':
      'Gehen Sie durch die Sicherheitskontrolle zum Abfluggate',
    'approx-meters': '{approxDistance} Meter',
    'arriving-at': 'Ankunft',
    'arriving-soon': 'Jetzt',
    'avoid-transfers': 'Umstiege vermeiden',
    'avoid-walking': 'Gehen vermeiden',
    'back-to-front-page': 'Zurück zur Startseite ›',
    'bike-availability': 'Fahrräder verfügbar',
    'bike-availability-short': 'Räder',
    bus: 'Bus',
    'bus-with-route-number': 'Bus {routeNumber} {headSign}',
    'buy-ticket': 'Ticket kaufen',
    call: 'Anrufen',
    cancel: 'Abbrechen',
    'car-distance-duration': 'Auto {distance} ({duration})',
    cause: 'Grund',
    'char-left': 'Zeichen',
    'choose-stop': 'Haltestelle auswählen',
    citybike: 'Fahrrad',
    'citybike-register-required': 'Anmeldung bei Citybikes benötigt',
    close: 'Schließen',
    'contact-information': '{contactName} Kontaktinformationen',
    'create-account': '{contactName}-Konto anlegen',
    'cycle-distance-duration': 'Radfahren: {distance} ({duration})',
    'cyclewalk-distance-duration': 'Fahrrad schieben: {distance} ({duration})',
    datasources: 'Datenquellen',
    delete: 'Löschen',
    'departure-time-in-minutes': '{minutes} min',
    departures: 'Abfahrten',
    destination: 'Zielort',
    'destination-placeholder': 'Wohin reisen?',
    'digitransit-platform': 'Digitransit-Plattform',
    'disruption-info': 'Störungsmeldungen',
    'disruption-info-no-alerts': 'Keine Störungsmeldungen.',
    'disruption-info-route-no-alerts': 'Keine Störungsmeldungen.',
    disruptions: 'Störungen',
    'distance-between': 'Abstand zwischen {distance1} m — {distance2} m',
    'distance-under': 'Abstand unter {distance} m',
    earlier: 'Früher',
    'edit-favourites': 'Favoriten bearbeiten',
    'extra-info': 'Mehr Infos',
    'favourite-target': 'Favoriten',
    ferry: 'Fähre',
    'ferry-with-route-number': 'Fähre {routeNumber} {headSign}',
    roadinspection: 'Road inspection',
    'roadinspection-vehicle-time-range': 'Road inspection timerange: 30 days',
    'realtime-roadinspection-vehicles': 'Realtime road inspection',
    'footer-feedback': 'Feedback senden',
    frontpage: 'Startseite',
    'geolocate-yourself': 'Lokalisieren',
    'geolocation-denied-heading': 'Bitte Zugriff auf den Standort erlauben',
    'geolocation-denied-text':
      'Zugriff auf den Standort ist nicht erlaubt. Bitte prüfen Sie die Einstellungen Ihres Browsers.',
    'geolocation-failed-text':
      'Ihr Browser unterstützt keinen Zugriff auf den Standort',
    'geolocation-prompt-text': 'Bitte Zugriff auf den Standort erlauben',
    'geolocation-timeout-heading':
      'Standortermittlung dauert länger als erwartet.',
    'geolocation-timeout-text':
      'Abfahrtsort unten wählen, oder später versuchen.',
    'give-destination': 'Geben Sie Ihren Zielort ein',
    'give-name-to-location': 'Name für diesen Ort',
    'give-origin': 'Geben Sie Ihren Abfahrtsort ein',
    'give-position': 'Geben Sie Ihre Position ins Suchfeld ein.',
    'hour-short': 'h',
    'how-to-rate-service': 'Wie würden Sie den Service bewerten?',
    'in-addition': 'auch',
    inquiry: 'Feedback geben',
    instructions: 'Anweisungen',
    'itinerary-feedback-button': 'Feedback senden',
    'itinerary-feedback-message': 'Nichts gefunden?',
    'itinerary-feedback-placeholder': 'Beschreibung (optional)',
    'itinerary-hide-stops': 'Zwischenstopps ausblenden',
    'itinerary-page.description': 'Reisebeschreibung',
    'itinerary-page.title': 'Reisebeschreibung',
    later: 'Später',
    leaves: 'Abfahrt',
    'leaving-at': 'Abfahrt',
    'likely-to-recommend':
      'Wie wahrscheinlich ist es, dass Sie unseren Service weiterempfehlen würden?',
    'likely-to-use':
      'Wie wahrscheinlich würden Sie weiterhin diesen Service anstatt des bisherigen Services nutzen?',
    loading: 'laden',
    locate: 'Lokalisieren',
    'location-examples': 'z.B. Zuhause, Arbeit, Schule,...',
    'location-from-map': 'Gewählte Position',
    login: 'Login',
    'long-transfers-margin': 'Lange',
    'main-menu-label-open': 'Menü öffnen',
    'main-mode': 'Ich reise mit',
    map: 'Karte',
    'messagebar-label-close-message-bar': 'Banner schließen',
    'messagebar-label-page': 'Seite',
    'minute-short': 'm',
    minutes: 'min',
    navigate: 'Navigieren',
    'near-you': 'In der Nähe',
    nearest: 'Nächste',
    'network-error': 'Ein Netzwerkfehler ist aufgetreten.',
    next: 'Nächste',
    'no-favourite-locations': 'Fügen Sie ihre meist genutzen Orte hier hinzu.',
    'no-favourites':
      'Sie können Favoriten mittels Klick auf den Stern hinzufügen. Danach werden diese hier erscheinen.',
    'no-position': 'Kein Standort',
    'no-position-no-stops':
      'Die nächsten Haltestellen können nicht gezeigt werden, da Ihr Standort unbekannt ist.',
    'no-route-end-date-not-in-range':
      'Current timetable does not include routes departing later.',
    'no-route-msg':
      'Wir konnten leider keine Reisevorschläge für diese Strecke finden. Bitte versuchen Sie den Abfahrts- oder Zielort zu ändern.',
    'no-route-start-date-not-in-range':
      'Current timetable does not include routes departing earlier.',
    'no-transfers-margin': 'Keine',
    'no-trips-found': 'Keine Reisen für diesen Tag gefunden.',
    now: 'Jetzt',
    'number-of-intermediate-stops':
      '{number, plural, =0 {Keine Zwischenstopps} one {1 Zwischenstopp} other {{number} Zwischenstopps} }',
    'off-canvas-info':
      'Mit einer Anmeldung können Sie Favoriten speichern und auf anderen Geräten wieder verwenden.',
    or: 'oder',
    origin: 'Start',
    'origin-placeholder': 'Start',
    'own-position': 'Aktueller Standort',
    'page-not-found': 'Oh! Seite wurde nicht gefunden ੨( ･᷄ ︵･᷅ )ｼ',
    'park-and-ride': 'Park-and-Ride',
    'park-and-ride-availability': 'Plätze verfügbar',
    'parking-availability': 'Plätze verfügbar',
    'pay-attention': 'Achtung',
    'pick-icon': 'Icon auswählen',
    'place-route-or-keyword': 'Adresse oder Haltestelle',
    'platform-num': 'Gleis {platformCode}',
    'platform-short': 'Gl. {platformCode}',
    'prefer-walking': 'Gehen bevorzugen',
    previous: 'Vorherige',
    print: 'Drucken',
    'print-timetable': 'Fahrplan',
    rail: 'Zug',
    'rail-with-route-number': 'Zug {routeNumber} {headSign}',
    'realtime-matching': 'Echtzeit-Matching',
    'rent-cycle-at': 'Fahrrad bei {station} mieten',
    'required-ticket': 'Benötigtes Ticket',
    'right-now': 'Jetzt',
    route: 'Linie',
    'route-destination-arrives': 'Ankunft / Letzte Haltestelle',
    'route-from-here': 'Route hierher',
    'route-here': 'Route von hier',
    'route-not-possible':
      'Leider ist diese Route nicht möglich. Technischer Fehler: "{error}"',
    'route-page.description': 'Linie {shortName}, {longName}',
    'route-page.title': 'Route {shortName}',
    'route-page.title-short': 'Linie',
    'route-stop-or-keyword': 'Linie oder Haltestelle',
    routes: 'Linien',
    run: 'Schnell',
    save: 'Speichern',
    search: 'Ziel',
    'search-destination-results-but-no-search':
      'Siehe Ergebnisse im "Zielort"-Tab',
    'search-no-results': 'Keine Ergebnisse',
    'search-origin': 'Geben Sie Ihren Abfahrtsort ein',
    'search-position': 'Standort suchen',
    'search-search-results-but-no-destination':
      'Siehe Ergebnisse im "Linie oder Haltestelle"-Tab',
    'searching-position': 'Suche aktuellen Standort...',
    'select-position': 'Standort aus vorherigen Suchen wählen',
    send: 'Senden',
    settings: 'Einstellungen',
    share: 'Reise teilen',
    'show-departures': 'Abfahrten zeigen',
    'show-more': 'Mehr zeigen',
    'skip-positioning': 'Überspringen',
    slow: 'Langsam',
    'specify-location': 'Ort eingeben',
    'splash-choose': 'Abfahrtsorte eingeben',
    'splash-locating': 'Suche aktuellen Standort...',
    'splash-or-choose': 'oder einen dieser Abfahrtsorte wählen',
    'splash-use-positioning': 'Aktuellen Standort benutzen',
    'splash-welcome': 'Wie möchten Sie starten?',
    'splash-you-can-also': 'Sie können auch',
    stop: 'Haltestelle',
    'stop-number': 'Haltestellennummer',
    'stop-page.description': 'Haltestelle - {name} {code}, {desc}',
    'stop-page.title': 'Haltestelle - {name} {code}',
    'stop-page.title-short': 'Haltestelle',
    stops: 'Haltestellen',
    subway: 'U-Bahn',
    'subway-with-route-number': 'U-Bahn {routeNumber} {headSign}',
    'summary-page.description': '{from} - {to}',
    'summary-page.title': 'Reisevorschläge',
    'terminal-page.description': 'Terminal - {name}',
    'terminal-page.title': 'Terminal',
    'terminal-page.title-short': 'Terminal - {name}',
    'terms-of-use': 'Nutzungsbedingungen',
    'ticket-and-price-info': 'Ticket- und Preisinformation',
    tickets: 'Fahrkarten',
    time: 'Zeit',
    timetable: 'Fahrplan',
    'to-stop': 'Entfernung',
    today: 'Heute',
    tomorrow: 'Morgen',
    tram: 'Tram',
    'tram-with-route-number': 'Tram {routeNumber} {headSign}',
    transfers: 'Umstiege',
    'transfers-allowed': 'Umstiege erlauben',
    'transfers-margin': 'Umstiegszeit',
    'trip-co2-emissions': 'CO2-Emissionen der Reise',
    'trip-page.description': 'Linie {shortName}, {longName}',
    'trip-page.title': 'Linie {shortName}',
    'try-again': 'Nochmal versuchen',
    'use-citybike': 'Fahrrad benutzen',
    'use-own-position': 'Aktuellen Standort benutzen',
    'using-modes': 'Verkehrsmittel',
    'very-likely': 'Sehr wahrscheinlich',
    'very-unlikely': 'Sehr unwahrscheinlich',
    wait: 'Wartezeit',
    'wait-amount-of-time': 'Wartezeit {duration}',
    walk: 'gehen',
    'walk-distance-duration': 'Gehen {distance} ({duration})',
    walking: 'Gehen',
    'walking-speed': 'Laufgeschwindigkeit',
    'weather-at-destination': 'Wetter am Reiseziel',
    yesterday: 'Gestern',
    'your-favourites': 'Favoriten',
    zones: 'Tarifzonen',
  },
  en: {
    '1. time range': '1. time range',
    '2. time range': '2. time range',
    'about-this-service': 'About the service',
    accessibility: 'Accessibility',
    'accessibility-limited': 'Wheelchair',
    'accessibility-nolimit': 'No limits',
    'accessibility-report': 'Accessibility report',
    'add-itinerary-via-point': 'Add via point for itinerary',
    'add-location': 'Add location',
    'add-location-to-favourites': 'Add an important location to your Favorites',
    'additional-analytics': 'Additional analytics',
    address: 'Address',
    'address-or-stop': 'Address or stop',
    agency: 'Operator',
    'air-humidity': 'Air humidity',
    'air-temperature': 'Air temperature',
    airplane: 'Flight',
    'airplane-with-route-number': 'Flight {routeNumber}',
    'airport-check-in': 'Check-in at the {agency} desk',
    'airport-collect-luggage': 'Collect your luggage',
    'airport-security-check-go-to-gate':
      'Proceed to your gate through security check',
    'all-routes': 'All routes',
    'all-routes-disabled': 'No routes',
    'approx-meters': '{approxDistance} m',
    'arriving-at': 'Arriving',
    'arriving-soon': 'Now',
    'average-speed': 'Average speed',
    'avoid-transfers': 'Avoid transfers',
    'avoid-walking': 'Avoid walking',
    back: 'Go back',
    'back-button-title': 'Go back to previous page',
    'back-to-front-page': 'Back to the front page',
    bicycle: 'bike',
    'bicycle-distance-duration': 'Bike {distance} ({duration})',
    'bicycle-route-prop-name': 'Name',
    'bicycle-route-prop-type': 'Type',
    'bicycle-route-prop-type-PLANNED': 'Planned',
    'bicycle-route-prop-type-CURRENT': 'Current',
    'bicycle-route-prop-year': 'Year of completion',
    'bicycle-route-prop-length': 'Length',
    'bicycle-routes': 'Bicycle routes',
    'bicycle-routes-BAANA': 'Baana',
    'bicycle-routes-BAANA-CURRENT': 'Baana',
    'bicycle-routes-BAANA-PLANNED': 'Planned baana',
    'bicycle-routes-BRAND': 'Brand routes',
    'bicycle-routes-BRAND-MAIN': 'Brand route',
    'bicycle-routes-BRAND-PERIMETER': 'Perimeter route',
    'bicycle-routes-MAIN_REGIONAL': 'Main and regional routes',
    'bicycle-routes-MAIN_REGIONAL-MAIN': 'Main route',
    'bicycle-routes-MAIN_REGIONAL-REGIONAL': 'Regional route',
    'bicycle-routes-MAIN_REGIONAL-PLANNED_MAIN': 'Planned main route',
    'bicycle-routes-MAIN_REGIONAL-PLANNED_REGIONAL': 'Planned regional route',
    'bicycle-routes-TYPES': 'Other routes',
    'bicycle-routes-TYPES-2_WAY': '2-way pp+jk',
    'bicycle-routes-TYPES-SLOW_STREET': 'Slow street (=bicycle street)',
    'bicycle-routes-TYPES-BICYCLE_LANE': 'Bicycle lane',
    'bicycle-routes-TYPES-SHARED_SPACE': 'Shared space',
    'bicycle-routes-TYPES-4MW_SHARED': '4 m wide shared pp+jk',
    'bicycle-routes-TYPES-35MW_SHARED': '3.5 m wide shared pp+jk',
    'bicycle-routes-TYPES-ACCESS_ROAD': 'Access road',
    'bicycle-routes-TYPES-WIDE_SHOULDER': 'Wide shoulder',
    'bike-availability': 'Bikes available at the station right now',
    'bike-availability-short': 'Bikes',
    'bike-not-allowed-bus': 'Bikes are not allowed on the bus',
    'bike-not-allowed-tram': 'Bikes are not allowed on the tram',
    'biketransport-citybike': "I'm using a citybike",
    'biketransport-only-bike': "I'm travelling only by bike",
    'biking-amount': 'Amount of biking',
    'biking-speed': 'Biking speed',
    bus: 'Bus',
    'bus-with-route-number': 'Bus {routeNumber} {headSign}',
    'buy-ticket': 'How to buy a ticket (HSL.fi)',
    call: 'Call',
    cameras: 'Cameras',
    cancel: 'Cancel',
    car: 'Car',
    'car-distance-duration': 'Drive {distance} ({duration})',
    car_park: 'park & ride',
    cause: 'cause',
    'char-left': 'characters',
    'choose-date': 'Choose date',
    'choose-date-error': 'The end date must be greater than the start date.',
    'choose-stop': 'Define selection',
    'weather-station': 'Weather station',
    citybike: 'City bike',
    'citybike-distance-duration': 'Bike {distance} ({duration})',
    'citybike-off-season':
      'City bike stations will be opened again next spring',
    'citybike-register-required': 'To use city bikes, you need to register',
    citybike_off: 'Closed',
    clear: 'Clear',
    'clear-selected-options': 'Clear options',
    close: 'Close',
    'close-comparison': 'Close comparison',
    compare: 'Compare',
    condition: 'Condition',
    'contact-information': '{contactName} contact details',
    'create-account': 'Create {contactName} account',
    'cycle-distance-duration': 'Cycle {distance} ({duration})',
    'cyclewalk-distance-duration': 'Walk your bike {distance} ({duration})',
    daily: 'Daily',
    datasources: 'Data sources',
    date: 'Date',
    delete: 'Delete',
    'departure-time-in-minutes': '{minutes} min',
    departures: 'Departures',
    description: 'Find public transport services to locations near and far.',
    destination: 'Destination',
    'destination-copied': 'Destination url copied to clipboard',
    'destination-needed': 'A destination needs to be defined',
    'destination-outside-service':
      'Unfortunately no routes were found for your journey. Your destination address is located outside the service area. Please change it.',
    'destination-placeholder': 'Enter destination, route or stop',
    'dew-point-temperature': 'Dew point temperature',
    'dialog-return-to-map': 'Return to map',
    'digitransit-platform': 'Digitransit service platform',
    disclaimer:
      'Please note that the results are based on estimated travel times. The suggested transport connections cannot be guaranteed.',
    disruption: 'Disruption',
    'disruption-info': 'Disruption info',
    'disruption-info-no-alerts': 'No known disruptions or diversions.',
    'disruption-info-route-no-alerts':
      'No known disruptions or diversions to the route.',
    disruptions: 'Disruptions',
    'distance-between': 'Distance {distance1} m — {distance2} m',
    'distance-total': 'Total distance',
    'distance-under': 'Distance less than {distance} m',
    'driving-conditions': 'Driving conditions',
    earlier: 'Earlier',
    'eco-counter': 'Eco counter',
    'eco-counter-direction-1': 'To city',
    'eco-counter-direction-2': 'From city',
    'eco-counter-direction-5': 'Not defined',
    'eco-counter-direction-6': 'From the north',
    'eco-counter-direction-7': 'From the east',
    'eco-counter-direction-8': 'From the south',
    'eco-counter-direction-9': 'From the west',
    'eco-counter-total': 'Total',
    'eco-counter-week-title': 'Week {date}',
    'edit-favourites': 'Edit the location in the Favorites',
    'elevation-gained-total': 'Elevation gained',
    'elevation-lost-total': 'Elevation lost',
    explanations: 'Explanations',
    'export-time-range-1': 'Export time range 1',
    'export-time-range-2': 'Export time range 2',
    'external-modes': 'Other transportation',
    'extra-info': 'Further information',
    'favourite-target': 'Favorite location',
    ferry: 'Ferry',
    'ferry-with-route-number': 'Ferry {routeNumber} {headSign}',
    roadinspection: 'Road inspection',
    'roadinspection-vehicle-time-range': 'Road inspection timerange: 30 days',
    'realtime-roadinspection-vehicles': 'Realtime road inspection',
    fluency: 'Congestions',
    'footer-links': 'Footer links',
    'footer-faq': 'FAQ',
    'footer-feedback': 'Submit feedback',
    'footer-link-to-privacy-policy': 'Privacy policy',
    'from-city': 'Out of the city',
    frontpage: 'Frontpage',
    'generic-error': 'There was an error',
    'geolocate-yourself': 'Detect your location',
    'geolocation-denied-heading': 'Geolocation denied',
    'geolocation-denied-text':
      'You have not given a permission to use your geolocation. You can allow it from the phone or browser settings.',
    'geolocation-failed-heading': 'Geolocation failed',
    'geolocation-failed-text':
      'Your browser does not support location retrieval.',
    'geolocation-prompt-text':
      'Accept your browser’s request to access your location.',
    'geolocation-timeout-heading':
      'Detecting your location is taking longer than expected.',
    'geolocation-timeout-text':
      'Have you accepted your browser’s request to access your location?',
    'give-destination': 'Enter your destination',
    'give-name-to-location': 'Give the location a descriptive name',
    'give-origin': 'Enter your origin.',
    'give-position': 'Enter your location or origin in the search field.',
    'give-route': 'Search route',
    'hide-realtime-on-map': 'Hide vehicles on map',
    hour: 'Hour',
    'hour-short': 'h',
    hourly: 'Hourly',
    'hourly-with-date': 'Hourly ({date})',
    'how-to-rate-service': 'How would you rate the service?',
    'in-addition': 'In addition',
    inquiry: 'How did you find the new Journey Planner? Please tell us!',
    instructions: 'Instructions',
    'itinerary-feedback-button': 'Send feedback',
    'itinerary-feedback-message': 'Couldn’t find what you were looking for?',
    'itinerary-feedback-placeholder': 'Description (optional)',
    'itinerary-hide-stops': 'Hide stops',
    'itinerary-page.description': 'Itinerary',
    'itinerary-page.title': 'Itinerary',
    'itinerary-summary-page.description': 'Route suggestions',
    'itinerary-summary-page.title': 'Route suggestions',
    'itinerary-ticket.title': 'Required ticket',
    'itinerary-tickets.title': 'Required tickets',
    'itinerary-time.title': 'Duration',
    'itinerary-walk.title': 'Total walking distance',
    'journeyplanner.title': 'HSL Journey Planner',
    'last-updated': 'Last updated',
    later: 'Later',
    leaves: 'Leaves',
    'leaving-at': 'Leaving at',
    'likely-to-recommend':
      'How likely are you to recommend our service to your friends or colleagues?',
    'likely-to-use':
      'How likely are you to use this service rather than the current Journey Planner?',
    links: 'Links',
    loading: 'Loading...',
    locate: 'Detect location',
    'location-examples': 'e.g. Home, Work, School,...',
    'location-from-map': 'Selected location',
    'location-home': 'Home',
    'location-school': 'School',
    'location-shopping': 'Shopping',
    'location-sport': 'Sports',
    'location-work': 'Work',
    login: 'Log in',
    'long-transfers-margin': '12 min',
    'main-bicycle-route-contract': 'Only main bicycle paths',
    'main-menu-label-close': 'Close the main menu',
    'main-menu-label-open': 'Open the main menu',
    'main-mode': "I'm traveling by",
    maintenance: 'Maintenance',
    'maintenance-job': 'Maintenance job',
    'maintenance-job-0': 'Other routes in service',
    'maintenance-job-1357': 'Brushing of surface',
    'maintenance-job-1364': 'Trimming of surface',
    'maintenance-job-1366': 'Trimming of surface',
    'maintenance-job-1367': 'Anti-skid',
    'maintenance-job-1368': 'Anti-skid',
    'maintenance-job-1369': 'Anti-skid',
    'maintenance-job-1370': 'Snow plowing',
    'maintenance-job-2864': 'Trimming of surface',
    'maintenance-job-99901': 'Brushing of snow',
    'maintenance-job-99902': 'Road inspection',
    'maintenance-job-legend-0': 'Other routes in service',
    'maintenance-job-legend-1357': 'Brushing of surface',
    'maintenance-job-legend-1364': 'Trimming of surface',
    'maintenance-job-legend-1366': 'Trimming of surface',
    'maintenance-job-legend-1367': 'Anti-skid',
    'maintenance-job-legend-1368': 'Anti-skid',
    'maintenance-job-legend-1369': 'Anti-skid',
    'maintenance-job-legend-1370': 'Snow plowing',
    'maintenance-job-legend-2864': 'Trimming of surface',
    'maintenance-job-legend-99901': 'Brushing of surface',
    'maintenance-job-legend-99902-car': 'Road inspection (car)',
    'maintenance-job-legend-99902-bicycle': 'Road inspection (bicycle)',
    'maintenance-job-realization': 'Realization',
    'maintenance-vehicle-name': 'Vehicle #{vehicleNumber}',
    'maintenance-vehicle-time-range': 'Time range',
    'maintenance-vehicle-time-range-12h': '12h',
    'maintenance-vehicle-time-range-1d': '1d',
    'maintenance-vehicle-time-range-1h': '1h',
    'maintenance-vehicle-time-range-3d': '3d',
    'maintenance-vehicle-time-range-30d': '30d brushing',
    'maintenance-vehicle-time-range-3h': '3h',
    'maintenance-vehicle-time-range-6h': '6h',
    'maintenance-inspection-vehicle-type-car': '(car) ',
    'maintenance-inspection-vehicle-type-bicycle': '(bicycle) ',
    'maintenance-inspection-vehicle-type-unknown': '(unknown) ',
    map: 'Map',
    'map-layer-citybike': 'Citybike stations',
    'map-layer-park-and-ride': 'Park & ride sites',
    'map-layer-stop-bus': 'Bus stops',
    'map-layer-stop-ferry': 'Ferries',
    'map-layer-stop-tram': 'Tram stops',
    'map-layer-terminal-bus': 'Bus terminals',
    'map-layer-terminal-rail': 'Train stations',
    'map-layer-terminal-subway': 'Subway stations',
    'map-layer-ticket-sales-machine': 'Ticket machines',
    'map-layer-ticket-sales-point': 'Travel Card top up points',
    'messagebar-label-close-message-bar': 'Close banner',
    'messagebar-label-page': 'Page',
    'minute-short': 'min',
    minutes: 'min',
    'minutes-or-route': 'Min / Route',
    monthly: 'Monthly',
    more: 'More',
    'more-settings': 'More settings',
    motorist: 'Motorist',
    navigate: 'Navigate',
    'near-you': 'Near you',
    nearest: 'Nearest',
    'network-error': 'Network error',
    next: 'Next',
    'no-bike-allowed-popup':
      'Bicycles are not allowed on buses or trams. If you are using these vehicles on your route, leave the bicycle at the stop or at a Park-and-Ride. ',
    'no-favourite-locations':
      'Add your most used locations here. The buttons will plan your route straight away.',
    'no-favourites':
      'Use the star buttons to add routes or stops to your favorites. Your favorites are shown on this page.',
    'no-position': 'No location',
    'no-position-no-stops':
      'Your location is not available. We are unable to show the closest stops.',
    'no-route-end-date-not-in-range':
      'Current timetable does not include routes departing later.',
    'no-route-msg':
      'Unfortunately no routes were found for your journey. Please change your origin or destination address.',
    'no-route-start-date-too-early':
      'Current timetable does not include routes departing earlier.',
    'no-route-start-end': 'Please select origin and destination.',
    'no-transfers-margin': '1 min',
    'no-trips-found': 'No journeys found for the selected date.',
    now: 'Now',
    'number-of-intermediate-stops':
      '{number, plural, =0 {No stops} one {1 stop} other {{number} stops} }',
    'number-of-minutes':
      '{number, plural, =0 {0 minutes} one {1 minute} other {{number} minutes}}',
    'off-canvas-info':
      'Log in to the service to save your favorites and utilize them on other devices',
    'option-default': 'Default',
    'option-least': 'Least',
    'option-less': 'Less',
    'option-more': 'More',
    'option-most': 'Most',
    or: 'or',
    origin: 'Origin',
    'origin-outside-service':
      'Unfortunately no routes were found for your journey. Your origin address is located outside the service area, please change it.',
    'origin-placeholder': 'From? - address or stop',
    'own-position': 'Your current location',
    'page-not-found': 'The page cannot be found.',
    'park-and-ride': 'Park and Ride',
    'park-and-ride-availability': 'Spaces available',
    parking: 'Parking',
    'parking-availability': 'Spaces available',
    'pay-attention': 'N.B.',
    'pick-icon': 'Select icon',
    'pick-mode': 'Select a transport mode',
    'place-route-or-keyword': 'Destination, route or stop',
    'platform-num': 'Platform {platformCode}',
    'platform-short': 'Plat. {platformCode}',
    'prefer-walking': 'Prefer walking',
    preferred: 'Prefer routes',
    previous: 'Previous',
    print: 'Print',
    'print-timetable': 'Weekly timetable',
    'public-transport': 'Public transport',
    rail: 'Train',
    'rail-with-route-number': 'Train {routeNumber} {headSign}',
    rain: 'Rain',
    'rain-classification': 'Rain classification',
    'rainfall-depth': 'Rainfall depth',
    'rainfall-intensity': 'Rainfall intensity',
    'no-rain': 'Clear',
    'has-rain': 'Rain',
    'light-snow': 'Light snow',
    'moderate-snow': 'Moderate snow',
    'heavy-snow': 'Heavy snow',
    realtime: 'Real-time',
    'realtime-maintenance-vehicles': 'Realtime maintenance vehicles',
    'realtime-matching': 'Real-time integration',
    'rent-cycle-at': 'Rent a bike at {station} station',
    'required-ticket': 'Ticket required for the journey',
    'return-cycle-to': 'Return the bike to {station} station',
    'reverse-route': 'Reverse route direction',
    'right-now': 'Right now',
    'road-condition': 'Road conditions',
    'road-condition-air': 'Air',
    'road-condition-condition': 'Condition',
    'road-condition-now': 'Now',
    'road-condition-road': 'Road',
    'road-condition-time': 'Time',
    'road-condition-weather': 'Weather',
    'road-condition-wind': 'Wind',
    'road-info': 'Road information',
    'road-signs': 'Variable road signs',
    'road-sign': 'Variable road sign',
    'road-sign-type': 'Type',
    'road-sign-display-value': 'Display value',
    'road-sign-effective-from': 'Effective from',
    'road-sign-SPEEDLIMIT': 'Speed limit',
    'road-sign-WARNING': 'Warning',
    'road-sign-INFORMATION': 'Information',
    'road-sign-warning-144': 'Slippery road',
    'road-sign-warning-165': 'Traffic signals',
    'road-sign-warning-189': 'Other dangers',
    'road-sign-warning-133': 'Traffic jam',
    'road-sign-warning-142': 'Road works',
    'road-sign-warning-183': 'Crosswind',
    'road-sign-warning-141': 'Uneven road ahead',
    'road-sign-warning-143': 'Loose chippings',
    'road-sign-warning-147': 'Dangerous shoulders',
    'road-sign-warning-154': 'Skiers crossing',
    'road-sign-warning-155': 'Animals (Elk)',
    'road-sign-warning-156': 'Animals (Reindeer)',
    'road-temperature': 'Road temperature',
    roadwork: 'Roadwork',
    roadworks: 'Roadworks',
    route: 'Route',
    'route-custom-settings': 'Custom settings',
    'route-default': 'Default settings',
    'route-destination-arrives': 'Arrives / Terminus',
    'route-fastest': 'Fast route',
    'route-from-here': 'Route from here',
    'route-guide': 'Route guide',
    'route-here': 'Route here',
    'route-here-button': 'Copy route here',
    'route-least-elevation-changes': 'Avoid changes in elevation',
    'route-least-transfers': 'Avoid transfers',
    'route-least-walking': 'Avoid walking',
    'route-not-possible':
      'Unfortunately, your route is not available. Technical error: "{error}"',
    'route-page.description': 'Route {shortName} - {longName}',
    'route-page.title': 'Route {shortName}',
    'route-page.title-short': 'Route',
    'route-prefer-greenways': 'Prefer cycleways',
    'route-prefer-illuminated': 'Prefer illuminated routes',
    'route-prefer-paved': 'Prefer paved routes',
    'route-prefer-walking-routes': 'Prefer walking routes',
    'route-prefer-winter-maintenance': 'Prefer routes with winter maintenance',
    'route-preferences': 'Route preferences',
    'route-public-transport-with-bicycle': 'Public transport with bicycle',
    'route-saved-settings': 'Saved settings',
    'route-stop-or-keyword': 'About the route or stop',
    routes: 'Routes',
    run: 'Run',
    save: 'Save',
    search: 'Search',
    'search-destination': 'Search destination, route or stop',
    'search-destination-results-but-no-search':
      'View results in the adjacent “Destination” tab',
    'search-no-results': 'No location',
    'search-origin': 'Search origin, route or stop',
    'search-position': 'Detect location',
    'search-search-results-but-no-destination':
      'View results in the adjacent “About the route or stop” tab',
    'searching-position': 'Detecting location...',
    'select-map-layers-header': 'What should be shown on the map?',
    'select-position': 'Select your location from your previous searches',
    send: 'Send',
    settings: 'Settings',
    'settings-label-change': 'Change settings',
    'settings-loadbutton': 'Load settings',
    'settings-loaded': 'Settings loaded!',
    'settings-reset': 'Reset settings',
    'settings-savebutton': 'Save settings',
    'settings-saved': 'Changes saved!',
    share: 'Share itinerary',
    'show-departures': 'Show departures',
    'show-information-as-list': 'Show information as list',
    'show-more': 'Show more',
    'show-realtime-on-map': 'Show vehicles on map',
    'show-routes': 'Show routes',
    'skip-positioning': 'Skip',
    slow: 'Slow',
    'snow-depth': 'Snow depth',
    'specify-location': 'Specify location',
    'speed-limit': 'Speed limit',
    'splash-choose': 'Specify origin',
    'splash-locating': 'Detecting location',
    'splash-or-choose': 'or select your origin',
    'splash-use-positioning': 'Use location services',
    'splash-welcome': 'How do you wish to start?',
    'splash-you-can-also': 'or',
    stop: 'Stop',
    'stop-number': 'Stop number',
    'stop-page.description': 'Stop {name} - {code}',
    'stop-page.title': 'Stop {name} - {code}',
    'stop-page.title-short': 'Stop',
    stops: 'Stops',
    'street-mode-bicycle': 'Bicycle',
    'street-mode-car': 'Car',
    'street-mode-car_park': 'Park & ride',
    'street-mode-public_transport': 'Public transport',
    'street-mode-walk': 'Walking',
    subway: 'Metro',
    'subway-with-route-number': 'Metro {routeNumber} {headSign}',
    'summary-page.description': '{from} - {to}',
    'summary-page.title': 'Itinerary suggestions',
    'terminal-page.description': 'Terminal {stop_name}',
    'terminal-page.title': 'Terminal {stop_name}',
    'terminal-page.title-short': 'Terminal',
    'terms-of-use': 'Terms of Use',
    'ticket-and-price-info': 'Ticket and price information',
    'ticket-single-adult': 'Adult',
    'ticket-type-none': 'No fare zone limits',
    tickets: 'Tickets',
    time: 'Time',
    timetable: 'Timetable',
    'to-city': 'Towards the city',
    'to-stop': 'To stop',
    today: 'Today',
    tomorrow: 'Tomorrow',
    'traffic-announcement-additional-info': 'Additional info',
    'traffic-announcement-additional-info-at': 'Heating with fire',
    'traffic-announcement-additional-info-fd': 'Follow directions',
    'traffic-announcement-additional-info-pd': 'Police is directing traffic',
    'traffic-announcement-class-acc': 'Onnettomuus',
    'traffic-announcement-class-act': 'Yleisö- tai muut tapahtumat',
    'traffic-announcement-class-equ': 'Tienvarsilaitteiden toiminta',
    'traffic-announcement-class-fos': 'Näkyvyys- ja ympäristöolosuhteet',
    'traffic-announcement-class-krk': 'Kelirikko',
    'traffic-announcement-class-los': 'Liikennetilanne',
    'traffic-announcement-class-mhz':
      'Vaaraa aiheuttava ajoneuvo tai kuljetus tiellä',
    'traffic-announcement-class-ohz': 'Este esine tai eläin tiellä',
    'traffic-announcement-class-res': 'Liikennerajoitukset',
    'traffic-announcement-class-rmt': 'Tietyö',
    'traffic-announcement-class-shz': 'Ajo-olot liukkaus kelitilanne',
    'traffic-announcement-duration': 'Estimated duration',
    'traffic-announcement-duration-gte_180_lte_360': '3 - 6 hours',
    'traffic-announcement-duration-gte_30_lte_60': '30 - 60 minutes',
    'traffic-announcement-duration-gte_360': 'More than 6 hours',
    'traffic-announcement-duration-gte_60_lte_180': '1 - 3 hours',
    'traffic-announcement-duration-lte_30': 'Under 30 minutes',
    'traffic-announcement-modes-of-transport': 'Traffic type',
    'traffic-announcement-modes-of-transport-bicycle': 'Bicycle',
    'traffic-announcement-modes-of-transport-car': 'Car',
    'traffic-announcement-modes-of-transport-pedestrian': 'Pedestrian',
    'traffic-announcement-modes-of-transport-public_transport':
      'Public transport',
    'traffic-announcement-oversize-load': 'Special transport',
    'traffic-announcement-oversize-load-definition': '(W. x H. x L. in meters)',
    'traffic-announcement-severity': 'Severity',
    'traffic-announcement-severity-high': 'Significantly harms traffic',
    'traffic-announcement-severity-low': 'Does not harm traffic',
    'traffic-announcement-severity-medium': 'Harms traffic',
    'traffic-announcement-subclass-abl': 'Erikoiskuljetus',
    'traffic-announcement-subclass-acb': 'Onnettomuus, jossa mukana linja-auto',
    'traffic-announcement-subclass-ach':
      'Onnettomuus, jossa mukana raskas ajoneuvo',
    'traffic-announcement-subclass-aci': 'Onnettomuus',
    'traffic-announcement-subclass-acl': 'Onnettomuuspaikan raivaus käynnissä',
    'traffic-announcement-subclass-acm': 'Usean ajoneuvon onnettomuus',
    'traffic-announcement-subclass-acz':
      'Onnettomuus, jossa mukana vaarallisia aineita',
    'traffic-announcement-subclass-ali': 'Alikulkukäytävän rakentaminen',
    'traffic-announcement-subclass-anh': 'Poroja tiel',
    'traffic-announcement-subclass-anl': 'Hirviä tiellä',
    'traffic-announcement-subclass-anm': 'Eläimiä tiellä',
    'traffic-announcement-subclass-aol': 'Raskas ajoneuvo kaatunut tielle',
    'traffic-announcement-subclass-aqd': 'Vesiliirron vaara',
    'traffic-announcement-subclass-asp': 'Ulosajo',
    'traffic-announcement-subclass-bkd': 'Rikkoutunut ajoneuvo tiellä',
    'traffic-announcement-subclass-bli': 'Lumimyrsky',
    'traffic-announcement-subclass-bls': 'Pöllyävää lunta',
    'traffic-announcement-subclass-brc': 'Silta suljettu liikenteeltä',
    'traffic-announcement-subclass-ctr':
      'Liikenne ohjataan kaksisuuntaisena toiselle ajoradalle',
    'traffic-announcement-subclass-dcd': 'Huono ajokeli',
    'traffic-announcement-subclass-dcn': 'Ajo-olot parantuneet',
    'traffic-announcement-subclass-do': 'Kiertotie käytössä',
    'traffic-announcement-subclass-efa': 'Messut',
    'traffic-announcement-subclass-ens': 'ENSITIEDOTE LIIKENNEONNETTOMUUDESTA',
    'traffic-announcement-subclass-epr': 'Kulkue',
    'traffic-announcement-subclass-esp': 'Urheilutapahtuma',
    'traffic-announcement-subclass-evd': 'Mielenosoitus',
    'traffic-announcement-subclass-evm': 'Yleisötapahtuma',
    'traffic-announcement-subclass-fig': 'Maastopalo',
    'traffic-announcement-subclass-fir': 'Tulipalo',
    'traffic-announcement-subclass-fld': 'Tulva',
    'traffic-announcement-subclass-flt': 'Puu kaatunut tielle',
    'traffic-announcement-subclass-fod': 'Sakeaa sumua',
    'traffic-announcement-subclass-fof': 'Jäätävää sumua',
    'traffic-announcement-subclass-fog': 'Sumua',
    'traffic-announcement-subclass-fop': 'Paikoitellen sumua',
    'traffic-announcement-subclass-fpc': 'Sähköjohtoja pudonnut tielle',
    'traffic-announcement-subclass-fue': 'Öljyä tiellä',
    'traffic-announcement-subclass-gal': 'Kova tuuli',
    'traffic-announcement-subclass-hai': 'Raekuuro',
    'traffic-announcement-subclass-hav': 'Hirviaita vaurioitunut',
    'traffic-announcement-subclass-hbd': 'Rikkoutunut raskas ajoneuvo tiellä',
    'traffic-announcement-subclass-ibu': 'Tiet jäätymässä',
    'traffic-announcement-subclass-icp': 'Paikoin liukasta',
    'traffic-announcement-subclass-jjm':
      'Kevyen liikenteen väylät jäisiä ja märkiä',
    'traffic-announcement-subclass-jjt': 'Kevyen liikenteen väylät jäätymäs',
    'traffic-announcement-subclass-jli': 'Kevyen liikenteen väylät liukkaita',
    'traffic-announcement-subclass-jlu': 'Kevyen liikenteen väylillä lunta',
    'traffic-announcement-subclass-jpl':
      'Kevyen liikenteen väylät paikoin liukkaita',
    'traffic-announcement-subclass-jyr': 'Päällysteen jyrsintätyö',
    'traffic-announcement-subclass-kai': 'Sadevesikaivojen puhdistustyö',
    'traffic-announcement-subclass-kav': 'Kaidevaurio',
    'traffic-announcement-subclass-kdt': 'Kaidetyö',
    'traffic-announcement-subclass-kkp': 'Kaivon kansi rikki/poissa paikaltaan',
    'traffic-announcement-subclass-klv': 'Kevyenliikenteen väylän rakentaminen',
    'traffic-announcement-subclass-kri': 'Kelirikko',
    'traffic-announcement-subclass-kuo': 'Kuoppia tiessä',
    'traffic-announcement-subclass-lc1': 'Yksi ajokaista suljettu liikenteeltä',
    'traffic-announcement-subclass-lc2':
      'Kaksi ajokaistaa suljettu liikenteeltä',
    'traffic-announcement-subclass-lcs': 'Ajokaistoja on suljettu liikenteeltä',
    'traffic-announcement-subclass-lij': 'Liittymän parannustyö',
    'traffic-announcement-subclass-lin': 'Ajokaista ajoittain suljettu',
    'traffic-announcement-subclass-lo1': 'Vain yksi ajokaista käytössä',
    'traffic-announcement-subclass-lo2': 'Vain kaksi kaistaa käytössä',
    'traffic-announcement-subclass-loh': 'Tienvarsilaitteiden huoltotyö',
    'traffic-announcement-subclass-lpa': 'Liikenne pysäytetään ajoittain',
    'traffic-announcement-subclass-ls1': 'Liikenne seisoo',
    'traffic-announcement-subclass-ls2': 'Liikenne pysähtelee',
    'traffic-announcement-subclass-ls3': 'Liikenne hidasta',
    'traffic-announcement-subclass-ls4': 'Liikenne jonoutunut',
    'traffic-announcement-subclass-ls6': 'Pitkiä jonoja',
    'traffic-announcement-subclass-lsl': 'Tie sortunut',
    'traffic-announcement-subclass-lso': 'Liikenne saattaa ruuhkautua',
    'traffic-announcement-subclass-lsr': 'Liikenne saattaa ruuhkautua',
    'traffic-announcement-subclass-lvt': 'Liikennevalotyö',
    'traffic-announcement-subclass-mar': 'Maratonjuoksu',
    'traffic-announcement-subclass-mil': 'Sotilassaattue',
    'traffic-announcement-subclass-nit': 'Niittotyö',
    'traffic-announcement-subclass-nls': 'Ajokaistoja kavennettu',
    'traffic-announcement-subclass-ohx': 'Liikennettä vaarantava este tiellä',
    'traffic-announcement-subclass-oil': 'Polttoainetta tiellä',
    'traffic-announcement-subclass-pav': 'Päällystevaurio',
    'traffic-announcement-subclass-pel': 'Pelastusharjoitus',
    'traffic-announcement-subclass-pki': 'POLIISIN KELI-ILMOITUS',
    'traffic-announcement-subclass-pys': 'Pysäkkiin liittyvä ilmoitus',
    'traffic-announcement-subclass-rad': 'Kiertotieopastus',
    'traffic-announcement-subclass-raf': 'Jäätävää sadetta',
    'traffic-announcement-subclass-rai': 'Vesisade',
    'traffic-announcement-subclass-raj': 'Raskas ajoneuvo jumissa',
    'traffic-announcement-subclass-rba': 'Liikenne pysäytetään',
    'traffic-announcement-subclass-rbi': 'Mustaa jäätä',
    'traffic-announcement-subclass-rbl': 'Räjäytystyö',
    'traffic-announcement-subclass-rbm': 'Siltatyö',
    'traffic-announcement-subclass-rca': 'Toinen ajorata suljettu liikenteeltä',
    'traffic-announcement-subclass-rcd': 'Tie suljettu liikenteeltä',
    'traffic-announcement-subclass-rcw': 'Tienrakennustyö',
    'traffic-announcement-subclass-reb': 'Liittymisramppi tukossa',
    'traffic-announcement-subclass-rec': 'Ramppi suljettu liikenteeltä',
    'traffic-announcement-subclass-ret': 'Poikkeavat kaistajärjestelyt',
    'traffic-announcement-subclass-rew': 'Raivaus- ja korjaustöitä',
    'traffic-announcement-subclass-ric': 'Tiet ovat jäisiä',
    'traffic-announcement-subclass-ril': 'Risteykset liukkaita',
    'traffic-announcement-subclass-rin': 'Tie ajoittain suljettu liikenteeltä',
    'traffic-announcement-subclass-rmk': 'Kunnossapito- ja hoitotyöt',
    'traffic-announcement-subclass-rmv': 'Työmaa-ajoa liikenteen seassa',
    'traffic-announcement-subclass-rmw': 'Tiemerkintätyö',
    'traffic-announcement-subclass-rnl': 'Muuttuneet liikennejärjestelyt',
    'traffic-announcement-subclass-rno': 'Rekan nosto',
    'traffic-announcement-subclass-rpc': 'Tienpinta huonossa kunnossa',
    'traffic-announcement-subclass-rrw': 'Tietyö keskikaistalla',
    'traffic-announcement-subclass-rsl': 'Tiet ovat liukkaita',
    'traffic-announcement-subclass-rwi': 'Tiet jäisiä ja märkiä',
    'traffic-announcement-subclass-rwk': 'Tietyö',
    'traffic-announcement-subclass-rwl': 'Pitkäaikainen tienparannustyö',
    'traffic-announcement-subclass-rwm': 'Tienparannustyö',
    'traffic-announcement-subclass-rwr': 'Päällystystyö',
    'traffic-announcement-subclass-rxb': 'Poistumisramppi tukossa',
    'traffic-announcement-subclass-sat':
      'Kohtaava liikenne, ajo vuorotellen ohjauksen mukaan',
    'traffic-announcement-subclass-sav': 'Savea tiellä',
    'traffic-announcement-subclass-sfh': 'Sakea lumisade',
    'traffic-announcement-subclass-sfl': 'Lumisade',
    'traffic-announcement-subclass-shl': 'Kuorma levinnyt tielle',
    'traffic-announcement-subclass-sjt': 'Sähköjohtotyö',
    'traffic-announcement-subclass-slt': 'Räntäsade',
    'traffic-announcement-subclass-slu': 'Lumisohjoa',
    'traffic-announcement-subclass-smo': 'Savu haittaa näkyvyyttä',
    'traffic-announcement-subclass-smv':
      'Nestettä vuotanut liikkuvasta ajoneuvosta',
    'traffic-announcement-subclass-snp': 'Lumi- tai jääpolanne',
    'traffic-announcement-subclass-snr': 'Lumikinoksia',
    'traffic-announcement-subclass-snw': 'Kaistaopasteet eivät toimi',
    'traffic-announcement-subclass-sok': 'Sivuojan kaivuutyö',
    'traffic-announcement-subclass-son': 'SUURONNETTOMUUS',
    'traffic-announcement-subclass-spk': 'Sirotepaikkaus, irtokiviä',
    'traffic-announcement-subclass-spy': 'Roiskevesi haittaa näkyvyyttä',
    'traffic-announcement-subclass-sro': 'Lunta tiellä',
    'traffic-announcement-subclass-std': 'Myrskyvaurioita',
    'traffic-announcement-subclass-stk': 'Soratien kunto huono',
    'traffic-announcement-subclass-stm': 'Myrsky',
    'traffic-announcement-subclass-taa': 'Toinen ajokaista avattu liikenteelle',
    'traffic-announcement-subclass-tal': 'Akselipainorajoitus',
    'traffic-announcement-subclass-tav': 'Tie on avattu liikenteelle',
    'traffic-announcement-subclass-tbu': 'Liikenne ruuhkautuu',
    'traffic-announcement-subclass-tcn': 'Liikenneruuhka',
    'traffic-announcement-subclass-tcx': 'Liikenneruuhka ohi',
    'traffic-announcement-subclass-tea': 'Liikenne on palautumassa normaaliksi',
    'traffic-announcement-subclass-tgw': 'Painorajoitus asetettu',
    'traffic-announcement-subclass-tlo': 'Liikennevalot eivät toimi',
    'traffic-announcement-subclass-tlt': 'Tilapäinen liikennevalo-ohjaus',
    'traffic-announcement-subclass-tmt': 'Tutkimus- ja mittaustyö',
    'traffic-announcement-subclass-tnr': 'Tilapäinen nopeusrajoitus',
    'traffic-announcement-subclass-tol': 'Tie on liukas',
    'traffic-announcement-subclass-tri': 'Tasoristeystyö',
    'traffic-announcement-subclass-tuc': 'Tunneli suljettu liikenteeltä',
    'traffic-announcement-subclass-tvl': 'Tievalaistuksen lamppuja pimeänä',
    'traffic-announcement-subclass-tvm': 'Tievalaistus vioittunut myrskyssä',
    'traffic-announcement-subclass-tvo':
      'Tievalaistus vioittunut onnettomuudessa',
    'traffic-announcement-subclass-tvt': 'Tievalaistustyö',
    'traffic-announcement-subclass-vet': 'Vettä tiellä',
    'traffic-announcement-subclass-vfr': 'Palava ajoneuvo',
    'traffic-announcement-subclass-vir': 'Näkyvyys heikentynyt',
    'traffic-announcement-subclass-vka': 'Järjestelmän vikatila',
    'traffic-announcement-subclass-vmt': 'Viimeistelytyö',
    'traffic-announcement-subclass-vnw': 'Muuttuvat opasteet eivät toimi',
    'traffic-announcement-subclass-vrt': 'Vesakon raivaus',
    'traffic-announcement-subclass-vwc': 'Ajoneuvo väärällä ajoradalla',
    'traffic-announcement-subclass-wbc': 'Kaapelityö',
    'traffic-announcement-subclass-wbs': 'Kunnallistekninen työ',
    'traffic-announcement-subclass-wic': 'Kova sivutuuli',
    'traffic-announcement-subclass-wig': 'Puuskittainen tuuli',
    'traffic-announcement-subclass-yak':
      'Työkohteessa vain yksi ajokaista käytössä',
    'traffic-announcement-temporary-speed-limit': 'Temporary speed limit',
    'traffic-announcement-traffic-direction': 'Direction',
    'traffic-announcement-traffic-direction-both': 'Both directions',
    'traffic-announcement-traffic-direction-negative': 'Opposite direction',
    'traffic-announcement-traffic-direction-positive': 'Chosen direction',
    'traffic-announcement-vehicle-size-limit': 'Vehicle limitations',
    'traffic-announcement-vehicle-size-limit-definition':
      '(W. x H. x L. x Weight in meters and tons)',
    'traffic-camera': 'Traffic camera',
    'traffic-camera-no-recent-images': 'No recent images',
    'traffic-count': 'Traffic count',
    'traffic-direction': 'Direction',
    'traffic-flow': 'Traffic flow',
    'traffic-flow-heavy': 'Rush',
    'traffic-flow-medium': 'Slow',
    'traffic-flow-normal': 'Normal',
    'traffic-flow-unknown': 'Unknown',
    'traffic-info': 'Traffic information',
    'traffic-monitoring': 'Traffic monitoring',
    'traffic-restriction': 'Traffic restriction',
    tram: 'Tram',
    'tram-with-route-number': 'Tram {routeNumber} {headSign}',
    transfers: 'Number of transfers',
    'transfers-allowed': 'Transfers allowed',
    'transfers-margin': 'Transfer margin at least',
    'trip-co2-emissions': 'CO2 emissions of the journey',
    'trip-information': 'Trip information',
    'trip-page.description': 'Route {route_short_name} - {route_long_name}',
    'trip-page.title': 'Route {route_short_name}',
    'try-again': 'Try again',
    unpreferred: 'Avoid routes',
    'use-citybike': 'Start using',
    'use-national-service':
      'You can also try the national service available at',
    'use-own-position': 'Use current location',
    'using-modes': 'I want to travel by',
    'very-likely': 'Very likely',
    'very-unlikely': 'Very unlikely',
    'via-leg-stop-duration': 'At via point {stayDuration}',
    'via-point': 'Via point',
    'viapoint-remove': 'Remove viapoint',
    'viapoint-slack-amount': 'Stop duration',
    'view-route': 'View Route',
    wait: 'Wait',
    'wait-amount-of-time': 'Wait {duration}',
    walk: 'walking',
    'walk-distance-duration': 'Walk {distance} ({duration})',
    walking: 'Amount of walking',
    'walking-speed': 'Walking speed',
    'warning-call-agency':
      'Only on demand: {routeName}, which needs to be booked in advance.',
    'warning-call-agency-no-route':
      'Only on demand. Needs to be booked in advance.',
    'weather-at-destination': 'Weather at the destination',
    'weather-stations': 'Weather stations',
    weekly: 'Weekly',
    'wind-direction': 'Wind direction',
    'wind-speed': 'Wind speed',
    north: 'North',
    northeast: 'Northeast',
    east: 'East',
    southeast: 'Southeast',
    south: 'South',
    southwest: 'Southwest',
    west: 'West',
    northwest: 'Northwest',
    yesterday: 'Yesterday',
    'your-favourites': 'Favorites',
    zones: 'Fare zones',
  },
  es: {
    'about-this-service': 'Sobre el servicio',
    accessibility: 'Accesibilidad',
    'accessibility-limited': 'Silla de ruedas',
    'accessibility-nolimit': 'Sin límites',
    'add-itinerary-via-point': 'Añadir punto intermedio al itinerario',
    'add-location': 'Añadir localización',
    'add-location-to-favourites':
      'Añadir una importante localización a tus Favoritos',
    address: 'Dirección',
    'address-or-stop': 'Dirección o parada',
    agency: 'Operador',
    airplane: 'Vuelo',
    'airplane-with-route-number': 'Vuelo {routeNumber}',
    'airport-check-in': 'Facture en el mostrador de {agency}',
    'airport-collect-luggage': 'Recoja su equipaje',
    'airport-security-check-go-to-gate':
      'Proceda a su puerta a través del control de seguridad',
    'all-routes': 'Todas las rutas',
    'all-routes-disabled': 'Ninguna ruta',
    'approx-meters': '{approxDistance} m',
    'arriving-at': 'Llegada',
    'arriving-soon': 'Ahora',
    'avoid-transfers': 'Evitar transbordos',
    'avoid-walking': 'Evitar andar',
    'back-to-front-page': 'Volver a la página principal',
    bicycle: 'bicicleta',
    'bicycle-distance-duration': 'Bicicleta {distance} ({duration})',
    'bike-availability': 'Bicicletas disponibles actualmente en la estación',
    'bike-availability-short': 'Bicicletas',
    bus: 'Bus',
    'bus-with-route-number': 'Bus {routeNumber} {headSign}',
    'buy-ticket': 'Cómo comprar un ticket (HSL.fi)',
    call: 'Llamar',
    cancel: 'Cancelar',
    car: 'Coche',
    'car-distance-duration': 'Conducir {distance} ({duration})',
    car_park: 'park & ride',
    cause: 'causa',
    'char-left': 'carácteres',
    'choose-stop': 'Seleccione parada',
    citybike: 'Bicicleta pública',
    'citybike-distance-duration': 'Bicicleta {distance} ({duration})',
    'citybike-register-required':
      'Para usar la red de bicicleta pública, necesita registrarse.',
    citybike_off: 'Cerrada',
    close: 'Cerrar',
    'contact-information': '{contactName} detalles de contacto',
    'create-account': 'Crear cuenta {contactName}',
    'cycle-distance-duration': 'Ir en bici {distance} ({duration})',
    'cyclewalk-distance-duration':
      'Anda junto a tu bici {distance} ({duration})',
    datasources: 'Fuentes de datos',
    date: 'Fecha',
    delete: 'Eliminar',
    'departure-time-in-minutes': '{minutes} min',
    departures: 'Salidas',
    description:
      'Encuentre servicios de transporte público a localizaciones cercanas y lejanas.',
    destination: 'Destino',
    'destination-outside-service':
      'Desafortunadamente no hemos encontrado rutas para su viaje. Su destino está fuera del área de servicio. Por favor cambie el destino.',
    'destination-placeholder': 'Introduzca destino, ruta or parada',
    'digitransit-platform': 'Plataforma del servicio Digitransit',
    disruption: 'Incidencia',
    'disruption-info': 'Información sobre incidencias',
    'disruption-info-no-alerts': 'No hay desvíos o incidencias conocidos.',
    'disruption-info-route-no-alerts':
      'No hay desvíos o incidencias conocidos en la ruta.',
    disruptions: 'Incidencias',
    'distance-between': 'Distancia {distance1} m — {distance2} m',
    'distance-under': 'Distancia menor que {distance} m',
    earlier: 'Antes',
    'edit-favourites': 'Edite la localización en los Favoritos',
    explanations: 'Explicaciones',
    'export-time-range-1': 'Export time range 1',
    'export-time-range-2': 'Export time range 2',
    'extra-info': 'Más información',
    'favourite-target': 'Localización favorita',
    ferry: 'Ferry',
    'ferry-with-route-number': 'Ferry {routeNumber} {headSign}',
    roadinspection: 'Road inspection',
    'roadinspection-vehicle-time-range': 'Road inspection timerange: 30 days',
    'realtime-roadinspection-vehicles': 'Realtime road inspection',
    'footer-feedback': 'Envíe comentarios',
    'footer-link-to-privacy-policy': 'Política de privacidad',
    frontpage: 'Página principal',
    'generic-error': 'Ha habido un error',
    'geolocate-yourself': 'Detecte su localización',
    'geolocation-denied-heading': 'Geolocalización denegada',
    'geolocation-denied-text':
      'No ha dado permiso para usar su geolocalización. Puede permitirlo desde los ajustes de su teléfono o navegador web.',
    'geolocation-failed-heading': 'Geolocalización fallida',
    'geolocation-failed-text':
      'Su navegador no soporta la obtención de localización.',
    'geolocation-prompt-text':
      'Acepte la petición de su navegador para acceder a su localización.',
    'geolocation-timeout-heading':
      'Detectar su localización está llevando más tiempo del esperado.',
    'geolocation-timeout-text':
      '¿Ha aceptado la petición de su navegador para acceder a su localización?',
    'give-destination': 'Busque destino',
    'give-name-to-location': 'De un nombre descriptivo a la localización',
    'give-origin': 'Introduzca su origen.',
    'give-position':
      'Introduzca su localización u origen en el cuadro de búsqueda.',
    hour: 'Hora',
    'hour-short': 'h',
    'how-to-rate-service': '¿Qué nota le pondría al servicio?',
    'in-addition': 'Como añadido',
    inquiry: '¿Qué le ha parecido el nuevo ruteador? ¡Cuéntenos!',
    instructions: 'Instrucciones',
    'itinerary-feedback-button': 'Enviar comentarios',
    'itinerary-feedback-message': '¿No encuentra lo que estaba buscando?',
    'itinerary-feedback-placeholder': 'Descripción (opcional)',
    'itinerary-hide-stops': 'Ocultar paradas',
    'itinerary-page.description': 'Itinerario',
    'itinerary-page.title': 'Itinerario',
    'itinerary-summary-page.description': 'Sugerencias de ruta',
    'itinerary-summary-page.title': 'Sugerencias de ruta',
    'itinerary-ticket.title': 'Ticket requerido',
    'itinerary-tickets.title': 'Tickets requeridos',
    'itinerary-time.title': 'Duración',
    'itinerary-walk.title': 'Distancia total caminando',
    'journeyplanner.title': 'Ruteador de HSL',
    later: 'Después',
    leaves: 'Sale',
    'leaving-at': 'Sale a las',
    'likely-to-recommend':
      '¿Recomendaría el servicio a sus amigos o conocidos?',
    'likely-to-use': '¿Usaría este servicio en vez del ruteador actual?',
    loading: 'Cargando...',
    locate: 'Detectando localización',
    'location-examples': 'p.ej. Casa, Trabajo, Colegio,...',
    'location-from-map': 'Localización seleccionada',
    login: 'Iniciar sesión',
    'long-transfers-margin': '12 min',
    'main-menu-label-close': 'Cerrar el menú principal',
    'main-menu-label-open': 'Abrir el menú principal',
    'main-mode': 'Viajo mediante',
    map: 'Mapa',
    'messagebar-label-close-message-bar': 'Cerrar el banner',
    'messagebar-label-page': 'Página',
    'minute-short': 'min',
    minutes: 'min',
    'minutes-or-route': 'Min / Ruta',
    'more-settings': 'Más configuración',
    navigate: 'Navegar',
    'near-you': 'Cerca de ud.',
    nearest: 'Más cercano',
    'network-error': 'Error de red',
    next: 'Siguiente',
    'no-favourite-locations':
      'Añada sus destinos favoritos aquí. Los botones le crearán la ruta de un clic.',
    'no-favourites':
      'Clique en las estrellas para añadir sus rutas o paradas favoritas. Sus favoritos se enseñarán en esta página.',
    'no-position': 'Sin localización',
    'no-position-no-stops':
      'Su localización no está disponible. No podemos enseñarle las paradas cercanas.',
    'no-route-end-date-not-in-range':
      'Los horarios actuales no incluyen rutas saliendo más tarde.',
    'no-route-msg':
      'Desafortunadamente no se encontraron rutas para su viaje. Por favor cambie el origen o el destino.',
    'no-route-start-date-too-early':
      'Los horarios actuales no incluyen rutas saliendo antes.',
    'no-route-start-end': 'Por favor seleccione origen y destino.',
    'no-transfers-margin': '1 min',
    'no-trips-found': 'No se han encontrado viajes para la fecha seleccionada.',
    now: 'Ahora',
    'number-of-intermediate-stops':
      '{number, plural, =0 {Sin paradas} one {1 parada} other {{number} paradas} }',
    'off-canvas-info':
      'Inicie sesión para guardar sus favoritos y utilizarlos en otros dispositivos',
    or: 'o',
    origin: 'Origen',
    'origin-outside-service':
      'Desafortunadamente no hemos encontrado rutas para su viaje. Su origen está fuera del área de servicio. Por favor cambie el destino.',
    'origin-placeholder': '¿Desde? - dirección o parada',
    'own-position': 'Su posición actual',
    'page-not-found': 'La página no se puede encontrar.',
    'park-and-ride': 'Park and Ride',
    'park-and-ride-availability': 'Espacios disponibles',
    'parking-availability': 'Espacios disponibles',
    'pay-attention': 'N.B.',
    'pick-icon': 'Seleccione icono',
    'place-route-or-keyword': 'Destino, ruta o parada',
    'platform-num': 'Platforma {platformCode}',
    'platform-short': 'Plat. {platformCode}',
    'prefer-walking': 'Preferir andar',
    previous: 'Anterior',
    print: 'Imprimir',
    rail: 'Tren',
    'rail-with-route-number': 'Tren {routeNumber} {headSign}',
    realtime: 'Tiempo real',
    'realtime-matching': 'Integración del tiempo real',
    'rent-cycle-at': 'Alquile una bici en la estación {station}',
    'required-ticket': 'Ticket requerido para el viaje',
    'right-now': 'Ahora mismo',
    route: 'Ruta',
    'route-default': 'Configuración por defecto',
    'route-destination-arrives': 'Llegada / Fin de línea',
    'route-fastest': 'Ruta rápida',
    'route-from-here': 'Ruta desde aquí',
    'route-guide': 'Guía de la ruta',
    'route-here': 'Ruta hacia aquí',
    'route-least-transfers': 'Evitar transbordos',
    'route-least-walking': 'Evitar andar',
    'route-not-possible':
      'Desafortunadamente, su ruta no está disponible. Error: "{error}"',
    'route-page.description': 'Ruta {shortName} - {longName}',
    'route-page.title': 'Ruta {shortName}',
    'route-page.title-short': 'Ruta',
    'route-saved-settings': 'Configuración propia',
    'route-stop-or-keyword': 'Sobre la ruta o parada',
    routes: 'Rutas',
    run: 'Correr',
    save: 'Guardar',
    search: 'Buscar',
    'search-destination': 'Busque destino, ruta o parada',
    'search-destination-results-but-no-search':
      'Ver resultados en la pestaña de “Destino”',
    'search-no-results': 'Sin localización',
    'search-origin': 'Busque origen, ruta o parada',
    'search-position': 'Detectar localización',
    'search-search-results-but-no-destination':
      'Ver resultados en la pestaña “Sobre la ruta o la parada”',
    'searching-position': 'Detectando localización...',
    'select-position': 'Seleccione su localización de las búsquedas anteriores',
    send: 'Enviar',
    settings: 'Configuración',
    'settings-reset': 'Resetear configuración',
    'settings-savebutton': 'Guardar configuración',
    'settings-saved': '¡Cambios guardados!',
    share: 'Compartir itinerario',
    'show-departures': 'Ver salidas',
    'show-more': 'Ver más',
    'show-routes': 'Ver rutas',
    'skip-positioning': 'Saltar',
    slow: 'Lento',
    'specify-location': 'Especificar localización',
    'splash-choose': 'Especificar origen',
    'splash-locating': 'Detectando localización',
    'splash-or-choose': 'o seleccione su origen',
    'splash-use-positioning': 'Usar servicios de localización',
    'splash-welcome': '¿Cómo desea empezar?',
    'splash-you-can-also': 'o',
    stop: 'Parada',
    'stop-number': 'Número de parada',
    'stop-page.description': 'Parada {name} - {code}',
    'stop-page.title': 'Parada {name} - {code}',
    'stop-page.title-short': 'Parada',
    stops: 'Paradas',
    subway: 'Metro',
    'subway-with-route-number': 'Metro {routeNumber} {headSign}',
    'terminal-page.description': 'Terminal {stop_name}',
    'terminal-page.title': 'Terminal {stop_name}',
    'terminal-page.title-short': 'Terminal',
    'terms-of-use': 'Términos de uso',
    'ticket-and-price-info':
      'Información sobre precios y títulos de transporte',
    'ticket-single-adult': 'Adulto',
    'ticket-type-none': 'Sin límites de tarifas',
    tickets: 'Tickets',
    time: 'Tiempo',
    timetable: 'Horario',
    'to-stop': 'Hasta la parada',
    today: 'Hoy',
    tomorrow: 'Mañana',
    tram: 'Tranvía',
    'tram-with-route-number': 'Tranvía {routeNumber} {headSign}',
    transfers: 'Número de transbordos',
    'transfers-allowed': 'Transbordos permitidos',
    'transfers-margin': 'Márgen de transbordos de al menos',
    'trip-co2-emissions': 'Emisiones de CO2 del viaje',
    'trip-information': 'Información sobre el viaje',
    'trip-page.description': 'Ruta {route_short_name} - {route_long_name}',
    'trip-page.title': 'Ruta {route_short_name}',
    'try-again': 'Pruebe de nuevo',
    'use-citybike': 'Empiece a usar',
    'use-national-service':
      'También puede probar el servicio nacional disponible en',
    'use-own-position': 'Usar localización actual',
    'using-modes': 'Quiero viajar mediante',
    'very-likely': 'Muy probable',
    'very-unlikely': 'Muy poco probable',
    'via-point': 'Punto intermedio',
    'view-route': 'Ver ruta',
    wait: 'Esperar',
    'wait-amount-of-time': 'Esperar {duration}',
    walk: 'andando',
    'walk-distance-duration': 'Andar {distance} ({duration})',
    walking: 'Cantidad de andar',
    'walking-speed': 'Velocidad de andar',
    'warning-call-agency':
      'Sólo bajo demanda: {routeName}, que necesita ser reservada con antelación.',
    'warning-call-agency-no-route':
      'Sólo bajo demanda, necesita ser reservado con antelación.',
    'weather-at-destination': 'Tiempo en destino',
    yesterday: 'Ayer',
    'your-favourites': 'Favoritos',
    zones: 'Zonas tarifarias',
  },
  fi: {
    '1. time range': '1. ajanjakso',
    '2. time range': '2. ajanjakso',
    'about-this-service': 'Tietoja palvelusta',
    accessibility: 'Esteettömyys',
    'accessibility-limited': 'Liikun pyörätuolilla',
    'accessibility-nolimit': 'Ei rajoitusta',
    'accessibility-report': 'Saavutettavuusseloste',
    'add-itinerary-via-point': 'Lisää reitille välipiste',
    'add-location': 'Lisää paikka',
    'add-location-to-favourites':
      'Lisää sinulle tärkeä paikka Suosikkisi-välilehdelle',
    'additional-analytics': 'Lisäanalytiikka',
    address: 'Osoite',
    'address-or-stop': 'Osoite tai pysäkki',
    agency: 'Liikennöitsijä',
    'air-humidity': 'Ilman kosteus',
    'air-temperature': 'Ilman lämpötila',
    airplane: 'Lentokone',
    'airplane-with-route-number': 'Lento {routeNumber}',
    'airport-check-in': 'Tee lähtöselvitys lentoyhtiön {agency} tiskillä',
    'airport-collect-luggage': 'Nouda matkatavarat',
    'airport-security-check-go-to-gate':
      'Kävele turvatarkastuksen läpi lähtöportille',
    'all-routes': 'Kaikki linjat',
    'all-routes-disabled': 'Ei linjoja',
    'approx-meters': '{approxDistance} m',
    'arriving-at': 'Perillä',
    'arriving-soon': 'Nyt',
    'average-speed': 'Keskinopeus',
    'avoid-transfers': 'Vähän vaihtoja',
    'avoid-walking': 'Vähän kävelyä',
    back: 'Takaisin',
    'back-button-title': 'Siirry takaisin edelliselle sivulle',
    'back-to-front-page': 'Siirry etusivulle ›',
    bicycle: 'pyörällä',
    'bicycle-distance-duration': 'Pyöräile {distance} ({duration})',
    'bicycle-route-prop-name': 'Nimi',
    'bicycle-route-prop-type': 'Tyyppi',
    'bicycle-route-prop-type-PLANNED': 'Suunnitteilla',
    'bicycle-route-prop-type-CURRENT': 'Nykyinen',
    'bicycle-route-prop-year': 'Valmistumisvuosi',
    'bicycle-route-prop-length': 'Pituus',
    'bicycle-routes': 'Pyörätiet',
    'bicycle-routes-BAANA': 'Baanat',
    'bicycle-routes-BAANA-CURRENT': 'Baana',
    'bicycle-routes-BAANA-PLANNED': 'Suunnitteilla oleva baana',
    'bicycle-routes-BRAND': 'Brändireitit',
    'bicycle-routes-BRAND-MAIN': 'Brändireitti',
    'bicycle-routes-BRAND-PERIMETER': 'Kehäreitti',
    'bicycle-routes-MAIN_REGIONAL': 'Pää- ja aluereitit',
    'bicycle-routes-MAIN_REGIONAL-MAIN': 'Pääreitti',
    'bicycle-routes-MAIN_REGIONAL-REGIONAL': 'Aluereitti',
    'bicycle-routes-MAIN_REGIONAL-PLANNED_MAIN':
      'Suunnitteilla oleva pääreitti',
    'bicycle-routes-MAIN_REGIONAL-PLANNED_REGIONAL':
      'Suunnitteilla oleva aluereitti',
    'bicycle-routes-TYPES': 'Muut väylät',
    'bicycle-routes-TYPES-2_WAY': '2-suuntainen pp+jk',
    'bicycle-routes-TYPES-SLOW_STREET': 'Hidaskatu (=pyöräkatu)',
    'bicycle-routes-TYPES-BICYCLE_LANE': 'Pyöräkaista',
    'bicycle-routes-TYPES-SHARED_SPACE': 'Yhteinen tila',
    'bicycle-routes-TYPES-4MW_SHARED': '4 m leveä yhdistetty pp+jk',
    'bicycle-routes-TYPES-35MW_SHARED': '3.5 m leveä yhdistetty pp+jk',
    'bicycle-routes-TYPES-ACCESS_ROAD': 'Tonttikatu',
    'bicycle-routes-TYPES-WIDE_SHOULDER': 'Leveä piennar',
    'bike-availability': 'Pyöriä asemalla juuri nyt',
    'bike-availability-short': 'Pyöriä',
    'bike-not-allowed-bus': 'Pyörää ei voi kuljettaa bussissa',
    'bike-not-allowed-tram': 'Pyörää ei voi kuljettaa raitiovaunussa',
    'biketransport-citybike': 'Käytän kaupunkipyörää',
    'biketransport-only-bike': 'Liikun vain pyörällä',
    'biking-amount': 'Pyöräilyn määrä',
    'biking-speed': 'Pyöräilyn nopeus',
    bus: 'Bussi',
    'bus-with-route-number': 'Bussi {routeNumber} {headSign}',
    'buy-ticket': 'Näin ostat lipun (HSL.fi)',
    call: 'Soita',
    cameras: 'Kamerat',
    cancel: 'Peruuta',
    car: 'Auto',
    'car-distance-duration': 'Aja {distance} ({duration})',
    car_park: 'liityntäpysäköinti',
    cause: 'syy',
    'char-left': 'merkkiä',
    'choose-date': 'Valitse päivämäärä',
    'choose-date-error':
      'Päättymispäivämäärän on oltava suurempi kuin aloituspäivämäärän.',
    'choose-stop': 'Tarkenna valinta',
    'weather-station': 'Sääasema',
    citybike: 'Kaupunkipyörä',
    'citybike-distance-duration': 'Pyöräile {distance} ({duration})',
    'citybike-off-season': 'Kaupunkipyörät palaavat käyttöön ensi keväänä',
    'citybike-register-required':
      'Kaupunkipyörien käyttö vaatii rekisteröitymisen',
    citybike_off: 'Suljettu',
    clear: 'Tyhjennä',
    'clear-selected-options': 'Tyhjennä valinnat',
    close: 'Sulje',
    'close-comparison': 'Sulje vertailu',
    compare: 'Vertaile',
    condition: 'Keli',
    'contact-information': '{contactName}:n yhteystiedot',
    'create-account': 'Luo {contactName} tunnus',
    'cycle-distance-duration': 'Pyöräile {distance} ({duration})',
    'cyclewalk-distance-duration': 'Taluta pyörää {distance} ({duration})',
    daily: 'Päivittäin',
    datasources: 'Tietolähteet',
    date: 'Päivämäärä',
    delete: 'Poista',
    'departure-time-in-minutes': '{minutes} min',
    departures: 'Lähdöt',
    description: 'Löydä joukkoliikennetarjonta lähelle ja kauas.',
    destination: 'Määränpää',
    'destination-copied': 'Määränpää-linkki kopioitu leikepöydälle',
    'destination-label-change': 'Vaihda määränpää',
    'destination-needed': 'Määränpää täytyy olla määritetty',
    'destination-outside-service':
      'Emme valitettavasti löytäneet yhtään reittiehdotusta kyseiselle välille. Muuta määränpään osoitetta, joka sijaitsee palvelualueen ulkopuolella.',
    'destination-placeholder': 'Kirjoita määränpää, linja tai pysäkki',
    'dew-point-temperature': 'Kastepistelämpötila',
    'dialog-return-to-map': 'Takaisin karttaan',
    'digitransit-platform': 'Digitransit palvelualusta',
    disclaimer:
      'Tulokset perustuvat arvioituihin ajoaikoihin. Ehdotetun yhteyden toteutumista ei voida taata.',
    disruption: 'Häiriö',
    'disruption-info': 'Poikkeusinfo',
    'disruption-info-no-alerts':
      'Ei tiedossa olevia häiriöitä tai poikkeuksia.',
    'disruption-info-route-no-alerts':
      'Linjalla ei tiedossa olevia häiriöitä tai poikkeuksia.',
    disruptions: 'Häiriöt',
    'distance-between': 'Etäisyys {distance1} m — {distance2} m',
    'distance-total': 'Matkan pituus',
    'distance-under': 'Etäisyys alle {distance} m',
    'driving-conditions': 'Ajokeli',
    earlier: 'Aiemmin',
    'eco-counter': 'Laskentapisteet',
    'eco-counter-direction-1': 'Keskustaan',
    'eco-counter-direction-2': 'Keskustasta',
    'eco-counter-direction-5': 'Ei määritetty',
    'eco-counter-direction-6': 'Pohjoisesta',
    'eco-counter-direction-7': 'Idästä',
    'eco-counter-direction-8': 'Etelästä',
    'eco-counter-direction-9': 'Lännestä',
    'eco-counter-total': 'Yhteensä',
    'eco-counter-week-title': 'Viikko {date}',
    'edit-favourites': 'Muokkaa paikkaa Suosikkisi-välilehdellä',
    'elevation-gained-total': 'Nousua yhteensä',
    'elevation-lost-total': 'Laskua yhteensä',
    explanations: 'Selitykset',
    'export-time-range-1': 'Lataa 1. ajanjakso',
    'export-time-range-2': 'Lataa 2. ajanjakso',
    'external-modes': 'Muut kulkumuodot',
    'extra-info': 'Lisätiedot',
    'favourite-target': 'Suosikkikohde',
    ferry: 'Lautta',
    'ferry-with-route-number': 'Lautta {routeNumber} {headSign}',
    roadinspection: 'Kelintarkistus',
    'roadinspection-vehicle-time-range':
      'Kelintarkistuksen aikaväli: 30 päivää',
    'realtime-roadinspection-vehicles': 'Kelintarkistuksen reaaliaikaseuranta',
    fluency: 'Ruuhkat',
    'footer-links': 'Alatunnisteen linkit',
    'footer-faq': 'UKK',
    'footer-feedback': 'Anna palautetta',
    'footer-link-to-privacy-policy': 'Rekisteriseloste',
    'from-city': 'Keskustasta',
    frontpage: 'Etusivu',
    'generic-error': 'Tapahtui virhe',
    'geolocate-yourself': 'Paikanna',
    'geolocation-denied-heading': 'Paikannus on estetty',
    'geolocation-denied-text':
      'Et ole antanut lupaa paikannukseen. Voit sallia paikannuksen selaimen tai puhelimen asetuksista.',
    'geolocation-failed-heading': 'Paikannus epäonnistui',
    'geolocation-failed-text':
      'Paikannus ei toimi tai selaimesi ei tue paikannusta.',
    'geolocation-prompt-text': 'Hyväksy selaimen paikannuspyyntö.',
    'geolocation-timeout-heading': 'Paikannus kestää odotettua pidempään.',
    'geolocation-timeout-text': 'Oletko hyväksynyt selaimen paikannuspyynnön?',
    'give-destination': 'Määränpää',
    'give-name-to-location': 'Anna paikalle kuvaava nimi',
    'give-origin': 'Lähtöpaikka',
    'give-position': 'Kirjoita sijaintisi tai lähtöpaikkasi hakukenttään.',
    'give-route': 'Hae linjaa',
    'hide-realtime-on-map': 'Piilota ajoneuvot kartalla',
    hour: 'Tunti',
    'hour-short': 'h',
    hourly: 'Tunneittain',
    'hourly-with-date': 'Tunneittain ({date})',
    'how-to-rate-service': 'Kuinka arvioisit palvelua?',
    'in-addition': 'Lisäksi',
    inquiry: 'Mitä pidät uudesta Reittioppaasta? Kerro se meille! ',
    instructions: 'Ohjeet',
    'itinerary-feedback-button': 'Lähetä palautetta',
    'itinerary-feedback-message': 'Etkö löytänyt mitä etsit?',
    'itinerary-feedback-placeholder': 'Kuvaus (valinnainen)',
    'itinerary-hide-stops': 'Piilota pysäkit',
    'itinerary-page.description': 'Reittiohje',
    'itinerary-page.title': 'Reittiohje',
    'itinerary-ticket.title': 'Tarvittava lippu',
    'itinerary-tickets.title': 'Tarvittavat liput',
    'itinerary-time.title': 'Matka-aika',
    'itinerary-walk.title': 'Kävelyä',
    'journeyplanner.title': 'HSL Reittiopas',
    'last-updated': 'Viimeksi päivitetty',
    later: 'Myöhemmin',
    leaves: 'Lähtee',
    'leaving-at': 'Lähtöaika',
    'likely-to-recommend':
      'Kuinka todennäköistä on, että suosittelisit palveluamme ystävälle tai kollegallesi?',
    'likely-to-use':
      'Kuinka todennäköisesti käyttäisit tätä palvelua ennemmin kuin nykyistä reittiopas-palvelua?',
    links: 'Linkit',
    loading: 'Ladataan...',
    locate: 'Paikanna',
    'location-examples': 'esim. Koti, Työ, Koulu,...',
    'location-from-map': 'Valittu sijainti',
    'location-home': 'Koti',
    'location-school': 'Koulu',
    'location-shopping': 'Kauppa',
    'location-sport': 'Urheilu',
    'location-work': 'Työ',
    login: 'Kirjaudu sisään',
    'long-transfers-margin': '12 min',
    'main-bicycle-route-contract': 'Vain pääpyörätiet',
    'main-menu-label-close': 'Sulje päävalikko',
    'main-menu-label-open': 'Avaa päävalikko',
    'main-mode': 'Kulkumuoto',
    maintenance: 'Kunnossapito',
    'maintenance-job': 'Kunnossapitotyö',
    'maintenance-job-0': 'Muut seurattavat väylät',
    'maintenance-job-1357': 'Harjaus',
    'maintenance-job-1364': 'Tasaus',
    'maintenance-job-1366': 'Tasaus',
    'maintenance-job-1367': 'Liukkauden torjunta',
    'maintenance-job-1368': 'Liukkauden torjunta',
    'maintenance-job-1369': 'Liukkauden torjunta',
    'maintenance-job-1370': 'Auraus',
    'maintenance-job-2864': 'Tasaus',
    'maintenance-job-99901': 'Lumen Harjaus',
    'maintenance-job-99902': 'Kelintarkistus',
    'maintenance-job-legend-0': 'Muut seurattavat väylät',
    'maintenance-job-legend-1357': 'Harjaus',
    'maintenance-job-legend-1364': 'Tasaus',
    'maintenance-job-legend-1366': 'Tasaus',
    'maintenance-job-legend-1367': 'Liukkauden torjunta',
    'maintenance-job-legend-1368': 'Liukkauden torjunta',
    'maintenance-job-legend-1369': 'Liukkauden torjunta',
    'maintenance-job-legend-1370': 'Auraus',
    'maintenance-job-legend-2864': 'Tasaus',
    'maintenance-job-legend-99901': 'Harjaus',
    'maintenance-job-legend-99902-car': 'Kelintarkistus (auto)',
    'maintenance-job-legend-99902-bicycle': 'Kelintarkistus (polkupyörä)',
    'maintenance-job-realization': 'Toteuma',
    'maintenance-vehicle-name': 'Ajoneuvo #{vehicleNumber}',
    'maintenance-vehicle-time-range': 'Kunnossapidon aikaväli',
    'maintenance-vehicle-time-range-12h': '12h',
    'maintenance-vehicle-time-range-1d': '1d',
    'maintenance-vehicle-time-range-1h': '1h',
    'maintenance-vehicle-time-range-3d': '3d',
    'maintenance-vehicle-time-range-30d': '30d harjaus',
    'maintenance-vehicle-time-range-3h': '3h',
    'maintenance-vehicle-time-range-6h': '6h',
    'maintenance-inspection-vehicle-type-car': '(auto) ',
    'maintenance-inspection-vehicle-type-bicycle': '(pyörä) ',
    'maintenance-inspection-vehicle-type-unknown': '(tuntematon) ',
    map: 'Kartta',
    'map-layer-citybike': 'Kaupunkipyöräasemat',
    'map-layer-park-and-ride': 'Liityntäpysäköintipaikat',
    'map-layer-stop-bus': 'Bussipysäkit',
    'map-layer-stop-ferry': 'Lautat',
    'map-layer-stop-tram': 'Raitiovaunupysäkit',
    'map-layer-terminal-bus': 'Bussiterminaalit',
    'map-layer-terminal-rail': 'Juna-asemat',
    'map-layer-terminal-subway': 'Metroasemat',
    'map-layer-ticket-sales-machine': 'Lippuautomaatit',
    'map-layer-ticket-sales-point': 'Matkakortin latauspisteet',
    'messagebar-label-close-message-bar': 'Sulje banneri',
    'messagebar-label-page': 'Sivu',
    'minute-short': 'min',
    minutes: 'min',
    'minutes-or-route': 'Min / Linja',
    monthly: 'Kuukausittain',
    more: 'Lisää',
    'more-settings': 'Lisäasetukset',
    motorist: 'Autoilija',
    navigate: 'Navigoi',
    'near-you': 'Lähelläsi',
    nearest: 'Lähimmät',
    'network-error': 'Verkkovirhe',
    next: 'Seuraava',
    'no-bike-allowed-popup':
      'Pyörää ei voi ottaa bussiin tai raitiovaunuun. Jos käytät reitilläsi näitä kulkuvälineitä, jätä pyörä pysäkille tai liityntäpysäköintiin.',
    'no-favourite-locations':
      'Lisää tähän usein käyttämäsi paikat. Painikkeet toimivat suorina linkkeinä reititykseen.',
    'no-favourites':
      'Tähti-painikkeella saat lisättyä linjan tai pysäkin suosikiksesi. Suosikkiesi aikataulut näkyvät etäisyyden mukaan tällä sivulla.',
    'no-position': 'Ei sijaintia',
    'no-position-no-stops':
      'Sijaintisi ei ole tiedossa, joten emme voi näyttää lähimpiä pysäkkejä.',
    'no-route-end-date-not-in-range':
      'Käytössä oleva aikataulu ei sisällä myöhäisempiä reittejä.',
    'no-route-msg':
      'Emme valitettavasti löytäneet yhtään reittiehdotusta kyseiselle välille. Muuta lähdön tai määränpään osoitetta.',
    'no-route-start-date-too-early':
      'Käytössä oleva aikataulu ei sisällä aiempia reittejä.',
    'no-route-start-end': 'Valitse lähtöpaikka ja määränpää.',
    'no-transfers-margin': '1 min',
    'no-trips-found': 'Valitulle päivälle ei löydy matkoja.',
    now: 'Nyt',
    'number-of-intermediate-stops':
      '{number, plural, =0 {Ei pysähdyksiä} one {1 pysäkki} other {{number} pysäkkiä} }',
    'number-of-minutes':
      '{number, plural, =0 {0 minuuttia} one {1 minuutti} other {{number} minuuttia}}',
    'off-canvas-info':
      'Kirjautumalla palveluun saat suosikit talteen ja voit hyödyntää niitä muillakin laitteillasi',
    'option-default': 'Oletusarvo',
    'option-least': 'Vähiten',
    'option-less': 'Vähemmän',
    'option-more': 'Enemmän',
    'option-most': 'Eniten',
    or: 'tai',
    origin: 'Lähtöpaikka',
    'origin-label-change': 'Vaihda lähtöpaikka',
    'origin-outside-service':
      'Emme valitettavasti löytäneet yhtään reittiehdotusta kyseiselle välille. Muuta lähtöosoitetta, joka sijaitsee palvelualueen ulkopuolella.',
    'origin-placeholder': 'Mistä? - osoite tai pysäkki',
    'own-position': 'Nykyinen sijaintisi',
    'page-not-found': 'Etsimääsi sivua ei löytynyt.',
    'park-and-ride': 'Liityntäpysäköinti',
    'park-and-ride-availability': 'Pysäköintipaikkoja vapaana',
    parking: 'Pysäköinti',
    'parking-availability': 'Pysäköintipaikkoja vapaana',
    'pay-attention': 'Huom!',
    'pick-icon': 'Valitse kuvake',
    'pick-mode': 'Valitse joukkoliikennevälineistä',
    'place-route-or-keyword': 'Määränpää, linja tai pysäkki',
    'platform-num': 'Laituri {platformCode}',
    'platform-short': 'Lait. {platformCode}',
    'prefer-walking': 'Suosi kävelyä',
    preferred: 'Suosi linjoja',
    previous: 'Edelliset',
    print: 'Tulosta',
    'print-timetable': 'Viikkoaikataulu',
    'public-transport': 'Joukkoliikenne',
    rail: 'Juna',
    'rail-with-route-number': 'Juna {routeNumber} {headSign}',
    rain: 'Sade',
    'rain-classification': 'Sateen luokittelu',
    'rainfall-depth': 'Sademäärä',
    'rainfall-intensity': 'Sateen intensiteetti',
    'no-rain': 'Poutaa',
    'has-rain': 'Vesisade',
    'light-snow': 'Heikko lumisade',
    'moderate-snow': 'Kohtalainen lumisade',
    'heavy-snow': 'Runsas lumisade',
    realtime: 'Reaaliaika',
    'realtime-maintenance-vehicles': 'Kunnossapidon reaaliaikaseuranta',
    'realtime-matching': 'Realiajan integraatio',
    'rent-cycle-at': 'Vuokraa pyörä asemalta {station}',
    'required-ticket': 'Reitillä tarvittava lippu',
    'return-cycle-to': 'Palauta pyörä asemalle {station}',
    'reverse-route': 'Käännä reitin suunta',
    'right-now': 'Juuri nyt',
    'road-condition': 'Ajokeli',
    'road-condition-air': 'Ilma',
    'road-condition-condition': 'Ajokeli',
    'road-condition-now': 'Nyt',
    'road-condition-road': 'Tie',
    'road-condition-time': 'Aika',
    'road-condition-weather': 'Sää',
    'road-condition-wind': 'Tuuli',
    'road-info': 'Tietiedot',
    'road-sign': 'Ohjauslaite',
    'road-signs': 'Ohjauslaitteet',
    'road-sign-type': 'Tyyppi',
    'road-sign-display-value': 'Näyttöarvo',
    'road-sign-effective-from': 'Voimassa alkaen',
    'road-sign-SPEEDLIMIT': 'Nopeusrajoitus',
    'road-sign-WARNING': 'Varoitus',
    'road-sign-INFORMATION': 'Tiedotus',
    'road-sign-warning-144': 'Liukas ajorata',
    'road-sign-warning-165': 'Liikennevalot',
    'road-sign-warning-189': 'Muu vaara',
    'road-sign-warning-133': 'Liikenneruuhka',
    'road-sign-warning-142': 'Tietyö',
    'road-sign-warning-183': 'Sivutuuli',
    'road-sign-warning-141': 'Epätasainen tie',
    'road-sign-warning-143': 'Irtokiviä',
    'road-sign-warning-147': 'Vaarallinen tien reuna',
    'road-sign-warning-154': 'Hiihtolatu',
    'road-sign-warning-155': 'Hirvieläimiä',
    'road-sign-warning-156': 'Poroja',
    'road-temperature': 'Tien lämpötila',
    roadwork: 'Tietyö',
    roadworks: 'Tietyöt',
    route: 'Linja',
    'route-custom-settings': 'Omat asetukset',
    'route-default': 'Perusasetukset',
    'route-destination-arrives': 'Saapuu / Päätepysäkki',
    'route-fastest': 'Nopea reitti',
    'route-from-here': 'Reititä täältä',
    'route-guide': 'Linjaohje',
    'route-here': 'Reititä tänne',
    'route-here-button': 'Kopioi reitti tänne',
    'route-least-elevation-changes': 'Vältä korkeuseroja',
    'route-least-transfers': 'Vältä vaihtoja',
    'route-least-walking': 'Vältä kävelyä',
    'route-not-possible':
      'Valitettavasti reittisi ei ole mahdollinen. Tekninen virhe: "{error}"',
    'route-page.description': 'Linja {shortName}, {longName}',
    'route-page.title': 'Linja {shortName}',
    'route-page.title-short': 'Linja',
    'route-prefer-greenways': 'Suosi kevyen liikenteen reittejä',
    'route-prefer-illuminated': 'Suosi valaistuja reittejä',
    'route-prefer-paved': 'Suosi päällystettyjä reittejä',
    'route-prefer-walking-routes': 'Suosi kävelyreittejä',
    'route-prefer-winter-maintenance': 'Suosi talvikunnossapidettyjä reittejä',
    'route-preferences': 'Reittimieltymykset',
    'route-public-transport-with-bicycle': 'Pyöräillen ja julkisilla',
    'route-saved-settings': 'Tallennetut asetukset',
    'route-stop-or-keyword': 'Tietoja linjasta tai pysäkistä',
    routes: 'Linjat',
    run: 'Juoksu',
    save: 'Tallenna',
    search: 'Etsi',
    'search-destination': 'Hae määränpäätä, linjaa tai pysäkkiä',
    'search-destination-results-but-no-search':
      'Katso tulokset viereiseltä Määränpää-välilehdeltä',
    'search-no-results': 'Ei tuloksia',
    'search-origin': 'Hae lähtöpaikkaa, linjaa tai pysäkkiä',
    'search-position': 'Paikanna',
    'search-search-results-but-no-destination':
      'Katso tulokset viereiseltä "Tietoja linjasta tai pysäkistä" –välilehdeltä',
    'searching-position': 'Paikannetaan...',
    'select-map-layers-header': 'Mitä haluat näyttää kartalla?',
    'select-position': 'Valitse sijaintisi edellisistä hauistasi',
    send: 'Lähetä',
    settings: 'Asetukset',
    'settings-label-change': 'Muuta reittihaun asetuksia',
    'settings-loadbutton': 'Lataa asetukset',
    'settings-loaded': 'Asetukset ladattu!',
    'settings-reset': 'Palauta oletusasetukset',
    'settings-savebutton': 'Tallenna asetukset',
    'settings-saved': 'Asetukset tallennettu!',
    share: 'Jaa ohje',
    'show-departures': 'Näytä lähdöt',
    'show-information-as-list': 'Näytä tiedot listana',
    'show-more': 'Näytä lisää',
    'show-realtime-on-map': 'Näytä ajoneuvot kartalla',
    'show-routes': 'Näytä linjat',
    'skip-positioning': 'Ohita',
    slow: 'Hidas',
    'snow-depth': 'Lumitilanne',
    'specify-location': 'Määritä sijainti',
    'speed-limit': 'Nopeusrajoitus',
    'splash-choose': 'Valitse lähtöpaikka',
    'splash-locating': 'Paikannetaan',
    'splash-or-choose': 'tai valitse lähtöpaikaksi',
    'splash-use-positioning': 'Käytä paikannusta',
    'splash-welcome': 'Miten haluat aloittaa?',
    'splash-you-can-also': 'tai',
    stop: 'Pysäkki',
    'stop-number': 'Pysäkkinumero',
    'stop-page.description': 'Pysäkki - {name} {code}, {desc}',
    'stop-page.title': 'Pysäkki - {name} {code}',
    'stop-page.title-short': 'Pysäkki',
    stops: 'Pysäkit',
    'street-mode-bicycle': 'Pyöräily',
    'street-mode-car': 'Auto',
    'street-mode-car_park': 'Liityntä\u00adpysäköinti',
    'street-mode-public_transport': 'Julkinen liikenne',
    'street-mode-walk': 'Kävely',
    subway: 'Metro',
    'subway-with-route-number': 'Metro {routeNumber} {headSign}',
    'summary-page.description': '{from} - {to}',
    'summary-page.title': 'Reittiehdotukset',
    'terminal-page.description': 'Terminaali - {name}',
    'terminal-page.title': 'Terminaali - {name}',
    'terminal-page.title-short': 'Terminaali',
    'terms-of-use': 'Käyttöehdot',
    'ticket-and-price-info': 'Lippu- ja hintatiedot',
    'ticket-single-adult': 'Aikuinen',
    'ticket-type-none': 'Ei lippuvyöhykerajoitusta',
    tickets: 'Matkaliput',
    time: 'Aika',
    timetable: 'Aikataulu',
    'to-city': 'Keskustaan',
    'to-stop': 'Pysäkille',
    today: 'Tänään',
    tomorrow: 'Huomenna',
    'traffic-announcement-additional-info': 'Lisämäre',
    'traffic-announcement-additional-info-at': 'Avotulella kuumennus',
    'traffic-announcement-additional-info-fd': 'Seuraa kiertotieopastusta',
    'traffic-announcement-additional-info-pd': 'Poliisi ohjaa liikennettä',
    'traffic-announcement-class-acc': 'Onnettomuus',
    'traffic-announcement-class-act': 'Yleisö- tai muut tapahtumat',
    'traffic-announcement-class-equ': 'Tienvarsilaitteiden toiminta',
    'traffic-announcement-class-fos': 'Näkyvyys- ja ympäristöolosuhteet',
    'traffic-announcement-class-krk': 'Kelirikko',
    'traffic-announcement-class-los': 'Liikennetilanne',
    'traffic-announcement-class-mhz':
      'Vaaraa aiheuttava ajoneuvo tai kuljetus tiellä',
    'traffic-announcement-class-ohz': 'Este esine tai eläin tiellä',
    'traffic-announcement-class-res': 'Liikennerajoitukset',
    'traffic-announcement-class-rmt': 'Tietyö',
    'traffic-announcement-class-shz': 'Ajo-olot liukkaus kelitilanne',
    'traffic-announcement-duration': 'Arvioitu kesto',
    'traffic-announcement-duration-gte_180_lte_360': '3 - 6 tuntia',
    'traffic-announcement-duration-gte_30_lte_60': '30 - 60 minuuttia',
    'traffic-announcement-duration-gte_360': 'Yli 6 tuntia',
    'traffic-announcement-duration-gte_60_lte_180': '1 - 3 tuntia',
    'traffic-announcement-duration-lte_30': 'Alle 30 minuuttia',
    'traffic-announcement-modes-of-transport': 'Liikennemuoto',
    'traffic-announcement-modes-of-transport-bicycle': 'Polkupyörä',
    'traffic-announcement-modes-of-transport-car': 'Auto',
    'traffic-announcement-modes-of-transport-pedestrian': 'Jalankulkija',
    'traffic-announcement-modes-of-transport-public_transport':
      'Julkinen liikenne',
    'traffic-announcement-oversize-load': 'Erikoiskuljetus',
    'traffic-announcement-oversize-load-definition':
      '(Lev. x Kork. x Pit. metreissä)',
    'traffic-announcement-severity': 'Haitta-aste',
    'traffic-announcement-severity-high': 'Merkittävää haittaa liikenteelle',
    'traffic-announcement-severity-low': 'Ei haittaa liikenteelle',
    'traffic-announcement-severity-medium': 'Haittaa liikenteelle',
    'traffic-announcement-subclass-abl': 'Erikoiskuljetus',
    'traffic-announcement-subclass-acb': 'Onnettomuus, jossa mukana linja-auto',
    'traffic-announcement-subclass-ach':
      'Onnettomuus, jossa mukana raskas ajoneuvo',
    'traffic-announcement-subclass-aci': 'Onnettomuus',
    'traffic-announcement-subclass-acl': 'Onnettomuuspaikan raivaus käynnissä',
    'traffic-announcement-subclass-acm': 'Usean ajoneuvon onnettomuus',
    'traffic-announcement-subclass-acz':
      'Onnettomuus, jossa mukana vaarallisia aineita',
    'traffic-announcement-subclass-ali': 'Alikulkukäytävän rakentaminen',
    'traffic-announcement-subclass-anh': 'Poroja tiel',
    'traffic-announcement-subclass-anl': 'Hirviä tiellä',
    'traffic-announcement-subclass-anm': 'Eläimiä tiellä',
    'traffic-announcement-subclass-aol': 'Raskas ajoneuvo kaatunut tielle',
    'traffic-announcement-subclass-aqd': 'Vesiliirron vaara',
    'traffic-announcement-subclass-asp': 'Ulosajo',
    'traffic-announcement-subclass-bkd': 'Rikkoutunut ajoneuvo tiellä',
    'traffic-announcement-subclass-bli': 'Lumimyrsky',
    'traffic-announcement-subclass-bls': 'Pöllyävää lunta',
    'traffic-announcement-subclass-brc': 'Silta suljettu liikenteeltä',
    'traffic-announcement-subclass-ctr':
      'Liikenne ohjataan kaksisuuntaisena toiselle ajoradalle',
    'traffic-announcement-subclass-dcd': 'Huono ajokeli',
    'traffic-announcement-subclass-dcn': 'Ajo-olot parantuneet',
    'traffic-announcement-subclass-do': 'Kiertotie käytössä',
    'traffic-announcement-subclass-efa': 'Messut',
    'traffic-announcement-subclass-ens': 'ENSITIEDOTE LIIKENNEONNETTOMUUDESTA',
    'traffic-announcement-subclass-epr': 'Kulkue',
    'traffic-announcement-subclass-esp': 'Urheilutapahtuma',
    'traffic-announcement-subclass-evd': 'Mielenosoitus',
    'traffic-announcement-subclass-evm': 'Yleisötapahtuma',
    'traffic-announcement-subclass-fig': 'Maastopalo',
    'traffic-announcement-subclass-fir': 'Tulipalo',
    'traffic-announcement-subclass-fld': 'Tulva',
    'traffic-announcement-subclass-flt': 'Puu kaatunut tielle',
    'traffic-announcement-subclass-fod': 'Sakeaa sumua',
    'traffic-announcement-subclass-fof': 'Jäätävää sumua',
    'traffic-announcement-subclass-fog': 'Sumua',
    'traffic-announcement-subclass-fop': 'Paikoitellen sumua',
    'traffic-announcement-subclass-fpc': 'Sähköjohtoja pudonnut tielle',
    'traffic-announcement-subclass-fue': 'Öljyä tiellä',
    'traffic-announcement-subclass-gal': 'Kova tuuli',
    'traffic-announcement-subclass-hai': 'Raekuuro',
    'traffic-announcement-subclass-hav': 'Hirviaita vaurioitunut',
    'traffic-announcement-subclass-hbd': 'Rikkoutunut raskas ajoneuvo tiellä',
    'traffic-announcement-subclass-ibu': 'Tiet jäätymässä',
    'traffic-announcement-subclass-icp': 'Paikoin liukasta',
    'traffic-announcement-subclass-jjm':
      'Kevyen liikenteen väylät jäisiä ja märkiä',
    'traffic-announcement-subclass-jjt': 'Kevyen liikenteen väylät jäätymäs',
    'traffic-announcement-subclass-jli': 'Kevyen liikenteen väylät liukkaita',
    'traffic-announcement-subclass-jlu': 'Kevyen liikenteen väylillä lunta',
    'traffic-announcement-subclass-jpl':
      'Kevyen liikenteen väylät paikoin liukkaita',
    'traffic-announcement-subclass-jyr': 'Päällysteen jyrsintätyö',
    'traffic-announcement-subclass-kai': 'Sadevesikaivojen puhdistustyö',
    'traffic-announcement-subclass-kav': 'Kaidevaurio',
    'traffic-announcement-subclass-kdt': 'Kaidetyö',
    'traffic-announcement-subclass-kkp': 'Kaivon kansi rikki/poissa paikaltaan',
    'traffic-announcement-subclass-klv': 'Kevyenliikenteen väylän rakentaminen',
    'traffic-announcement-subclass-kri': 'Kelirikko',
    'traffic-announcement-subclass-kuo': 'Kuoppia tiessä',
    'traffic-announcement-subclass-lc1': 'Yksi ajokaista suljettu liikenteeltä',
    'traffic-announcement-subclass-lc2':
      'Kaksi ajokaistaa suljettu liikenteeltä',
    'traffic-announcement-subclass-lcs': 'Ajokaistoja on suljettu liikenteeltä',
    'traffic-announcement-subclass-lij': 'Liittymän parannustyö',
    'traffic-announcement-subclass-lin': 'Ajokaista ajoittain suljettu',
    'traffic-announcement-subclass-lo1': 'Vain yksi ajokaista käytössä',
    'traffic-announcement-subclass-lo2': 'Vain kaksi kaistaa käytössä',
    'traffic-announcement-subclass-loh': 'Tienvarsilaitteiden huoltotyö',
    'traffic-announcement-subclass-lpa': 'Liikenne pysäytetään ajoittain',
    'traffic-announcement-subclass-ls1': 'Liikenne seisoo',
    'traffic-announcement-subclass-ls2': 'Liikenne pysähtelee',
    'traffic-announcement-subclass-ls3': 'Liikenne hidasta',
    'traffic-announcement-subclass-ls4': 'Liikenne jonoutunut',
    'traffic-announcement-subclass-ls6': 'Pitkiä jonoja',
    'traffic-announcement-subclass-lsl': 'Tie sortunut',
    'traffic-announcement-subclass-lso': 'Liikenne saattaa ruuhkautua',
    'traffic-announcement-subclass-lsr': 'Liikenne saattaa ruuhkautua',
    'traffic-announcement-subclass-lvt': 'Liikennevalotyö',
    'traffic-announcement-subclass-mar': 'Maratonjuoksu',
    'traffic-announcement-subclass-mil': 'Sotilassaattue',
    'traffic-announcement-subclass-nit': 'Niittotyö',
    'traffic-announcement-subclass-nls': 'Ajokaistoja kavennettu',
    'traffic-announcement-subclass-ohx': 'Liikennettä vaarantava este tiellä',
    'traffic-announcement-subclass-oil': 'Polttoainetta tiellä',
    'traffic-announcement-subclass-pav': 'Päällystevaurio',
    'traffic-announcement-subclass-pel': 'Pelastusharjoitus',
    'traffic-announcement-subclass-pki': 'POLIISIN KELI-ILMOITUS',
    'traffic-announcement-subclass-pys': 'Pysäkkiin liittyvä ilmoitus',
    'traffic-announcement-subclass-rad': 'Kiertotieopastus',
    'traffic-announcement-subclass-raf': 'Jäätävää sadetta',
    'traffic-announcement-subclass-rai': 'Vesisade',
    'traffic-announcement-subclass-raj': 'Raskas ajoneuvo jumissa',
    'traffic-announcement-subclass-rba': 'Liikenne pysäytetään',
    'traffic-announcement-subclass-rbi': 'Mustaa jäätä',
    'traffic-announcement-subclass-rbl': 'Räjäytystyö',
    'traffic-announcement-subclass-rbm': 'Siltatyö',
    'traffic-announcement-subclass-rca': 'Toinen ajorata suljettu liikenteeltä',
    'traffic-announcement-subclass-rcd': 'Tie suljettu liikenteeltä',
    'traffic-announcement-subclass-rcw': 'Tienrakennustyö',
    'traffic-announcement-subclass-reb': 'Liittymisramppi tukossa',
    'traffic-announcement-subclass-rec': 'Ramppi suljettu liikenteeltä',
    'traffic-announcement-subclass-ret': 'Poikkeavat kaistajärjestelyt',
    'traffic-announcement-subclass-rew': 'Raivaus- ja korjaustöitä',
    'traffic-announcement-subclass-ric': 'Tiet ovat jäisiä',
    'traffic-announcement-subclass-ril': 'Risteykset liukkaita',
    'traffic-announcement-subclass-rin': 'Tie ajoittain suljettu liikenteeltä',
    'traffic-announcement-subclass-rmk': 'Kunnossapito- ja hoitotyöt',
    'traffic-announcement-subclass-rmv': 'Työmaa-ajoa liikenteen seassa',
    'traffic-announcement-subclass-rmw': 'Tiemerkintätyö',
    'traffic-announcement-subclass-rnl': 'Muuttuneet liikennejärjestelyt',
    'traffic-announcement-subclass-rno': 'Rekan nosto',
    'traffic-announcement-subclass-rpc': 'Tienpinta huonossa kunnossa',
    'traffic-announcement-subclass-rrw': 'Tietyö keskikaistalla',
    'traffic-announcement-subclass-rsl': 'Tiet ovat liukkaita',
    'traffic-announcement-subclass-rwi': 'Tiet jäisiä ja märkiä',
    'traffic-announcement-subclass-rwk': 'Tietyö',
    'traffic-announcement-subclass-rwl': 'Pitkäaikainen tienparannustyö',
    'traffic-announcement-subclass-rwm': 'Tienparannustyö',
    'traffic-announcement-subclass-rwr': 'Päällystystyö',
    'traffic-announcement-subclass-rxb': 'Poistumisramppi tukossa',
    'traffic-announcement-subclass-sat':
      'Kohtaava liikenne, ajo vuorotellen ohjauksen mukaan',
    'traffic-announcement-subclass-sav': 'Savea tiellä',
    'traffic-announcement-subclass-sfh': 'Sakea lumisade',
    'traffic-announcement-subclass-sfl': 'Lumisade',
    'traffic-announcement-subclass-shl': 'Kuorma levinnyt tielle',
    'traffic-announcement-subclass-sjt': 'Sähköjohtotyö',
    'traffic-announcement-subclass-slt': 'Räntäsade',
    'traffic-announcement-subclass-slu': 'Lumisohjoa',
    'traffic-announcement-subclass-smo': 'Savu haittaa näkyvyyttä',
    'traffic-announcement-subclass-smv':
      'Nestettä vuotanut liikkuvasta ajoneuvosta',
    'traffic-announcement-subclass-snp': 'Lumi- tai jääpolanne',
    'traffic-announcement-subclass-snr': 'Lumikinoksia',
    'traffic-announcement-subclass-snw': 'Kaistaopasteet eivät toimi',
    'traffic-announcement-subclass-sok': 'Sivuojan kaivuutyö',
    'traffic-announcement-subclass-son': 'SUURONNETTOMUUS',
    'traffic-announcement-subclass-spk': 'Sirotepaikkaus, irtokiviä',
    'traffic-announcement-subclass-spy': 'Roiskevesi haittaa näkyvyyttä',
    'traffic-announcement-subclass-sro': 'Lunta tiellä',
    'traffic-announcement-subclass-std': 'Myrskyvaurioita',
    'traffic-announcement-subclass-stk': 'Soratien kunto huono',
    'traffic-announcement-subclass-stm': 'Myrsky',
    'traffic-announcement-subclass-taa': 'Toinen ajokaista avattu liikenteelle',
    'traffic-announcement-subclass-tal': 'Akselipainorajoitus',
    'traffic-announcement-subclass-tav': 'Tie on avattu liikenteelle',
    'traffic-announcement-subclass-tbu': 'Liikenne ruuhkautuu',
    'traffic-announcement-subclass-tcn': 'Liikenneruuhka',
    'traffic-announcement-subclass-tcx': 'Liikenneruuhka ohi',
    'traffic-announcement-subclass-tea': 'Liikenne on palautumassa normaaliksi',
    'traffic-announcement-subclass-tgw': 'Painorajoitus asetettu',
    'traffic-announcement-subclass-tlo': 'Liikennevalot eivät toimi',
    'traffic-announcement-subclass-tlt': 'Tilapäinen liikennevalo-ohjaus',
    'traffic-announcement-subclass-tmt': 'Tutkimus- ja mittaustyö',
    'traffic-announcement-subclass-tnr': 'Tilapäinen nopeusrajoitus',
    'traffic-announcement-subclass-tol': 'Tie on liukas',
    'traffic-announcement-subclass-tri': 'Tasoristeystyö',
    'traffic-announcement-subclass-tuc': 'Tunneli suljettu liikenteeltä',
    'traffic-announcement-subclass-tvl': 'Tievalaistuksen lamppuja pimeänä',
    'traffic-announcement-subclass-tvm': 'Tievalaistus vioittunut myrskyssä',
    'traffic-announcement-subclass-tvo':
      'Tievalaistus vioittunut onnettomuudessa',
    'traffic-announcement-subclass-tvt': 'Tievalaistustyö',
    'traffic-announcement-subclass-vet': 'Vettä tiellä',
    'traffic-announcement-subclass-vfr': 'Palava ajoneuvo',
    'traffic-announcement-subclass-vir': 'Näkyvyys heikentynyt',
    'traffic-announcement-subclass-vka': 'Järjestelmän vikatila',
    'traffic-announcement-subclass-vmt': 'Viimeistelytyö',
    'traffic-announcement-subclass-vnw': 'Muuttuvat opasteet eivät toimi',
    'traffic-announcement-subclass-vrt': 'Vesakon raivaus',
    'traffic-announcement-subclass-vwc': 'Ajoneuvo väärällä ajoradalla',
    'traffic-announcement-subclass-wbc': 'Kaapelityö',
    'traffic-announcement-subclass-wbs': 'Kunnallistekninen työ',
    'traffic-announcement-subclass-wic': 'Kova sivutuuli',
    'traffic-announcement-subclass-wig': 'Puuskittainen tuuli',
    'traffic-announcement-subclass-yak':
      'Työkohteessa vain yksi ajokaista käytössä',
    'traffic-announcement-temporary-speed-limit': 'Väliaikainen nopeusrajoitus',
    'traffic-announcement-traffic-direction': 'Vaikutussuunta',
    'traffic-announcement-traffic-direction-both': 'Molempiin suuntiin',
    'traffic-announcement-traffic-direction-negative': 'Vastakkaiseen suuntaan',
    'traffic-announcement-traffic-direction-positive': 'Valittuun suuntaan',
    'traffic-announcement-vehicle-size-limit': 'Ajoneuvorajoitus',
    'traffic-announcement-vehicle-size-limit-definition':
      '(Lev. x Kork. x Pit. x Paino metreissä ja tonneissa)',
    'traffic-camera': 'Liikennekamera',
    'traffic-camera-no-recent-images': 'Ei tuoreita kuvia',
    'traffic-count': 'Liikennemäärä',
    'traffic-direction': 'Suunta',
    'traffic-flow': 'Liikennevirta',
    'traffic-flow-heavy': 'Ruuhka',
    'traffic-flow-medium': 'Hidas',
    'traffic-flow-normal': 'Normaali',
    'traffic-flow-unknown': 'Ei voitu määrittää',
    'traffic-info': 'Liikennetiedot',
    'traffic-monitoring': 'Liikenteen seuranta',
    'traffic-restriction': 'Liikennerajoitus',
    tram: 'Raitiovaunu',
    'tram-with-route-number': 'Raitiovaunu {routeNumber} {headSign}',
    transfers: 'Vaihtojen määrä',
    'transfers-allowed': 'Saa olla vaihtoja',
    'transfers-margin': 'Vaihtoaika vähintään',
    'trip-co2-emissions': 'Reitin CO2 päästöt',
    'trip-information': 'Lähdön tiedot',
    'trip-page.description': 'Linja {shortName}, {longName}',
    'trip-page.title': 'Linja {shortName}',
    'try-again': 'Yritä uudelleen',
    unpreferred: 'Vältä linjoja',
    'use-citybike': 'Ota käyttöön',
    'use-national-service':
      'Voit myös hakea samaa reittiä oheisen linkin koko maan kattavasta palvelusta:',
    'use-own-position': 'Käytä nykyistä sijaintia',
    'using-modes': 'Haluan kulkea',
    'very-likely': 'Erittäin todennäköistä',
    'very-unlikely': 'Erittäin epätodennäköistä',
    'via-leg-stop-duration': 'Välipisteessä {stayDuration}',
    'via-point': 'Välipiste',
    'viapoint-remove': 'Poista pysähdys',
    'viapoint-slack-amount': 'Pysähdyksen kesto',
    'view-route': 'Linjan tiedot',
    wait: 'Odota',
    'wait-amount-of-time': 'Odota {duration}',
    walk: 'kävelyn',
    'walk-distance-duration': 'Kävele {distance} ({duration})',
    walking: 'Kävelyn määrä',
    'walking-speed': 'Kävelynopeus',
    'warning-call-agency':
      'Liikennöidään kutsujoukkoliikenteenä: {routeName},\n      joka on tilattava etukäteen',
    'warning-call-agency-no-route':
      'Liikennöidään kutsujoukkoliikenteenä, joka on tilattava etukäteen',
    'weather-at-destination': 'Sää määränpäässä',
    'weather-stations': 'Sääasemat',
    weekly: 'Viikoittain',
    'wind-direction': 'Tuulen suunta',
    'wind-speed': 'Tuulen nopeus',
    north: 'Pohjoinen',
    northeast: 'Koillinen',
    east: 'Itä',
    southeast: 'Kaakko',
    south: 'Etelä',
    southwest: 'Lounas',
    west: 'Länsi',
    northwest: 'Luode',
    yesterday: 'Eilen',
    'your-favourites': 'Suosikkisi',
    zones: 'Lippuvyöhykkeet',
  },
  fr: {
    'about-this-service': 'À propos de ce service',
    accessibility: 'Accessibilité',
    'accessibility-limited': 'Fauteuil roulant',
    'accessibility-nolimit': 'Aucune limite',
    'add-itinerary-via-point': 'Ajouter une étape au trajet',
    'add-location': 'Ajouter un endroit',
    'add-location-to-favourites':
      "Ajouter vos lieux importants à l'onglet favoris",
    address: 'Adresse',
    'address-or-stop': 'Adresse ou arrêt',
    agency: 'Transporteur',
    airplane: 'Avion',
    'airplane-with-route-number': 'Avion {routeNumber}',
    'airport-check-in': "S'enregistrer au guichet {agency}",
    'airport-collect-luggage': 'Récupérer vos bagages',
    'airport-security-check-go-to-gate':
      "Franchir les contrôles de sécurité et se rendre à la porte d'embarquement",
    'all-routes': 'Toutes lignes',
    'all-routes-disabled': 'Aucune ligne',
    'approx-meters': '{approxDistance} m',
    'arriving-at': 'Arrivée',
    'arriving-soon': 'Imm.',
    'avoid-transfers': 'Éviter les correspondances',
    'avoid-walking': 'Éviter la marche',
    'back-button-title': 'Revenir à la page précédente',
    'back-to-front-page': "Revenir à la page d'accueil",
    bicycle: 'Vélo',
    'bicycle-distance-duration': 'Vélo {distance} ({duration})',
    'bike-availability': 'Vélos disponibles en station',
    'bike-availability-short': 'Vélos',
    'bike-not-allowed-bus': 'Les vélos ne sont pas autorisés dans le bus',
    'bike-not-allowed-tram': 'Les vélos ne sont pas autorisés dans le tram',
    'biketransport-citybike': "J'utilise un vélo en libre service",
    'biketransport-only-bike': "J'utilise uniquement le vélo",
    'biking-amount': 'Quantité de vélo',
    'biking-speed': 'Vitesse en vélo',
    bus: 'Bus',
    'bus-with-route-number': 'Bus {routeNumber} {headSign}',
    'buy-ticket': 'Acheter un titre de transport',
    call: 'Appeler',
    cancel: 'Annuler',
    car: 'Voiture',
    'car-distance-duration': 'Conduire {distance} ({duration})',
    car_park: 'parc relais',
    cause: 'Cause',
    'char-left': 'marquer',
    'choose-stop': 'Merci de choisir la ligne',
    citybike: 'Vélo en libre service',
    'citybike-distance-duration': 'VLS {distance} ({duration})',
    'citybike-register-required':
      "L'utilisation du Vélo en libre service nécessite une inscription",
    citybike_off: 'Fermé',
    close: 'Fermer',
    'contact-information': 'Contacter {contactName}',
    'create-account': 'Créer un identifiant {contactName}',
    'cycle-distance-duration': 'Pédaler {distance} ({duration})',
    'cyclewalk-distance-duration':
      'Avancer pied à terre sur {distance} ({duration})',
    datasources: 'Sources des données',
    date: 'Date',
    delete: 'Supprimer',
    'departure-time-in-minutes': '{minutes} min',
    departures: 'Départs',
    description:
      'Trouvez un réseau de transport public pour voyager loin ou à proximité',
    destination: 'Destination',
    'destination-label-change': 'Changer la destination',
    'destination-outside-service':
      'Aucun itinéraire possible pour votre trajet. Aucun réseau de transport à proximité de votre adresse de destination',
    'destination-placeholder': 'Lieu, adresse ou arrêt',
    'dialog-return-to-map': 'Retour à la carte',
    'digitransit-platform': 'Plateforme Digitransit',
    disclaimer:
      'Merci de noter que les résultats sont basés sur des temps de trajet estimés. Les correspondances indiquées ne peuvent être garanties.',
    disruption: 'Perturbation',
    'disruption-info': 'Informations sur les perturbations',
    'disruption-info-no-alerts': "Aucune perturbation n'est répertoriée",
    'disruption-info-route-no-alerts': 'La ligne circule normalement',
    disruptions: 'Perturbations',
    'distance-between': 'Distance {distance1} m — {distance2} m',
    'distance-under': 'Distance inférieure à {distance} m',
    earlier: 'Plus tôt',
    'edit-favourites': 'Éditer les favoris',
    explanations: 'Cause',
    'export-time-range-1': 'Export time range 1',
    'export-time-range-2': 'Export time range 2',
    'extra-info': 'Informations complémentaires',
    'favourite-target': 'Destination favorite',
    ferry: 'Bâteau',
    'ferry-with-route-number': 'Bâteau {routeNumber} {headSign}',
    roadinspection: 'Road inspection',
    'roadinspection-vehicle-time-range': 'Road inspection timerange: 30 days',
    'realtime-roadinspection-vehicles': 'Realtime road inspection',
    'footer-feedback': 'Donner votre avis',
    'footer-link-to-privacy-policy': 'Vie privée',
    frontpage: "Page d'accueil",
    'generic-error': "Une erreur s'est produite",
    'geolocate-yourself': 'Se géolocaliser',
    'geolocation-denied-heading': 'Comment souhaitez-vous démarrer ?',
    'geolocation-denied-text':
      "La géolocalisation n'est pas autorisée. Merci de vérifier votre navigateur/téléphone",
    'geolocation-failed-heading': 'Géolocalisation échouée',
    'geolocation-failed-text':
      'Votre navigateur ne supporte pas le positionnement',
    'geolocation-prompt-text':
      "Merci d'autoriser l'utilisation de votre position.",
    'geolocation-timeout-heading':
      'Le positionnement prend plus de temps que prévu.',
    'geolocation-timeout-text':
      'Merci de sélectionner une origine ou réessayer plus tard.',
    'give-destination': 'Entrer votre destination',
    'give-name-to-location': 'Donner un nom à cet endroit',
    'give-origin': 'Entrer votre point de départ',
    'give-position':
      'Indiquer votre position ou un lieu dans le champ de recherche.',
    'give-route': 'Entrer une ligne',
    'hour-short': 'h',
    'how-to-rate-service': 'Comment noteriez-vous ce service ?',
    'in-addition': 'En plus',
    inquiry: 'Répondre aux questions pour développer le service',
    instructions: 'Instructions',
    'itinerary-feedback-button': 'Envoyez votre avis',
    'itinerary-feedback-message': 'Avez-vous trouvé ce que vous cherchiez ?',
    'itinerary-feedback-placeholder': 'Description (optionnel)',
    'itinerary-hide-stops': 'Cacher les arrêts',
    'itinerary-page.description': 'Itinéraire',
    'itinerary-page.title': 'Itinéraire',
    'itinerary-summary-page.description': 'Trajet recommandé',
    'itinerary-summary-page.title': 'Trajet recommandé',
    'itinerary-ticket.title': 'Titre de transport requis',
    'itinerary-tickets.title': 'Titres de transport requis',
    'itinerary-time.title': 'Duréé',
    'itinerary-walk.title': 'Distance totale de marche',
    'journeyplanner.title': 'Digitransit for Paris',
    later: 'Plus tard',
    leaves: 'Départ',
    'leaving-at': 'Départ',
    'likely-to-recommend':
      'A quel point recommandriez vous ce service à un ami ou un collègue ?',
    'likely-to-use':
      'A quel point utiliseriez-vous ce service plutôt que le service actuel ?',
    loading: 'Chargement',
    locate: 'Localiser',
    'location-examples': 'Gare de Lyon, Longjumeau, Rue du Bac, Châtelet,...',
    'location-from-map': 'Emplacement sélectionné',
    login: 'Se connecter',
    'long-transfers-margin': '12 min',
    'main-menu-label-open': 'Ouvrir le menu principal',
    'main-mode': 'Voyager avec',
    map: 'Carte',
    'messagebar-label-close-message-bar': 'Fermer le message',
    'messagebar-label-page': 'Page',
    'minute-short': 'min',
    minutes: 'min',
    'minutes-or-route': 'Min / Ligne',
    'more-settings': 'Plus de paramètres',
    navigate: 'Voyager',
    'near-you': 'A proximité',
    nearest: 'Le plus proche',
    'network-error': 'Erreur réseau',
    next: 'Suivant',
    'no-bike-allowed-popup':
      "Les vélos ne sont pas autorisés dans les bus ou les trams. Si vous utilisez ces véhicules sur votre trajet, laissez le vélo à l'arrêt ou à un Parc Relais.",
    'no-favourite-locations':
      'Ajoutez vos lieux favoris ici. Les boutons calculeront votre itinéraire automatiquement',
    'no-favourites':
      "Vous pouvez cliquer sur les étoiles pour ajouter une ligne à vos favoris. Après l'ajout, ceux-ci sont disponibles dans l'onglet Favoris de l'interface",
    'no-position': 'Aucune position',
    'no-position-no-stops':
      'Localisation inconnue, impossible de proposer les arrêts à proximité.',
    'no-route-end-date-not-in-range':
      'Current timetable does not include routes departing later.',
    'no-route-msg':
      "Impossible de trouver un itinéraire dans cette zone. Changer le point de départ ou d'arrivée.",
    'no-route-start-date-too-early':
      'Current timetable does not include routes departing earlier.',
    'no-route-start-end': 'Choisir un point de départ et une destination',
    'no-transfers-margin': 'Aucune',
    'no-trips-found': "Il n'y a pas de trajet possible au jour choisi.",
    now: 'Maintenant',
    'number-of-intermediate-stops':
      '{number, plural, =0 {Sans arrêt} =1 {{number} arrêt} other {{number} arrêts} }',
    'number-of-minutes':
      '{number, plural, =0 {0 minute} one {1 minute} other {{number} minutes}}',
    'off-canvas-info':
      "En vous connectant, vos favoris seront sauvegardés et accessibles depuis d'autres appareils",
    'option-default': 'Par défaut',
    'option-least': 'Le moins',
    'option-less': 'Moins',
    'option-more': 'Plus',
    'option-most': 'Le plus',
    or: 'ou',
    origin: 'Départ',
    'origin-label-change': 'Changer le départ',
    'origin-outside-service':
      'Aucun itinéraire possible pour votre trajet. Aucun réseau de transport à proximité de votre adresse de départ',
    'origin-placeholder': 'Lieu, adresse ou arrêt',
    'own-position': 'Position actuelle',
    'page-not-found': 'Page non trouvée',
    'park-and-ride': 'Parc Relais',
    'park-and-ride-availability': 'Parc Relais disponible',
    'park-and-ride-unknown': 'Capacité inconnue',
    'parking-availability': 'Disponibilité du Parc Relais',
    'pay-attention': 'Attention !',
    'pick-icon': 'Sélectionner une icône',
    'pick-mode': 'Sélectionner un mode de transport',
    'place-route-or-keyword': 'Lieu, ligne ou mot-clé',
    'platform-num': 'Quai {platformCode}',
    'platform-short': 'Plat. {platformCode}',
    'prefer-walking': 'Préférer la marche',
    preferred: 'Préférer les lignes',
    previous: 'Précédent',
    print: 'Imprimer',
    'print-timetable': 'Fiche horaire de la semaine',
    'public-transport': 'Transport public',
    rail: 'Train',
    'rail-with-route-number': 'Train {routeNumber} {headSign}',
    realtime: 'Temps-réel',
    'realtime-matching': 'Intégration du temps-réel',
    'rent-cycle-at': 'Décrocher un vélo à la station {station}',
    'required-ticket': 'Titre de transport requis',
    'return-cycle-to': 'Retourner la vélo à la station {station}',
    'right-now': 'Immédiatement',
    route: 'Ligne',
    'route-default': 'Paramètres par défaut',
    'route-destination-arrives': 'Arrivée / Terminus',
    'route-fastest': 'Trajet le plus rapide',
    'route-from-here': "Partir d'ici",
    'route-guide': 'Guide de trajet',
    'route-here': 'Arriver ici',
    'route-least-transfers': 'Éviter les correspondances',
    'route-least-walking': 'Éviter la marche',
    'route-not-possible':
      'Impossible de calculer un itinéraire. Information technique : "{error}"',
    'route-page.description': 'Ligne {shortName}, {longName}',
    'route-page.title': 'Ligne {shortName}',
    'route-page.title-short': 'Ligne',
    'route-prefer-greenways': 'Préférer les pistes cyclables',
    'route-prefer-illuminated': 'Préférer les routes éclairées',
    'route-prefer-paved': 'Préférer les routes pavées',
    'route-prefer-walking-routes': 'Préférer les rues piétonnes',
    'route-prefer-winter-maintenance': 'Préférer les routes déneigées',
    'route-preferences': "Préférences d'itinéraire",
    'route-public-transport-with-bicycle': 'Vélo et transport public',
    'route-saved-settings': 'Paramètres personnalisés',
    'route-stop-or-keyword': 'Ligne, arrêt ou mot-clé',
    routes: 'Lignes',
    run: 'Lancer',
    save: 'Enregistrer',
    search: 'Recherche',
    'search-destination': 'Entrer votre point de destination',
    'search-destination-results-but-no-search':
      "Voir les résultats de l'onglet 'Destination'",
    'search-no-results': 'Aucun résultat',
    'search-origin': 'Entrer votre point de départ',
    'search-position': 'Rechercher la position',
    'search-search-results-but-no-destination':
      "Voir les résultats de l'onglet 'Ligne, arrêt ou mot-clé'",
    'searching-position': 'Localisation en cours...',
    'select-map-layers-header': "Qu'afficher sur la carte ?",
    'select-position': 'Utiliser une localisation précédente',
    send: 'Envoyer',
    settings: 'Réglages',
    'settings-label-change': 'Changer les paramètres',
    'settings-reset': 'Remettre à zéro les paramètres',
    'settings-savebutton': 'Enregistrer les paramètres',
    'settings-saved': 'Paramètres enregistrés !',
    share: 'Partager',
    'show-departures': 'Afficher les départs',
    'show-more': 'Afficher plus',
    'show-routes': 'Afficher les lignes',
    'skip-positioning': 'Ignorer le positionnement',
    slow: 'Lent',
    'specify-location': 'Indiquer un endroit',
    'splash-choose': 'Choisir un point de départ',
    'splash-locating': 'Localisation...',
    'splash-or-choose': 'Ou choisir parmi ces destinations',
    'splash-please-allow-positioning':
      'Ce service fonctionne mieux en autorisant la géolocalisation.',
    'splash-use-positioning': 'Utiliser la position',
    'splash-welcome': 'Comment souhaitez-vous démarrer ?',
    'splash-you-can-also': 'Vous pouvez aussi',
    stop: 'Arrêt',
    'stop-number': "Identifiant d'arrêt",
    'stop-page.description': 'Arrêt - {name} {code}, {desc}',
    'stop-page.title': 'Arrêt - {name} {code}',
    'stop-page.title-short': 'Arrêt',
    stops: 'Arrêts',
    'street-mode-bicycle': 'Vélo',
    'street-mode-car': 'Voiture',
    'street-mode-car_park': 'Parc relais',
    'street-mode-public_transport': 'Transport public',
    'street-mode-walk': 'Marche',
    subway: 'Métro',
    'subway-with-route-number': 'Métro {routeNumber} {headSign}',
    'summary-page.description': '{from} - {to}',
    'summary-page.title': 'Itinéraire proposé',
    'terminal-page.description': 'Terminal - {name}',
    'terminal-page.title': 'Terminal - {name}',
    'terminal-page.title-short': 'Terminal',
    'terms-of-use': 'CGU',
    'ticket-and-price-info': 'Titres et informations tarifaires',
    'ticket-single-adult': 'Adulte',
    'ticket-type-none': 'Pas de limite tarifaire',
    tickets: 'Titres de transport',
    time: 'Heure',
    timetable: 'Fiche horaire',
    'to-stop': "à l'arrêt",
    today: "Aujourd'hui",
    tomorrow: 'Demain',
    tram: 'Tramway',
    'tram-with-route-number': 'Tramway {routeNumber} {headSign}',
    transfers: 'Correspondances',
    'transfers-allowed': 'Autoriser les correspondances',
    'transfers-margin': 'Marge de correspondance',
    'trip-co2-emissions': 'Émissions de CO2',
    'trip-information': 'Informations de voyage',
    'trip-page.description': 'Ligne {shortName}, {longName}',
    'trip-page.title': 'Ligne {shortName}',
    'try-again': 'Réessayer',
    unpreferred: 'Éviter les lignes',
    'use-citybike': 'Utiliser',
    'use-national-service':
      'Vous pouvez aussi utiliser le service national disponible à',
    'use-own-position': 'Utiliser la position actuelle',
    'using-modes': 'Modes de transport',
    'very-likely': 'Très probable',
    'very-unlikely': 'Très improbable',
    'via-leg-stop-duration': "Attente de {stayDuration} à l'étape",
    'via-point': 'Étape',
    'viapoint-slack-amount': "Durée de l'étape",
    'view-route': "Voir l'itinéraire",
    wait: 'Attendre',
    'wait-amount-of-time': 'Attendre {duration}',
    walk: 'marche',
    'walk-distance-duration': 'Marcher {distance} ({duration})',
    walking: 'Marcher',
    'walking-speed': 'Vitesse de marche',
    'warning-call-agency':
      "La ligne {routeName} est à la demande, et doit être réservée à l'avance",
    'warning-call-agency-no-route':
      "Uniquement à la demande. Doit être réservé à l'avance",
    'weather-at-destination': 'Météo à destination',
    yesterday: 'Hier',
    'your-favourites': 'Favoris',
    zones: 'Zones tarifaires',
  },
  nb: {
    'about-this-service': 'Om tjenesten',
    accessibility: 'Tilgjengelighet',
    'add-itinerary-via-point': 'Tilføy viapunkt i reisen',
    'add-location': 'Legg til sted',
    'add-location-to-favourites': 'Legg til sted i favoritter',
    address: 'Adresse',
    'address-or-stop': 'Adresse eller stoppested',
    agency: 'Operatør',
    airplane: 'Fly',
    'airplane-with-route-number': 'Fly {routeNumber}',
    'airport-check-in': 'Bagasje kan sjekkes inn hos {agency}',
    'airport-collect-luggage': 'Hent eventuell bagasje',
    'airport-security-check-go-to-gate':
      'Passer sikkerhetskontrollen og fortsett til gate',
    'approx-meters': '{approxDistance} meter',
    'arriving-at': 'Ankomst',
    'arriving-soon': 'Nå',
    'avoid-transfers': 'Unngå bytter',
    'avoid-walking': 'Unngå gange',
    'back-button-title': 'Gå tilbake til forrige side',
    'back-to-front-page': 'Tilbake til forsiden ›',
    'bike-availability': 'Sykler finnes',
    'bike-availability-short': 'Sykler',
    bus: 'Buss',
    'bus-with-route-number': 'Buss {routeNumber} {headSign}',
    'buy-ticket': 'Kjøp billett',
    call: 'Ring',
    cancel: 'Avbryt',
    'car-distance-duration': 'Kjør {distance} ({duration})',
    cause: 'Årsak',
    'char-left': 'slett',
    'choose-stop': 'Velg stopp',
    citybike: 'Bysykkel',
    'citybike-register-required': 'Bysykkelregistrering påkreves',
    close: 'Steng',
    'contact-information': '{contactName} kontaktinformasjon',
    'create-account': 'Lag {contactName}-konto',
    'cycle-distance-duration': 'Sykle {distance} ({duration})',
    'cyclewalk-distance-duration': 'Trill sykkelen {distance} ({duration})',
    datasources: 'Datakilder',
    delete: 'Slett',
    'departure-time-in-minutes': '{minutes} m',
    departures: 'Avganger',
    description:
      'En nasjonal reiseplanlegger skal bidra til å gjøre det lettere å reise kollektivt over hele landet.',
    destination: 'Til',
    'destination-label-change': 'Endre destinasjon',
    'destination-placeholder': 'Hvor vil du reise?',
    'digitransit-platform': 'Digitransit platform',
    'disruption-info': 'Avviksmelding',
    'disruption-info-no-alerts': 'Ingen avviksmeldinger.',
    'disruption-info-route-no-alerts': 'Ingen avviksmeldinger.',
    disruptions: 'Avvik',
    'distance-between': 'Avstand {distance1} m — {distance2} m',
    'distance-under': 'Avstand mindre enn {distance} m',
    earlier: 'Tidligere',
    'edit-favourites': 'Rediger favorittsted',
    'extra-info': 'Mer info',
    'favourite-target': 'Favorittdestinasjon',
    ferry: 'Ferge',
    'ferry-with-route-number': 'Ferge {routeNumber} {headSign}',
    roadinspection: 'Road inspection',
    'roadinspection-vehicle-time-range': 'Road inspection timerange: 30 days',
    'realtime-roadinspection-vehicles': 'Realtime road inspection',
    frontpage: 'Forside',
    'geolocate-yourself': 'Finn min posisjon',
    'geolocation-denied-heading':
      'Tjenesten fungerer best når du oppgir din posisjon',
    'geolocation-denied-text':
      'Lokalisering er ikke tillatt. Sjekk innstillingene for nettleseren din.',
    'geolocation-failed-heading': 'Lokalisering fungerer ikke',
    'geolocation-failed-text':
      'Nettleseren din har ikke støtte for lokalisering.',
    'geolocation-prompt-text': 'Vennligst aksepter lokalisering.',
    'geolocation-timeout-heading': 'Lokalisering tar lengre tid enn forventet.',
    'geolocation-timeout-text': 'Velg startsted og prøv igjen.',
    'give-destination': 'skriv hvor du vil reise',
    'give-name-to-location': 'Skriv inn et beskrivende navn på stedet',
    'give-origin': 'skriv avgangssted',
    'give-position': 'Skriv inn posisjon i søkefeltet over.',
    'hour-short': 't',
    'how-to-rate-service': 'Hvordan vil du rangere tjenesten?',
    'in-addition': 'Og',
    inquiry: 'Send tilbakemelding',
    instructions: 'Instruksjoner',
    'itinerary-feedback-button': 'Send tilbakemelding',
    'itinerary-feedback-message': 'Fant du ikke det du lette etter?',
    'itinerary-feedback-placeholder': 'Beskrivelse (valgfritt)',
    'itinerary-hide-stops': 'Skjul stopp',
    'itinerary-page.description': 'Reisebeskrivelser',
    'itinerary-page.title': 'Reisebeskrivelser',
    'itinerary-summary-page.description': 'Anbefalte ruter',
    'itinerary-summary-page.title': 'Anbefalte ruter',
    later: 'Senere',
    leaves: 'Går',
    'leaving-at': 'Avgang',
    'likely-to-recommend':
      'Hvor sannsynlig er det at du anbefaler vår tjeneste til en venn eller kollega?',
    'likely-to-use':
      'Hvor sannsynlig er det at du vil bruke denne tjenesten fremfor tjenesten du allerede bruker?',
    loading: 'laster',
    locate: 'Der jeg er',
    'location-examples': 'f.eks hjemme, arbeid, skole ...',
    'location-from-map': 'Velg posisjon',
    login: 'Logg inn',
    'long-transfers-margin': 'Veldig lang',
    'main-menu-label-open': 'Åpen hovedmenyen',
    'main-mode': 'Jeg reiser med',
    map: 'Kart',
    'messagebar-label-close-message-bar': 'Lukk melding',
    'messagebar-label-page': 'Side',
    'minute-short': 'm',
    minutes: 'min',
    navigate: 'Naviger',
    'near-you': 'Nær deg',
    nearest: 'Forrige',
    'network-error': 'Nettverksfeil',
    next: 'Neste',
    'no-favourite-locations':
      'Tilføy de mest besøkte steder her. Det gir deg hurtig rejseplanlegging.',
    'no-favourites':
      'Du kan legge til favoritter ved å trykke på favorittknapper forskjellige steder i reiseplanleggeren',
    'no-position': 'Ingen posisjon',
    'no-position-no-stops':
      'Kan ikke vise holdeplasser i nærheten da vi ikke vet din posisjon.',
    'no-route-end-date-not-in-range':
      'Current timetable does not include routes departing later.',
    'no-route-msg':
      'Vi finner dessverre ingen reiseforslag til angitt rute. Forsøk å endre adresse',
    'no-route-start-date-too-early':
      'Current timetable does not include routes departing earlier.',
    'no-route-start-end': 'Vennligst velg avgangssted og destinasjon.',
    'no-transfers-margin': 'Ingen',
    'no-trips-found': 'Ingen reiser tilgjenelig for denne dagen.',
    now: 'Nå',
    'number-of-intermediate-stops':
      '{number, plural, =0 {Ingen stopp} other {{number} stopp} }',
    'off-canvas-info':
      'Ved å logge inn kan du finne tilbake til dine favoritter på andre enheter',
    or: 'eller',
    origin: 'Fra',
    'origin-label-change': 'Endre avreisested',
    'origin-placeholder': 'Fra',
    'own-position': 'Min posisjon',
    'page-not-found': 'Siden finnes ikke ੨( ･᷄ ︵･᷅ )ｼ',
    'park-and-ride': 'Innfartsparkering',
    'park-and-ride-availability': 'Tilgjengelighet',
    'park-and-ride-unknown': 'Ukjent kapasitet',
    'parking-availability': 'Tilgjengelighet',
    'pay-attention': 'Obs!',
    'pick-icon': 'Velg et ikon',
    'place-route-or-keyword': 'Adresse eller stoppested',
    'platform-num': 'Plattform {platformCode}',
    'platform-short': 'Plat. {platformCode}',
    'prefer-walking': 'Foretrekk gange',
    previous: 'Tidligere',
    print: 'Skriv ut',
    'print-timetable': 'Tidstabeller',
    rail: 'Tog',
    'rail-with-route-number': 'Tog {routeNumber} {headSign}',
    'realtime-matching': 'Sanntidsmatching',
    'rent-cycle-at': 'Lei sykkel fra stativ {station}',
    'required-ticket': 'Billett før påstigning',
    'right-now': 'Nå',
    route: 'linje',
    'route-destination-arrives': 'Destinasjon / Siste holdeplass',
    'route-from-here': 'Finn rute herfra',
    'route-here': 'Finn rute hit',
    'route-not-possible':
      'Dessverre kan vi ikke finne et reiseforslag til deg. Teknisk feil: "{error}"',
    'route-page.description': 'Rute {shortName}, {longName}',
    'route-page.title': 'Rute {shortName}',
    'route-page.title-short': 'Rute',
    'route-stop-or-keyword': 'Linje eller stoppested',
    routes: 'Ruter',
    run: 'Løping',
    save: 'Lagre',
    search: 'Til',
    'search-destination-results-but-no-search': 'Se resultater i Til-fanen',
    'search-no-results': 'Ingen resultater',
    'search-origin': 'skriv avgangssted',
    'search-position': 'Let etter posisjon',
    'search-search-results-but-no-destination': 'Se resultater i Fra-fanen',
    'searching-position': 'Leter etter posisjon',
    'select-position': 'Velg din posisjon basert på tidligere søk',
    send: 'Send',
    settings: 'Innstillinger',
    'settings-label-change': 'Endre innstillinger',
    'settings-reset': 'Nullstill innstillinger',
    'settings-savebutton': 'Lagre innstillinger',
    'settings-saved': 'Endringer lagret!',
    share: 'Del reisen',
    'show-departures': 'Vis avganger',
    'show-more': 'Vis flere',
    'skip-positioning': 'Hopp over',
    slow: 'Spasering',
    'specify-location': 'Angi sted',
    'splash-choose': 'Angi avgangssted',
    'splash-locating': 'Søker etter din posisjon',
    'splash-or-choose': 'Eller velg din posisjon fra',
    'splash-use-positioning': 'Bruk min posisjon',
    'splash-welcome': 'Velkommen til ny versjon av din reiseplanlegger',
    'splash-you-can-also': 'Alternativt',
    stop: 'Holdeplass',
    'stop-number': 'Holdeplassnummer',
    'stop-page.description': 'Holdeplass - {name} {code}, {desc}',
    'stop-page.title': 'Holdeplass - {name} {code}',
    'stop-page.title-short': 'Holdeplass',
    stops: 'Holdeplasser',
    subway: 'T-bane',
    'subway-with-route-number': 'T-bane {routeNumber} {headSign}',
    'summary-page.description': '{from} - {to}',
    'summary-page.title': 'Anbefalte ruter',
    'terminal-page.description': 'Terminal - {name}',
    'terminal-page.title': 'Terminal',
    'terminal-page.title-short': 'Terminal - {name}',
    'terms-of-use': 'Brukervilkår',
    'ticket-and-price-info': 'Billett- og prisinformasjon',
    tickets: 'Billetter',
    time: 'Tid',
    timetable: 'Tidstabeller',
    'to-stop': 'Avstand',
    today: 'I dag',
    tomorrow: 'I morgen',
    tram: 'Trikk',
    'tram-with-route-number': 'Trikk {routeNumber} {headSign}',
    transfers: 'Bytter',
    'transfers-allowed': 'Bytter tillatt',
    'transfers-margin': 'Byttetid',
    'trip-co2-emissions': 'Reisens CO2-utslipp',
    'trip-page.description': 'Linje {shortName}, {longName}',
    'trip-page.title': 'Linje {shortName}',
    'try-again': 'Prøv igjen.',
    'use-citybike': 'Bruk bysykkel',
    'use-own-position': 'Bruk min posisjon',
    'using-modes': 'Transportform',
    'very-likely': 'Svært sannsynlig',
    'very-unlikely': 'Lite sannsynlig',
    'via-leg-stop-duration': 'Viapunkt {stayDuration}',
    'via-point': 'Viapunkt',
    wait: 'Vent',
    'wait-amount-of-time': 'Vent {duration}',
    walk: 'gå',
    'walk-distance-duration': 'Gå {distance} ({duration})',
    walking: 'Gange',
    'walking-speed': 'Ganghastighet',
    'warning-call-agency':
      'Bestillingsrute: {routeName}, avgangen må forhåndsbestilles fra operatør.',
    'warning-call-agency-no-route':
      'Kun bestillingsrute. Avgangen må forhåndsbestilles fra operatør.',
    'weather-at-destination': 'Været på destinasjonen',
    yesterday: 'I går',
    'your-favourites': 'Favoritter',
    zones: 'Billettsoner',
  },
  sv: {
    '1. time range': '1. tidsintervall',
    '2. time range': '2. tidsintervall',
    'about-this-service': 'Om tjänsten',
    accessibility: 'Tillgänglighet',
    'accessibility-limited': 'Med rullstolen',
    'accessibility-nolimit': 'Ingen begränsning',
    'accessibility-report': 'Tillgänglighetsrapport',
    'add-itinerary-via-point': 'Lägg till mellanstopp',
    'add-location': 'Lägg till en plats',
    'add-location-to-favourites':
      'Lägg till en plats som är viktig för dig på fliken Favoriter',
    'additional-analytics': 'Ytterligare analyser',
    address: 'Adress',
    'address-or-stop': 'Adress eller hållplats',
    agency: 'Trafikidkare',
    'air-humidity': 'Luftfuktighet',
    'air-temperature': 'Lufttemperatur',
    airplane: 'Flyg',
    'airplane-with-route-number': 'Flyg {routeNumber}',
    'airport-check-in': 'Bagage kan checkas in på {agency}',
    'airport-collect-luggage': 'Plocka upp ditt bagage',
    'airport-security-check-go-to-gate':
      'Gå igenom säkerhetskontrollen till gate',
    'all-routes': 'Alla linjer',
    'all-routes-disabled': 'Inga linjer',
    'approx-meters': '{approxDistance} m',
    'arriving-at': 'Anländer',
    'arriving-soon': 'Nu',
    'average-speed': 'Medelhastighet',
    'avoid-transfers': 'Undvik byten',
    'avoid-walking': 'Undvik gång',
    back: 'Tillbaka',
    'back-button-title': 'Tillbaka till föregående sida',
    'back-to-front-page': 'Tillbaka till framsidan ›',
    bicycle: 'cyckel',
    'bicycle-distance-duration': 'Cykla {distance} ({duration})',
    'bicycle-route-prop-name': 'Name',
    'bicycle-route-prop-type': 'Type',
    'bicycle-route-prop-type-PLANNED': 'Planned',
    'bicycle-route-prop-type-CURRENT': 'Current',
    'bicycle-route-prop-year': 'Year of completion',
    'bicycle-route-prop-length': 'Length',
    'bicycle-routes': 'Cykelvägar',
    'bicycle-routes-BAANA': 'Baana',
    'bicycle-routes-BAANA-CURRENT': 'Baana',
    'bicycle-routes-BAANA-PLANNED': 'Planned baana',
    'bicycle-routes-BRAND': 'Brand routes',
    'bicycle-routes-BRAND-MAIN': 'Brand route',
    'bicycle-routes-BRAND-PERIMETER': 'Perimeter route',
    'bicycle-routes-MAIN_REGIONAL': 'Main and regional routes',
    'bicycle-routes-MAIN_REGIONAL-MAIN': 'Main route',
    'bicycle-routes-MAIN_REGIONAL-REGIONAL': 'Regional route',
    'bicycle-routes-MAIN_REGIONAL-PLANNED_MAIN': 'Planned main route',
    'bicycle-routes-MAIN_REGIONAL-PLANNED_REGIONAL': 'Planned regional route',
    'bicycle-routes-TYPES': 'Other routes',
    'bicycle-routes-TYPES-2_WAY': '2-way pp+jk',
    'bicycle-routes-TYPES-SLOW_STREET': 'Slow street (=bicycle street)',
    'bicycle-routes-TYPES-BICYCLE_LANE': 'Bicycle lane',
    'bicycle-routes-TYPES-SHARED_SPACE': 'Shared space',
    'bicycle-routes-TYPES-4MW_SHARED': '4 m wide shared pp+jk',
    'bicycle-routes-TYPES-35MW_SHARED': '3.5 m wide shared pp+jk',
    'bicycle-routes-TYPES-ACCESS_ROAD': 'Access road',
    'bicycle-routes-TYPES-WIDE_SHOULDER': 'Wide shoulder',
    'bike-availability': 'Antal cyklar just nu',
    'bike-availability-short': 'Cyklar',
    'bike-not-allowed-bus': 'Cyklar är inte tillåtna på bussen',
    'bike-not-allowed-tram': 'Cyklar är inte tillåtna på spårvagnen',
    'biketransport-citybike': 'Jag använder en stadscykel',
    'biketransport-only-bike': 'Jag reser bara med cykel',
    'biking-amount': 'Antal cyklar',
    'biking-speed': 'Cykling hastighet',
    bus: 'Buss',
    'bus-with-route-number': 'Buss {routeNumber} {headSign}',
    'buy-ticket': 'Så här köper du biljetten (HSL.fi)',
    call: 'Ring',
    cameras: 'Kameror',
    cancel: 'Avbryt',
    car: 'Bil',
    'car-distance-duration': 'Kör {distance} ({duration})',
    car_park: 'infartsparkering',
    cause: 'Orsak',
    'char-left': 'tecken',
    'choose-date': 'Välj datum',
    'choose-date-error': 'Slutdatumet måste vara större än startdatumet.',
    'choose-stop': 'Specifiera val',
    'weather-station': 'Väderstation',
    citybike: 'Stadscykel',
    'citybike-distance-duration': 'Cykla {distance} ({duration})',
    'citybike-off-season': 'Stadscyklarna tas i bruk igen nästa vår',
    'citybike-register-required':
      'Det krävs registrering för att kunna använda stadscyklar',
    citybike_off: 'Stängd',
    clear: 'Klar',
    'clear-selected-options': 'Töm dina val',
    close: 'Stäng',
    'close-comparison': 'Avslut',
    compare: 'Jämför',
    condition: 'Förhållande',
    'contact-information': '{contactName} Kontaktuppgifter',
    'create-account': 'Skapa {contactName} konto',
    'cycle-distance-duration': 'Cykla {distance} ({duration})',
    'cyclewalk-distance-duration': 'Led cykeln {distance} ({duration})',
    daily: 'Dagligen',
    datasources: 'Datakällor',
    date: 'Datum',
    delete: 'Ta bort',
    'departure-time-in-minutes': '{minutes} min',
    departures: 'Avgångar',
    description: 'Res kollektivt när och fjärran.',
    destination: 'Destination',
    'destination-copied': 'Destinationslänk kopierad till urklipp',
    'destination-label-change': 'Byt destination',
    'destination-needed': 'En destination måste vara specifierad',
    'destination-outside-service':
      'Vi kunde tyvärr inte hitta några reseförslag för denna sträcka. Ändra destination, som ligger utanför serviceområde.',
    'destination-placeholder': 'Destination, linje eller hållplats',
    'dew-point-temperature': 'Daggpunktstemperatur',
    'dialog-return-to-map': 'Tillbaka till kartan',
    'digitransit-platform': 'Digitransit-serviceplattformen',
    disclaimer:
      'Resultaten baserar sig på beräknade körtider. Vi kan inte garantera att den förslagna förbindelsen kan förverkligas.',
    disruption: 'Störning',
    'disruption-info': 'Undantagsinfo',
    'disruption-info-no-alerts': 'Inga kända störningar eller avvikelser.',
    'disruption-info-route-no-alerts':
      'Linjen har för tillfället inga kända störningar eller avvikelser.',
    disruptions: 'Störningar',
    'distance-between': 'Avstånd {distance1} m — {distance2} m',
    'distance-total': 'Resans längd',
    'distance-under': 'Avstånd mindre än {distance} m',
    'driving-conditions': 'Körförhållanden',
    earlier: 'Tidigare',
    'eco-counter': 'Eco counter',
    'eco-counter-direction-1': 'Till centrum',
    'eco-counter-direction-2': 'Från centrum',
    'eco-counter-direction-5': 'Ej specificerad',
    'eco-counter-direction-6': 'Norrifrån',
    'eco-counter-direction-7': 'Österifrån',
    'eco-counter-direction-8': 'Söderifrån',
    'eco-counter-direction-9': 'Västerifån',
    'eco-counter-total': 'Totalt',
    'eco-counter-week-title': 'Vecka {date}',
    'edit-favourites': 'Redigera favoritdestination',
    'elevation-gained-total': 'Uppförsbacke totalt',
    'elevation-lost-total': 'Nedförsbacke totalt',
    explanations: 'Förklaringar',
    'export-time-range-1': 'Export time range 1',
    'export-time-range-2': 'Export time range 2',
    'external-modes': 'Övriga färdmedel',
    'extra-info': 'Mer info',
    'favourite-target': 'Favoritdestination',
    ferry: 'Färja',
    'ferry-with-route-number': 'Färja {routeNumber} {headSign}',
    roadinspection: 'Väggranskning',
    'roadinspection-vehicle-time-range':
      'Väggranskning tidsintervall: 30 dagar',
    'realtime-roadinspection-vehicles': 'Väggranskning i realtid',
    fluency: 'Rusningar',
    'footer-links': 'Sidfotslänkar',
    'footer-faq': 'Frågor och svar',
    'footer-feedback': 'Skicka respons',
    'footer-link-to-privacy-policy': 'Registerbeskrivning',
    'from-city': 'Från centrum',
    frontpage: 'Framsidan',
    'generic-error': 'Det hände ett fel',
    'geolocate-yourself': 'Sök position',
    'geolocation-denied-heading': 'Delning av platsinformation är förbjudet',
    'geolocation-denied-text':
      'Du har inte aktiverat platstjänster. Du kan aktivera platsdelning via webbläsarens eller telefonens inställningar.',
    'geolocation-failed-heading': 'Platstjänster otillgängliga.',
    'geolocation-failed-text': 'Försök igen eller skriv in din plats.',
    'geolocation-prompt-text': 'Vänligen tillåt delning av platsinformation.',
    'geolocation-timeout-heading':
      'Det tar längre tid än väntat att hitta din plats.',
    'geolocation-timeout-text':
      'Har du godkänt att webbläsaren får använda din plats?',
    'give-destination': 'Skriv in din destination',
    'give-name-to-location': 'Ge ett namn som beskriver platsen',
    'give-origin': 'Skriv in din avfärdsplats',
    'give-position': 'Skriv in din position eller avfärdsplats i sökfältet.',
    'give-route': 'Sök linje',
    'hide-realtime-on-map': 'Dölj fordonen på kartan',
    hour: 'Timme',
    'hour-short': 'h',
    hourly: 'Varje timme',
    'hourly-with-date': 'Varje timme ({date})',
    'how-to-rate-service': 'Hur skulle du utvärdera tjänsten?',
    'in-addition': 'Även',
    inquiry: 'Vad tycker du om den nya Reseplaneraren. Berätta för oss!',
    instructions: 'Anvisningar',
    'itinerary-feedback-button': 'Skicka respons',
    'itinerary-feedback-message': 'Hittade du inte vad du sökte?',
    'itinerary-feedback-placeholder': 'Beskrivning (valfri)',
    'itinerary-hide-stops': 'Dölj hållplatserna',
    'itinerary-page.description': 'Ruttinformation',
    'itinerary-page.title': 'Ruttinformation',
    'itinerary-summary-page.description': 'Ruttförslag',
    'itinerary-summary-page.title': 'Ruttförslag',
    'itinerary-ticket.title': 'Biljett som behövs',
    'itinerary-tickets.title': 'Biljetter som behövs',
    'itinerary-time.title': 'Restid',
    'itinerary-walk.title': 'Gång totalt',
    'journeyplanner.title': 'HRT Reseplaneraren',
    'last-updated': 'Senast uppdaterad',
    later: 'Senare',
    leaves: 'Avgår',
    'leaving-at': 'Avgångstid',
    'likely-to-recommend':
      'Hur sannolikt är det att du skulle rekommendera den här tjänsten åt en vän eller kollega?',
    'likely-to-use':
      'Hur sannolikt skulle du använda denna tjänst i stället för den nuvarande Reseplaneraren?',
    links: 'Länkar',
    loading: 'Laddar...',
    locate: 'Lokalisera',
    'location-examples': 't.ex. Hem, Arbetsplats, Skola,...',
    'location-from-map': 'Vald position',
    'location-home': 'Hem',
    'location-school': 'Skola',
    'location-shopping': 'Affär',
    'location-sport': 'Sport',
    'location-work': 'Arbetsplats',
    login: 'Logga in',
    'long-transfers-margin': '12 min',
    'main-bicycle-route-contract': 'Endast huvudcykelvägar',
    'main-menu-label-close': 'Stäng huvudmenyn',
    'main-menu-label-open': 'Öppna huvudmenyn',
    'main-mode': 'Jag använder',
    maintenance: 'Underhåll',
    'maintenance-job': 'Underhållsjobb',
    'maintenance-job-0': 'Andra underhållsjobb',
    'maintenance-job-1357': 'Gatuborstning',
    'maintenance-job-1364': 'Utjämning av vägytan',
    'maintenance-job-1366': 'Utjämning av vägytan',
    'maintenance-job-1367': 'Halkbekämpning',
    'maintenance-job-1368': 'Halkbekämpning',
    'maintenance-job-1369': 'Halkbekämpning',
    'maintenance-job-1370': 'Snöplogning',
    'maintenance-job-2864': 'Utjämning av vägytan',
    'maintenance-job-99901': 'SnöGatuborstning',
    'maintenance-job-99902': 'Väggranskning',
    'maintenance-job-legend-0': 'Andra underhållsjobb',
    'maintenance-job-legend-1357': 'Gatuborstning',
    'maintenance-job-legend-1364': 'Utjämning av vägytan',
    'maintenance-job-legend-1366': 'Utjämning av vägytan',
    'maintenance-job-legend-1367': 'Halkbekämpning',
    'maintenance-job-legend-1368': 'Halkbekämpning',
    'maintenance-job-legend-1369': 'Halkbekämpning',
    'maintenance-job-legend-1370': 'Snöplogning',
    'maintenance-job-legend-2864': 'Utjämning av vägytan',
    'maintenance-job-legend-99901': 'Gatuborstning',
    'maintenance-job-legend-99902-car': 'Väggranskning (bil)',
    'maintenance-job-legend-99902-bicycle': 'Väggranskning (cykel)',
    'maintenance-job-realization': 'Förverkligande',
    'maintenance-vehicle-name': 'Ajoneuvo #{vehicleNumber}',
    'maintenance-vehicle-time-range': 'Tidsintervall',
    'maintenance-vehicle-time-range-12h': '12h',
    'maintenance-vehicle-time-range-1d': '1d',
    'maintenance-vehicle-time-range-1h': '1h',
    'maintenance-vehicle-time-range-3d': '3d',
    'maintenance-vehicle-time-range-30d': '30d Gatuborstning',
    'maintenance-vehicle-time-range-3h': '3h',
    'maintenance-vehicle-time-range-6h': '6h',
    'maintenance-inspection-vehicle-type-car': '(bil) ',
    'maintenance-inspection-vehicle-type-bicycle': '(cykel) ',
    'maintenance-inspection-vehicle-type-unknown': '(okänd) ',
    map: 'Karta',
    'map-layer-citybike': 'Stadscykelstation',
    'map-layer-park-and-ride': 'Infartsparkering',
    'map-layer-stop-bus': 'Busshållplats',
    'map-layer-stop-ferry': 'Färja',
    'map-layer-stop-tram': 'Spårvagnshållplats',
    'map-layer-terminal-bus': 'Bussterminal',
    'map-layer-terminal-rail': 'Tågstation',
    'map-layer-terminal-subway': 'Metrostation',
    'map-layer-ticket-sales-machine': 'Biljettautomat',
    'map-layer-ticket-sales-point': 'Laddningsplats för resekort',
    'messagebar-label-close-message-bar': 'Stäng banner',
    'messagebar-label-page': 'Sidan',
    'minute-short': 'min',
    minutes: 'min',
    'minutes-or-route': 'Min / Linje',
    monthly: 'Månatligen',
    more: 'Mer',
    'more-settings': 'Fler inställningar',
    motorist: 'Bilist',
    navigate: 'Navigera',
    'near-you': 'Nära dig',
    nearest: 'Närmaste',
    'network-error': 'Nätverksfel',
    next: 'Nästa',
    'no-bike-allowed-popup':
      'Cyklar är inte tillåtna i bussar eller spårvagnar. Om du använder dessa fordon på din färd, lämna cykeln vid hållplatsen eller vid infartsparkeringen.',
    'no-favourite-locations':
      'Lägg till dina oftast använda platser här. Knapparna för dig rakt till reseplanen.',
    'no-favourites':
      'Genom att klicka på stjärnan kan du lägga till en linje eller hållplats till dina favoriter. Dina favoriter syns på den här sidan.',
    'no-position': 'Ingen position',
    'no-position-no-stops':
      'De närmaste hållplatserna kan inte visas, eftersom din position inte är känd.',
    'no-route-end-date-not-in-range':
      'Tidtabellen har inga resor som avgår senare.',
    'no-route-msg':
      'Vi kunde tyvärr inte hitta några reseförslag för denna sträcka. Ändra adress.',
    'no-route-start-date-too-early':
      'Tidtabellen har inga resor som avgår tidigare.',
    'no-route-start-end': 'Välj avfärdsplats och destination.',
    'no-transfers-margin': '1 min',
    'no-trips-found': 'Inga resor på den valda dagen.',
    now: 'Nu',
    'number-of-intermediate-stops':
      '{number, plural, =0 {Inga hållplatser} one {1 hållplats} other {{number} hållplatser} }',
    'number-of-minutes':
      '{number, plural, =0 {0 minuter} one {1 minut} other {{number} minuter}}',
    'off-canvas-info':
      'Genom att logga in kan du spara dina favoriter och använda dem med dina andra enheter.',
    'option-default': 'Standard',
    'option-least': 'Minst',
    'option-less': 'Mindre',
    'option-more': 'Mera',
    'option-most': 'Mest',
    or: 'eller',
    origin: 'Avfärdsplats',
    'origin-label-change': 'Byt avfärdsplats',
    'origin-outside-service':
      'Vi kunde tyvärr inte hitta några reseförslag för denna sträcka. Ändra avfärdsplats, som ligger utanför serviceområde.',
    'origin-placeholder': 'Från - adress eller hållplats',
    'own-position': 'Min position',
    'page-not-found': 'Sidan kunde inte hittas.',
    'park-and-ride': 'Infartsparkering',
    'park-and-ride-availability': 'Lediga parkeringsplatser',
    parking: 'Parkering',
    'parking-availability': 'Lediga parkeringsplatser',
    'pay-attention': 'Obs!',
    'pick-icon': 'Välj en ikon',
    'pick-mode': 'Välj ett transportläge',
    'place-route-or-keyword': 'Destination, linje eller hållplats',
    'platform-num': 'Plattform {platformCode}',
    'platform-short': 'Plattf. {platformCode}',
    'prefer-walking': 'Främja gång',
    preferred: 'Föredra rutter',
    previous: 'Senaste',
    print: 'Skriv ut',
    'print-timetable': 'Veckans tidtabell',
    'public-transport': 'Kollektivtrafik',
    rail: 'Tåg',
    'rail-with-route-number': 'Tåg {routeNumber} {headSign}',
    rain: 'Regn',
    'rain-classification': 'Klassificering av regn',
    'rainfall-depth': 'Nederbörd',
    'rainfall-intensity': 'Regnintensitet',
    'no-rain': 'Torrväder',
    'has-rain': 'Regn',
    'light-snow': 'Lätt snöfall',
    'moderate-snow': 'Måttligt snöfall',
    'heavy-snow': 'Kraftigt snöfall',
    realtime: 'Realtid',
    'realtime-maintenance-vehicles': 'Vägunderhåll i realtid',
    'realtime-matching': 'Realtidsintegration',
    'rent-cycle-at': 'Hyr en cykel från stationen {station}',
    'required-ticket': 'Biljett som behövs under resan',
    'return-cycle-to': 'Returnera cykeln till stationen {station}',
    'reverse-route': 'Omvänd färdriktning',
    'right-now': 'Just nu',
    'road-condition': 'Väglag',
    'road-condition-air': 'Luft',
    'road-condition-condition': 'Väglag',
    'road-condition-now': 'Nu',
    'road-condition-road': 'Väg',
    'road-condition-time': 'Tid',
    'road-condition-weather': 'Väder',
    'road-condition-wind': 'Vind',
    'road-info': 'Väginformation',
    'road-signs': 'Variabla vägmärker',
    'road-sign': 'Variabel vägmärke',
    'road-sign-type': 'Type',
    'road-sign-display-value': 'Display value',
    'road-sign-effective-from': 'Effective from',
    'road-sign-SPEEDLIMIT': 'Hastighetsbegränsning',
    'road-sign-WARNING': 'Varning',
    'road-sign-INFORMATION': 'Information',
    'road-sign-warning-144': 'Slirig körbana',
    'road-sign-warning-165': 'Trafikljus',
    'road-sign-warning-189': 'Annan fara',
    'road-sign-warning-133': 'Köbildning',
    'road-sign-warning-142': 'Vägarbete',
    'road-sign-warning-183': 'Sidvind',
    'road-sign-warning-141': 'Ojämn väg',
    'road-sign-warning-143': 'Stenskott',
    'road-sign-warning-147': 'Farlig vägkant',
    'road-sign-warning-154': 'Skidspår',
    'road-sign-warning-155': 'Älgar',
    'road-sign-warning-156': 'Renar',
    'road-temperature': 'Vägtemperatur',
    roadwork: 'Vägarbete',
    roadworks: 'Vägarbeten',
    route: 'Linje',
    'route-custom-settings': 'Mina inställningar',
    'route-default': 'Standardrutt',
    'route-destination-arrives': 'Ankomst / Ändhållplats',
    'route-fastest': 'Snabb rutt',
    'route-from-here': 'Sök rutt härifrån',
    'route-guide': 'Linjeguide',
    'route-here': 'Sök rutt hit',
    'route-here-button': 'Kopiera rutten hit',
    'route-least-elevation-changes': 'Undvik höjdskillnader',
    'route-least-transfers': 'Minsta överföringar',
    'route-least-walking': 'Minsta gången',
    'route-not-possible':
      'Tyvärr är din rutt inte möjlig. Tekniskt fel: "{error}"',
    'route-page.description': 'Linje {shortName} - {longName}',
    'route-page.title': 'Linje {shortName}',
    'route-page.title-short': 'Linje',
    'route-prefer-greenways': 'Föredra lättrafikleder',
    'route-prefer-illuminated': 'Föredra upplysta rutter',
    'route-prefer-paved': 'Föredra belagda rutter',
    'route-prefer-walking-routes': 'Föredra gångvägar',
    'route-prefer-winter-maintenance': 'Föredra rutter med vinterunderhåll',
    'route-preferences': 'Ruttpreferenser',
    'route-public-transport-with-bicycle': 'Med cykel och kollektivtrafik',
    'route-saved-settings': 'Sparade inställningar',
    'route-stop-or-keyword': 'Linje eller hållplats',
    routes: 'Linjer',
    run: 'Springande',
    save: 'Spara',
    search: 'Sök',
    'search-destination': 'Sök destination, linje eller hållplats',
    'search-destination-results-but-no-search':
      'Se resultaten under fliken Destination',
    'search-no-results': 'Inga sökresultat',
    'search-origin': 'Sök avfärdsplats, linje eller hållplats',
    'search-position': 'Sök position',
    'search-search-results-but-no-destination':
      'Se resultaten under fliken Linje eller hållplats',
    'searching-position': 'Söker position...',
    'select-map-layers-header': 'Vad vill du visa på kartan?',
    'select-position': 'Välj din position bland tidigare sökningar',
    send: 'Skicka',
    settings: 'Anpassa',
    'settings-label-change': 'Anpassa sökning',
    'settings-loadbutton': 'Ladda inställningarna',
    'settings-loaded': 'Inställningarna laddades!',
    'settings-reset': 'Återställ inställningarna',
    'settings-savebutton': 'Spara inställningarna',
    'settings-saved': 'Inställningarna sparades!',
    share: 'Dela resan',
    'show-departures': 'Visa avgångarna',
    'show-information-as-list': 'Visa informationen som en lista',
    'show-more': 'Visa flera',
    'show-realtime-on-map': 'Visa fordonen på kartan',
    'show-routes': 'Visa linjer',
    'skip-positioning': 'Hoppa över',
    slow: 'Långsam',
    'snow-depth': 'Snödjup',
    'specify-location': 'Ange en plats',
    'speed-limit': 'Hastighetsbegränsning',
    'splash-choose': 'Ange en avfärdsplats',
    'splash-locating': 'Söker din plats...',
    'splash-or-choose': 'eller välj en avfärdsplats',
    'splash-please-allow-positioning':
      'Tjänsten fungerar bäst om du delar din plats.',
    'splash-use-positioning': 'Använd min plats',
    'splash-you-can-also': 'alternativt',
    stop: 'Hållplats',
    'stop-number': 'Hållplatsnummer',
    'stop-page.description': 'Hållplats {name} - {code}',
    'stop-page.title': 'Hållplats {name} - {code}',
    'stop-page.title-short': 'Hållplats',
    stops: 'Hållplatser',
    'street-mode-bicycle': 'Cykel',
    'street-mode-car': 'Bil',
    'street-mode-car_park': 'Infarts\u00adparkering',
    'street-mode-public_transport': 'Kollektiv\u00adtrafik',
    'street-mode-walk': 'Gång',
    subway: 'Metro',
    'subway-with-route-number': 'Metro {routeNumber} {headSign}',
    'summary-page.description': '{from} - {to}',
    'summary-page.title': 'Ruttförslag',
    'terminal-page.description': 'Terminal {name}',
    'terminal-page.title': 'Terminal {name}',
    'terminal-page.title-short': 'Terminal',
    'terms-of-use': 'Användarvillkor',
    'ticket-and-price-info': 'Biljett- och prisinformation',
    'ticket-single-adult': 'Vuxen',
    'ticket-type-none': 'Ingen resezonsbegränsning',
    tickets: 'Biljetter',
    time: 'Tid',
    timetable: 'Tidtabell',
    'to-city': 'Till centrum',
    'to-stop': 'Avstånd',
    today: 'I dag',
    tomorrow: 'I morgon',
    'traffic-announcement-additional-info': 'Specifikation',
    'traffic-announcement-additional-info-at': 'Uppvärmning med eld',
    'traffic-announcement-additional-info-fd': 'Följ vägledning',
    'traffic-announcement-additional-info-pd': 'Polisen dirigerar trafiken',
    'traffic-announcement-class-acc': 'Onnettomuus',
    'traffic-announcement-class-act': 'Yleisö- tai muut tapahtumat',
    'traffic-announcement-class-equ': 'Tienvarsilaitteiden toiminta',
    'traffic-announcement-class-fos': 'Näkyvyys- ja ympäristöolosuhteet',
    'traffic-announcement-class-krk': 'Kelirikko',
    'traffic-announcement-class-los': 'Liikennetilanne',
    'traffic-announcement-class-mhz':
      'Vaaraa aiheuttava ajoneuvo tai kuljetus tiellä',
    'traffic-announcement-class-ohz': 'Este esine tai eläin tiellä',
    'traffic-announcement-class-res': 'Liikennerajoitukset',
    'traffic-announcement-class-rmt': 'Tietyö',
    'traffic-announcement-class-shz': 'Ajo-olot liukkaus kelitilanne',
    'traffic-announcement-duration': 'Beräknad varaktighet',
    'traffic-announcement-duration-gte_180_lte_360': '3 - 6 timmar',
    'traffic-announcement-duration-gte_30_lte_60': '30 - 60 minuter',
    'traffic-announcement-duration-gte_360': 'Över 6 timmar',
    'traffic-announcement-duration-gte_60_lte_180': '1 - 3 timmar',
    'traffic-announcement-duration-lte_30': 'Under 30 minuter',
    'traffic-announcement-modes-of-transport': 'Trafikformat',
    'traffic-announcement-modes-of-transport-bicycle': 'Cyckel',
    'traffic-announcement-modes-of-transport-car': 'Bil',
    'traffic-announcement-modes-of-transport-pedestrian': 'Fotgängare',
    'traffic-announcement-modes-of-transport-public_transport':
      'Kollektivtrafik',
    'traffic-announcement-oversize-load': 'Specialtransport',
    'traffic-announcement-oversize-load-definition': '(B. x H. x L. i meter)',
    'traffic-announcement-severity': 'Påverkan',
    'traffic-announcement-severity-high': 'Påverkar trafiken betydligt',
    'traffic-announcement-severity-low': 'Påverkar ej trafiken',
    'traffic-announcement-severity-medium': 'Påverkar trafiken',
    'traffic-announcement-subclass-abl': 'Erikoiskuljetus',
    'traffic-announcement-subclass-acb': 'Onnettomuus, jossa mukana linja-auto',
    'traffic-announcement-subclass-ach':
      'Onnettomuus, jossa mukana raskas ajoneuvo',
    'traffic-announcement-subclass-aci': 'Onnettomuus',
    'traffic-announcement-subclass-acl': 'Onnettomuuspaikan raivaus käynnissä',
    'traffic-announcement-subclass-acm': 'Usean ajoneuvon onnettomuus',
    'traffic-announcement-subclass-acz':
      'Onnettomuus, jossa mukana vaarallisia aineita',
    'traffic-announcement-subclass-ali': 'Alikulkukäytävän rakentaminen',
    'traffic-announcement-subclass-anh': 'Poroja tiel',
    'traffic-announcement-subclass-anl': 'Hirviä tiellä',
    'traffic-announcement-subclass-anm': 'Eläimiä tiellä',
    'traffic-announcement-subclass-aol': 'Raskas ajoneuvo kaatunut tielle',
    'traffic-announcement-subclass-aqd': 'Vesiliirron vaara',
    'traffic-announcement-subclass-asp': 'Ulosajo',
    'traffic-announcement-subclass-bkd': 'Rikkoutunut ajoneuvo tiellä',
    'traffic-announcement-subclass-bli': 'Lumimyrsky',
    'traffic-announcement-subclass-bls': 'Pöllyävää lunta',
    'traffic-announcement-subclass-brc': 'Silta suljettu liikenteeltä',
    'traffic-announcement-subclass-ctr':
      'Liikenne ohjataan kaksisuuntaisena toiselle ajoradalle',
    'traffic-announcement-subclass-dcd': 'Huono ajokeli',
    'traffic-announcement-subclass-dcn': 'Ajo-olot parantuneet',
    'traffic-announcement-subclass-do': 'Kiertotie käytössä',
    'traffic-announcement-subclass-efa': 'Messut',
    'traffic-announcement-subclass-ens': 'ENSITIEDOTE LIIKENNEONNETTOMUUDESTA',
    'traffic-announcement-subclass-epr': 'Kulkue',
    'traffic-announcement-subclass-esp': 'Urheilutapahtuma',
    'traffic-announcement-subclass-evd': 'Mielenosoitus',
    'traffic-announcement-subclass-evm': 'Yleisötapahtuma',
    'traffic-announcement-subclass-fig': 'Maastopalo',
    'traffic-announcement-subclass-fir': 'Tulipalo',
    'traffic-announcement-subclass-fld': 'Tulva',
    'traffic-announcement-subclass-flt': 'Puu kaatunut tielle',
    'traffic-announcement-subclass-fod': 'Sakeaa sumua',
    'traffic-announcement-subclass-fof': 'Jäätävää sumua',
    'traffic-announcement-subclass-fog': 'Sumua',
    'traffic-announcement-subclass-fop': 'Paikoitellen sumua',
    'traffic-announcement-subclass-fpc': 'Sähköjohtoja pudonnut tielle',
    'traffic-announcement-subclass-fue': 'Öljyä tiellä',
    'traffic-announcement-subclass-gal': 'Kova tuuli',
    'traffic-announcement-subclass-hai': 'Raekuuro',
    'traffic-announcement-subclass-hav': 'Hirviaita vaurioitunut',
    'traffic-announcement-subclass-hbd': 'Rikkoutunut raskas ajoneuvo tiellä',
    'traffic-announcement-subclass-ibu': 'Tiet jäätymässä',
    'traffic-announcement-subclass-icp': 'Paikoin liukasta',
    'traffic-announcement-subclass-jjm':
      'Kevyen liikenteen väylät jäisiä ja märkiä',
    'traffic-announcement-subclass-jjt': 'Kevyen liikenteen väylät jäätymäs',
    'traffic-announcement-subclass-jli': 'Kevyen liikenteen väylät liukkaita',
    'traffic-announcement-subclass-jlu': 'Kevyen liikenteen väylillä lunta',
    'traffic-announcement-subclass-jpl':
      'Kevyen liikenteen väylät paikoin liukkaita',
    'traffic-announcement-subclass-jyr': 'Päällysteen jyrsintätyö',
    'traffic-announcement-subclass-kai': 'Sadevesikaivojen puhdistustyö',
    'traffic-announcement-subclass-kav': 'Kaidevaurio',
    'traffic-announcement-subclass-kdt': 'Kaidetyö',
    'traffic-announcement-subclass-kkp': 'Kaivon kansi rikki/poissa paikaltaan',
    'traffic-announcement-subclass-klv': 'Kevyenliikenteen väylän rakentaminen',
    'traffic-announcement-subclass-kri': 'Kelirikko',
    'traffic-announcement-subclass-kuo': 'Kuoppia tiessä',
    'traffic-announcement-subclass-lc1': 'Yksi ajokaista suljettu liikenteeltä',
    'traffic-announcement-subclass-lc2':
      'Kaksi ajokaistaa suljettu liikenteeltä',
    'traffic-announcement-subclass-lcs': 'Ajokaistoja on suljettu liikenteeltä',
    'traffic-announcement-subclass-lij': 'Liittymän parannustyö',
    'traffic-announcement-subclass-lin': 'Ajokaista ajoittain suljettu',
    'traffic-announcement-subclass-lo1': 'Vain yksi ajokaista käytössä',
    'traffic-announcement-subclass-lo2': 'Vain kaksi kaistaa käytössä',
    'traffic-announcement-subclass-loh': 'Tienvarsilaitteiden huoltotyö',
    'traffic-announcement-subclass-lpa': 'Liikenne pysäytetään ajoittain',
    'traffic-announcement-subclass-ls1': 'Liikenne seisoo',
    'traffic-announcement-subclass-ls2': 'Liikenne pysähtelee',
    'traffic-announcement-subclass-ls3': 'Liikenne hidasta',
    'traffic-announcement-subclass-ls4': 'Liikenne jonoutunut',
    'traffic-announcement-subclass-ls6': 'Pitkiä jonoja',
    'traffic-announcement-subclass-lsl': 'Tie sortunut',
    'traffic-announcement-subclass-lso': 'Liikenne saattaa ruuhkautua',
    'traffic-announcement-subclass-lsr': 'Liikenne saattaa ruuhkautua',
    'traffic-announcement-subclass-lvt': 'Liikennevalotyö',
    'traffic-announcement-subclass-mar': 'Maratonjuoksu',
    'traffic-announcement-subclass-mil': 'Sotilassaattue',
    'traffic-announcement-subclass-nit': 'Niittotyö',
    'traffic-announcement-subclass-nls': 'Ajokaistoja kavennettu',
    'traffic-announcement-subclass-ohx': 'Liikennettä vaarantava este tiellä',
    'traffic-announcement-subclass-oil': 'Polttoainetta tiellä',
    'traffic-announcement-subclass-pav': 'Päällystevaurio',
    'traffic-announcement-subclass-pel': 'Pelastusharjoitus',
    'traffic-announcement-subclass-pki': 'POLIISIN KELI-ILMOITUS',
    'traffic-announcement-subclass-pys': 'Pysäkkiin liittyvä ilmoitus',
    'traffic-announcement-subclass-rad': 'Kiertotieopastus',
    'traffic-announcement-subclass-raf': 'Jäätävää sadetta',
    'traffic-announcement-subclass-rai': 'Vesisade',
    'traffic-announcement-subclass-raj': 'Raskas ajoneuvo jumissa',
    'traffic-announcement-subclass-rba': 'Liikenne pysäytetään',
    'traffic-announcement-subclass-rbi': 'Mustaa jäätä',
    'traffic-announcement-subclass-rbl': 'Räjäytystyö',
    'traffic-announcement-subclass-rbm': 'Siltatyö',
    'traffic-announcement-subclass-rca': 'Toinen ajorata suljettu liikenteeltä',
    'traffic-announcement-subclass-rcd': 'Tie suljettu liikenteeltä',
    'traffic-announcement-subclass-rcw': 'Tienrakennustyö',
    'traffic-announcement-subclass-reb': 'Liittymisramppi tukossa',
    'traffic-announcement-subclass-rec': 'Ramppi suljettu liikenteeltä',
    'traffic-announcement-subclass-ret': 'Poikkeavat kaistajärjestelyt',
    'traffic-announcement-subclass-rew': 'Raivaus- ja korjaustöitä',
    'traffic-announcement-subclass-ric': 'Tiet ovat jäisiä',
    'traffic-announcement-subclass-ril': 'Risteykset liukkaita',
    'traffic-announcement-subclass-rin': 'Tie ajoittain suljettu liikenteeltä',
    'traffic-announcement-subclass-rmk': 'Kunnossapito- ja hoitotyöt',
    'traffic-announcement-subclass-rmv': 'Työmaa-ajoa liikenteen seassa',
    'traffic-announcement-subclass-rmw': 'Tiemerkintätyö',
    'traffic-announcement-subclass-rnl': 'Muuttuneet liikennejärjestelyt',
    'traffic-announcement-subclass-rno': 'Rekan nosto',
    'traffic-announcement-subclass-rpc': 'Tienpinta huonossa kunnossa',
    'traffic-announcement-subclass-rrw': 'Tietyö keskikaistalla',
    'traffic-announcement-subclass-rsl': 'Tiet ovat liukkaita',
    'traffic-announcement-subclass-rwi': 'Tiet jäisiä ja märkiä',
    'traffic-announcement-subclass-rwk': 'Tietyö',
    'traffic-announcement-subclass-rwl': 'Pitkäaikainen tienparannustyö',
    'traffic-announcement-subclass-rwm': 'Tienparannustyö',
    'traffic-announcement-subclass-rwr': 'Päällystystyö',
    'traffic-announcement-subclass-rxb': 'Poistumisramppi tukossa',
    'traffic-announcement-subclass-sat':
      'Kohtaava liikenne, ajo vuorotellen ohjauksen mukaan',
    'traffic-announcement-subclass-sav': 'Savea tiellä',
    'traffic-announcement-subclass-sfh': 'Sakea lumisade',
    'traffic-announcement-subclass-sfl': 'Lumisade',
    'traffic-announcement-subclass-shl': 'Kuorma levinnyt tielle',
    'traffic-announcement-subclass-sjt': 'Sähköjohtotyö',
    'traffic-announcement-subclass-slt': 'Räntäsade',
    'traffic-announcement-subclass-slu': 'Lumisohjoa',
    'traffic-announcement-subclass-smo': 'Savu haittaa näkyvyyttä',
    'traffic-announcement-subclass-smv':
      'Nestettä vuotanut liikkuvasta ajoneuvosta',
    'traffic-announcement-subclass-snp': 'Lumi- tai jääpolanne',
    'traffic-announcement-subclass-snr': 'Lumikinoksia',
    'traffic-announcement-subclass-snw': 'Kaistaopasteet eivät toimi',
    'traffic-announcement-subclass-sok': 'Sivuojan kaivuutyö',
    'traffic-announcement-subclass-son': 'SUURONNETTOMUUS',
    'traffic-announcement-subclass-spk': 'Sirotepaikkaus, irtokiviä',
    'traffic-announcement-subclass-spy': 'Roiskevesi haittaa näkyvyyttä',
    'traffic-announcement-subclass-sro': 'Lunta tiellä',
    'traffic-announcement-subclass-std': 'Myrskyvaurioita',
    'traffic-announcement-subclass-stk': 'Soratien kunto huono',
    'traffic-announcement-subclass-stm': 'Myrsky',
    'traffic-announcement-subclass-taa': 'Toinen ajokaista avattu liikenteelle',
    'traffic-announcement-subclass-tal': 'Akselipainorajoitus',
    'traffic-announcement-subclass-tav': 'Tie on avattu liikenteelle',
    'traffic-announcement-subclass-tbu': 'Liikenne ruuhkautuu',
    'traffic-announcement-subclass-tcn': 'Liikenneruuhka',
    'traffic-announcement-subclass-tcx': 'Liikenneruuhka ohi',
    'traffic-announcement-subclass-tea': 'Liikenne on palautumassa normaaliksi',
    'traffic-announcement-subclass-tgw': 'Painorajoitus asetettu',
    'traffic-announcement-subclass-tlo': 'Liikennevalot eivät toimi',
    'traffic-announcement-subclass-tlt': 'Tilapäinen liikennevalo-ohjaus',
    'traffic-announcement-subclass-tmt': 'Tutkimus- ja mittaustyö',
    'traffic-announcement-subclass-tnr': 'Tilapäinen nopeusrajoitus',
    'traffic-announcement-subclass-tol': 'Tie on liukas',
    'traffic-announcement-subclass-tri': 'Tasoristeystyö',
    'traffic-announcement-subclass-tuc': 'Tunneli suljettu liikenteeltä',
    'traffic-announcement-subclass-tvl': 'Tievalaistuksen lamppuja pimeänä',
    'traffic-announcement-subclass-tvm': 'Tievalaistus vioittunut myrskyssä',
    'traffic-announcement-subclass-tvo':
      'Tievalaistus vioittunut onnettomuudessa',
    'traffic-announcement-subclass-tvt': 'Tievalaistustyö',
    'traffic-announcement-subclass-vet': 'Vettä tiellä',
    'traffic-announcement-subclass-vfr': 'Palava ajoneuvo',
    'traffic-announcement-subclass-vir': 'Näkyvyys heikentynyt',
    'traffic-announcement-subclass-vka': 'Järjestelmän vikatila',
    'traffic-announcement-subclass-vmt': 'Viimeistelytyö',
    'traffic-announcement-subclass-vnw': 'Muuttuvat opasteet eivät toimi',
    'traffic-announcement-subclass-vrt': 'Vesakon raivaus',
    'traffic-announcement-subclass-vwc': 'Ajoneuvo väärällä ajoradalla',
    'traffic-announcement-subclass-wbc': 'Kaapelityö',
    'traffic-announcement-subclass-wbs': 'Kunnallistekninen työ',
    'traffic-announcement-subclass-wic': 'Kova sivutuuli',
    'traffic-announcement-subclass-wig': 'Puuskittainen tuuli',
    'traffic-announcement-subclass-yak':
      'Työkohteessa vain yksi ajokaista käytössä',
    'traffic-announcement-temporary-speed-limit':
      'Tillfällig hastighetsbegränsning',
    'traffic-announcement-traffic-direction': 'Riktning',
    'traffic-announcement-traffic-direction-both': 'Båda hållen',
    'traffic-announcement-traffic-direction-negative': 'Motgående riktning',
    'traffic-announcement-traffic-direction-positive': 'Vald riktning',
    'traffic-announcement-vehicle-size-limit': 'Fordonsbegränsning',
    'traffic-announcement-vehicle-size-limit-definition':
      '(B. x H. x L. x V. i meter och ton)',
    'traffic-camera': 'Trafikkamera',
    'traffic-camera-no-recent-images': 'Inga nyligen tagna bilder',
    'traffic-count': 'Trafikmängd',
    'traffic-direction': 'Riktning',
    'traffic-flow': 'Trafikflöde',
    'traffic-flow-heavy': 'Rusning',
    'traffic-flow-medium': 'Långsam',
    'traffic-flow-normal': 'Normal',
    'traffic-flow-unknown': 'Kunde ej definieras',
    'traffic-info': 'Trafikinformation',
    'traffic-monitoring': 'Trafikövervakning',
    'traffic-restriction': 'Trafikrestriktion',
    tram: 'Spårvagn',
    'tram-with-route-number': 'Spårvagn {routeNumber} {headSign}',
    transfers: 'Antal byten',
    'transfers-allowed': 'Flera byten',
    'transfers-margin': 'Bytestid minst',
    'trip-co2-emissions': 'Resans CO2-utsläpp',
    'trip-information': 'Visa avgång',
    'trip-page.description': 'Linje {shortName} - {longName}',
    'trip-page.title': 'Linje {shortName}',
    'try-again': 'Försök igen',
    unpreferred: 'Undvik rutter',
    'use-citybike': 'Ta i bruk',
    'use-national-service': 'Du kan också använda national tjänsten:',
    'use-own-position': 'Använd min position',
    'using-modes': 'Jag vill åka',
    'very-likely': 'Väldigt sannolikt',
    'very-unlikely': 'Väldigt osannolikt',
    'via-leg-stop-duration': 'Mellanstopp {stayDuration}',
    'via-point': 'Stoppets längd',
    'viapoint-remove': 'Ta bort stoppet',
    'viapoint-slack-amount': 'Stoppets varaktighet',
    'view-route': 'Visa linje',
    wait: 'Vänta',
    'wait-amount-of-time': 'Vänta {duration}',
    walk: 'gång',
    'walk-distance-duration': 'Gå {distance} ({duration})',
    walking: 'Gång',
    'walking-speed': 'Promenadhastighet',
    'warning-call-agency':
      'Trafikeras enligt behov: {routeName}, som bör beställas på förhand.',
    'warning-call-agency-no-route':
      'Trafikeras enligt behov som bör beställas på förhand.',
    'weather-at-destination': 'Vädret på destinationen',
    'weather-stations': 'Väderstationer',
    weekly: 'Varje vecka',
    'wind-direction': 'Vindriktning',
    'wind-speed': 'Vindhastighet',
    north: 'Nord',
    northeast: 'Nordost',
    east: 'Ost',
    southeast: 'Sydost',
    south: 'Syd',
    southwest: 'Sydväst',
    west: 'Väst',
    northwest: 'Nordväst',
    yesterday: 'I går',
    'your-favourites': 'Favoriter',
    zones: 'Biljettzoner',
  },
};

export default translations;
