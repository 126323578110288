import React from 'react';

const HSLAdformTrackingPixel = () => (
  <div style={{ width: 0, height: 0, display: 'none' }}>
    <img
      id=""
      src="https://track.adform.net/Serving/TrackPoint/?pm=785700&ADFPageName=K%C3%A4yneet%20sivustolla&ADFdivider=|"
      width="1"
      height="1"
      alt=""
    />
  </div>
);

HSLAdformTrackingPixel.displayName = 'HSLAdformTrackingPixel';

export default HSLAdformTrackingPixel;
